import {
  closestCenter,
  DndContext,
  DndContextProps,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DraggableRow, LineLoader, RingLoader, Text } from '@components';
import { CellType, TableActionHandlerType } from '@enums';
import { useAppDispatch } from '@hooks';
import { getTableSorting } from '@store';
import type { PaginationProps } from '@types';

import classNames from 'classnames';
import { HeaderCell } from './DraggableHeaderCell';
import Pagination from './Pagination';

export interface TableConfig {
  className?: string;
  width?: number;
  maxWidth?: number | string;
  minWidth?: number | string;
  align?: 'right' | 'left';
  sticky?: boolean;
  format?: (value: any, fullData: any) => any;
  formatLabel?: (value: string, fullData: any) => any;
  cellCallback?: (props: any) => void;
  sortableCallback?: () => void;
  cellType?: CellType;
}

export interface Column extends TableConfig {
  value: string;
  label: string;
  withRightBorder?: boolean;
  disabled?: boolean;
  multiLines?: boolean;
  dotsTextOverflow?: boolean;
  center?: boolean;
}

export interface ActionHandlers<T> {
  [TableActionHandlerType.handleEdit]?: (rowData: T) => void;
  [TableActionHandlerType.handleView]?: (rowData: T) => void;
  [TableActionHandlerType.handleDelete]?: (rowData: T) => void;
  [TableActionHandlerType.handleCreate]?: () => void;
  [TableActionHandlerType.handlePriority]?: (tableKey: string, application_id?: string) => void;
  [TableActionHandlerType.handleRefresh]?: () => void;
  [TableActionHandlerType.handleCheck]?: (tableKey: string, id: string) => void;
  [TableActionHandlerType.handleVerification]?: (rowData?: T) => void;
  [TableActionHandlerType.handleDisable]?: () => void;
}

export interface DraggableItem {
  id: string | number;
  [key: string]: any;
}

export interface ColumnLayout {
  width: string;
  minWidth?: string;
  maxWidth?: string;
  flex: string;
  percentage: number;
}

interface DraggableContainerProps<T extends DraggableItem> {
  rows: T[];
  columns: Column[];
  isDraggable?: boolean;
  tableKey: string;
  isDataLoading?: boolean;
  actionHandlers?: ActionHandlers<T>;
  pagination?: PaginationProps;
  onChangePage?: (page: number) => void;
}

export const DraggableTable = memo(
  <T extends DraggableItem>({
    rows: initialItems,
    columns,
    isDraggable = false,
    tableKey,
    isDataLoading = false,
    actionHandlers,
    pagination,
    onChangePage,
  }: DraggableContainerProps<T>) => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<T[]>(initialItems);
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);

    const { t: tTable } = useTranslation('common', { keyPrefix: 'tables' });

    const columnLayouts = useMemo(() => {
      const availableWidth = containerWidth || 0;

      const totalMinWidth = columns.reduce((acc, col) => {
        const minWidth = col.minWidth ? parseInt(col.minWidth.toString()) : col.width ?? 100;
        return acc + minWidth;
      }, 0);

      if (totalMinWidth > availableWidth || availableWidth < 640) {
        return columns.map((col): ColumnLayout => {
          const minWidth = col.minWidth ? parseInt(col.minWidth.toString()) : col.width ?? 100;

          return {
            width: `${minWidth}px`,
            minWidth: `${minWidth}px`,
            maxWidth: col.maxWidth ? `${col.maxWidth}px` : undefined,
            flex: '0 0 auto',
            percentage: (minWidth / totalMinWidth) * 100,
          };
        });
      }

      const totalDefinedWidth = columns.reduce((acc, col) => acc + (col.width || 0), 0);

      if (totalDefinedWidth > 0 && totalDefinedWidth < availableWidth) {
        const remainingWidth = availableWidth - totalDefinedWidth;
        const scale = availableWidth / totalDefinedWidth;

        return columns.map((col): ColumnLayout => {
          const width = col.width ? col.width * scale : remainingWidth / columns.length;
          const percentage = (width / availableWidth) * 100;

          return {
            width: `${width}px`,
            minWidth: col.minWidth ? `${col.minWidth}px` : `${Math.min(width, 100)}px`,
            maxWidth: col.maxWidth ? `${col.maxWidth}px` : undefined,
            flex: '1 1 auto',
            percentage,
          };
        });
      }

      const equalWidth = availableWidth / columns.length;
      return columns.map((col): ColumnLayout => {
        const minWidth = col.minWidth ? parseInt(col.minWidth.toString()) : 100;

        return {
          width: `${equalWidth}px`,
          minWidth: `${minWidth}px`,
          maxWidth: col.maxWidth ? `${col.maxWidth}px` : undefined,
          flex: '1',
          percentage: 100 / columns.length,
        };
      });
    }, [columns, containerWidth]);

    useEffect(() => {
      const updateWidth = () => {
        if (containerRef.current) {
          setContainerWidth(containerRef.current.offsetWidth);
        }
      };

      updateWidth();
      window.addEventListener('resize', updateWidth);
      return () => window.removeEventListener('resize', updateWidth);
    }, []);

    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: { distance: 8 },
      }),
      useSensor(TouchSensor, {
        activationConstraint: {
          delay: 250,
          tolerance: 5,
          distance: 8,
        },
      }),
      useSensor(KeyboardSensor),
    );

    useEffect(() => {
      setRows(initialItems);
    }, [initialItems]);

    const handleDragEnd: DndContextProps['onDragEnd'] = useCallback((event) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        setRows((prevItems) => {
          const oldIndex = prevItems.findIndex((item) => item.id === active.id);
          const newIndex = prevItems.findIndex((item) => item.id === over.id);

          const newItems = [...prevItems];
          const [movedItem] = newItems.splice(oldIndex, 1);
          newItems.splice(newIndex, 0, movedItem);
          dispatch(getTableSorting({ tableKey: tableKey, rowData: newItems }));

          return newItems;
        });
      }
    }, []);

    return (
      <div
        ref={containerRef}
        className="relative flex flex-col overflow-hidden rounded-2xl ring-1 ring-theme-text-secondary"
        style={{ touchAction: isDraggable ? 'none' : 'auto' }}
      >
        <div className={`flex-1 ${isDraggable ? '' : 'overflow-x-auto padded-horizontal-scrollbar-track'}`}>
          <div className="min-w-fit">
            <div className="sticky top-0 z-10 flex bg-gray-50 bg-opacity-90 border-b">
              {columns?.map((col, index) => <HeaderCell key={index} column={col} layout={columnLayouts[index]} />)}
            </div>
            {isDataLoading && (
              <div className="relative h-1">
                <div className="absolute w-full top-0 left-0 bg-gray-50">
                  <LineLoader size="100%" stroke={3.5} color="#417788" loading={isDataLoading} />
                </div>
              </div>
            )}
            <div className={`flex-1 ${isDraggable ? '' : 'overflow-x-auto padded-horizontal-scrollbar-track'}`}>
              {rows?.length > 0 ? (
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                  <SortableContext
                    items={rows?.filter((item) => item?.id)?.map((item) => item.id) || []}
                    strategy={verticalListSortingStrategy}
                  >
                    {rows?.map((item, index) => (
                      <DraggableRow
                        key={`${item.id}-${index}`}
                        item={item}
                        index={index}
                        columns={columns}
                        columnLayouts={columnLayouts}
                        isDraggable={isDraggable}
                        actionHandlers={actionHandlers}
                        tableKey={tableKey}
                      />
                    ))}
                  </SortableContext>
                </DndContext>
              ) : isDataLoading ? (
                <div className="h-16 flex items-center justify-center">
                  <Text $level={4} $customizeColor className="flex justify-center items-center gap-x-4 text-gray-300">
                    <RingLoader color="#36d7b7" size={15} />
                    {tTable('fetchingData')}
                  </Text>
                </div>
              ) : (
                <div className="h-16 flex items-center justify-center select-none">
                  <Text $level={4} $customizeColor className="flex justify-center items-center gap-4 text-gray-300">
                    <DocumentMagnifyingGlassIcon className="size-6 text-gray-300" />
                    {tTable('noRecordFound')}
                  </Text>
                </div>
              )}
            </div>
          </div>
        </div>
        {pagination && onChangePage && pagination?.total > 0 && (
          <div
            className={classNames('sticky bottom-0 px-6 py-2 border-t', {
              'bg-transparent': pagination?.total % 2 === 0,
              ' bg-gray-50': pagination?.total % 2 !== 0,
            })}
          >
            <Pagination pagination={pagination} onChangePage={onChangePage} />
          </div>
        )}
      </div>
    );
  },
);
