import clsx from 'clsx';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Header, Sidebar } from './';

interface MainContentContainerProps {
  className?: string;
}
export const MainContentContainer = ({ children, className }: PropsWithChildren<MainContentContainerProps>) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  return (
    <div className="h-screen w-full max-h-screen overflow-hidden flex justify-center bg-[#f3f2f7] font-sans">
      <div
        id="mainContainer"
        className="flex flex-1 relative justify-center px-4 sm:px-6 md:px-8 pt-4 sm:pt-6 md:pt-8 xl:space-x-9 space-x-0 overflow-y-auto overflow-x-hidden padded-scrollbar-track"
      >
        <Sidebar status={sidebarOpen} sidebarAction={() => setSidebarOpen(false)} />
        <main className="lg:pt-4 flex flex-col flex-1 max-w-full xl:max-w-screen-xl" ref={containerRef}>
          <Header sidebarAction={() => setSidebarOpen(true)} />
          <div className={clsx(['flex flex-1 flex-col pb-8', className])}>{children}</div>
        </main>
      </div>
    </div>
  );
};
