import { motion } from 'framer-motion';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CompanyBg from '@assets/loginBg.webp';
import CompanyLogo from '@assets/logo.webp';

import { GlobalLoader } from '@/components';
import { getAllStorageData } from '@/storage';
import { useInitLoading, useLoginLoading, useUserDetails } from '@/store';
import { useNavigate } from 'react-router-dom';
import { SignInForm } from './subComponents';

export const Login: FC = () => {
  const navigate = useNavigate();
  const { t: tSignIn } = useTranslation('signIn');
  const [isImageLoading, setIsImageLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = CompanyBg;
    img.onload = () => setIsImageLoading(false);
  }, []);

  const loading = useLoginLoading();
  const isInitLoading = useInitLoading();

  const user_details = useUserDetails();

  const { current_access_token, current_refresh_token } = getAllStorageData();

  const shouldNavigate = current_access_token && current_refresh_token && user_details && !loading && !isInitLoading;

  const memoizedNavigate = useCallback(() => {
    if (shouldNavigate) {
      navigate('/homepage');
    }
  }, [user_details]);

  useEffect(() => {
    memoizedNavigate();
  }, [memoizedNavigate]);

  if (isImageLoading) return <GlobalLoader overlay />;

  return (
    <div className="min-h-screen w-full flex items-center justify-center bg-gradient-to-br from-gray-900 to-gray-800">
      <motion.div
        className="absolute inset-0 z-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <img src={CompanyBg} alt="Global Network" className="w-full h-full object-cover" />
      </motion.div>
      <motion.div
        className="relative z-10 bg-theme-neutral-dark backdrop-blur-sm bg-opacity-90 backdrop-filter ring-1 ring-white/10 rounded-xl shadow-[0_0px_6px_rgba(255,255,255,0.1)] px-8 py-10 w-full max-w-sm mx-4"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.3 }}
      >
        <div className="flex flex-col gap-8 px-1">
          <motion.div
            className="flex flex-col items-center space-y-6"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.3 }}
          >
            <img className="h-16 md:h-20 w-auto" src={CompanyLogo} alt="Company Logo" />
            <h2 className="text-lg font-semibold leading-9 tracking-tight text-theme-text-main">
              {tSignIn('signInWithYourAccountDetails')}
            </h2>
          </motion.div>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.3 }}
          >
            <SignInForm />
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};
