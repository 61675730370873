import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { DraggableTable } from '@components';
import { getSslStatusLogTable } from '@constants/components/ssLStatusLogTable';
import { CdnConfigurationTab } from '@enums';
import { useAppDispatch } from '@hooks';
import { getConfigurationList, useConfigurationList, useConfigurationLoading } from '@store';

export const SslStatusLog = ({ sslId }: { sslId: number }) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const tableKey = CdnConfigurationTab.SSLLog;

  const sslStatusLogTableColumns = useMemo(getSslStatusLogTable, []);

  const sslLoggedData = useConfigurationList(tableKey);
  const isSslStatusLoading = useConfigurationLoading(tableKey);

  useEffect(() => {
    if (application_id) {
      dispatch(getConfigurationList({ tableKey, application_id, sslId }));
    }
  }, [application_id, sslId]);

  return (
    <DraggableTable
      rows={sslLoggedData}
      columns={sslStatusLogTableColumns}
      isDataLoading={isSslStatusLoading}
      tableKey={tableKey}
    />
  );
};
