import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getCdnRoutePriorityColumns, useCdnRouteListColumns } from '@/constants';
import { useAppDispatch } from '@/hooks';
import { ConfigurationCard, DraggableTable, PrioritizeModal } from '@components';
import { CdnConfigurationTab } from '@enums';
import { updatePageChanges, useConfigurationList, useConfigurationLoading, useConfigurationPagination } from '@store';
import { useParams } from 'react-router-dom';

export const RouteForm = ({ actionHandlers }) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const tableKey = CdnConfigurationTab.Route;

  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.route' });
  const { t: tButtons } = useTranslation('configuration', { keyPrefix: 'buttons.route' });
  const { t: tModals } = useTranslation('configuration', { keyPrefix: 'modals.route' });
  const { t: tTables } = useTranslation('description', { keyPrefix: 'configuration.tables' });

  const isRouteLoading = useConfigurationLoading(tableKey);
  const routeListData = useConfigurationList(tableKey);
  const routePagination = useConfigurationPagination(tableKey);

  const cdnOriginRouteColumns = useCdnRouteListColumns() ?? [];
  const memorizedColumns = useMemo(getCdnRoutePriorityColumns, [tTables]);

  const [openPriorityModal, setOpenPriorityModal] = useState<boolean>(false);

  const handlePageChange = (page) => {
    dispatch(updatePageChanges({ tableKey, page, application_id }));
  };

  return (
    <ConfigurationCard
      title={tTabs('applicationRoute')}
      ActionButtonProps={{
        actionHandlers: actionHandlers,
        loading: isRouteLoading,
        createLabel: tButtons('addNewRoute'),
        setOpen: () => setOpenPriorityModal(true),
      }}
    >
      {openPriorityModal && (
        <PrioritizeModal
          title={tModals('prioritizeRoute')}
          columns={memorizedColumns}
          tableKey={tableKey}
          description={tModals('prioritizeRouteDescription')}
          open={openPriorityModal}
          actionHandlers={actionHandlers}
          onClose={() => setOpenPriorityModal(false)}
        />
      )}

      <DraggableTable
        rows={routeListData}
        columns={cdnOriginRouteColumns}
        pagination={routePagination}
        tableKey={tableKey}
        onChangePage={handlePageChange}
        isDataLoading={isRouteLoading}
        isDraggable={false}
        actionHandlers={actionHandlers}
      />
    </ConfigurationCard>
  );
};
