import { ApexOptions } from 'apexcharts';
import { FC, memo, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

import { CdnConfigurationDashboardGraph, CdnConfigurationTab, CdnDashboardFilter } from '@enums';
import { useChartDimensions } from '@hooks';
import { useConfigurationFilter } from '@store';
import { convertGraphDateTimeFormat, formatNumberWithSeparator, formatYAxisValue, validateValueEqual } from '@utils';

const dimensionData = {
  mobile: { height: 250 },
  tablet: { height: 300 },
  desktop: { height: 450 },
  large: { height: 550 },
};

const CdnConfigurationDashboardAttackCountGraph: FC<{ label: any }> = memo(({ label }) => {
  const { chartHeight } = useChartDimensions({ dimensionsData: dimensionData });
  const filter = useConfigurationFilter({
    tableKey: CdnConfigurationTab.Dashboard,
    name: CdnDashboardFilter.Time,
    origin: CdnConfigurationDashboardGraph.TotalTraffic,
  });

  const seriesData = useMemo(
    () => label?.dataSets?.map(({ timestamp = 0, delay = 0 }) => [timestamp * 1000, delay]) || [],
    [label],
  );

  const options: ApexOptions = useMemo(
    () => ({
      chart: {
        type: 'line',
        zoom: { enabled: false },
        toolbar: { show: false },
        animations: { enabled: false },
      },
      stroke: { curve: 'smooth', width: 2 },
      dataLabels: { enabled: false },
      xaxis: {
        type: 'datetime',
        labels: {
          format: convertGraphDateTimeFormat(filter.value),
          datetimeUTC: false,
        },
      },
      yaxis: {
        min: 0,
        labels: {
          formatter: (value) =>
            validateValueEqual(value, 0) ? '' : formatNumberWithSeparator(formatYAxisValue({ value })),
        },
      },
      tooltip: {
        x: { format: 'dd MMM yyyy HH:mm' },
        y: { formatter: (value) => formatNumberWithSeparator(value) },
      },
    }),
    [filter.value],
  );

  const series = useMemo(
    () => [
      {
        name: 'Delay',
        data: seriesData,
      },
    ],
    [seriesData],
  );

  if (!filter.value) return null;

  return (
    <div className="mt-8">
      <ReactApexChart options={options} series={series} height={chartHeight} type="line" />
    </div>
  );
});

CdnConfigurationDashboardAttackCountGraph.displayName = 'CdnConfigurationDashboardAttackCountGraph';

export default memo(CdnConfigurationDashboardAttackCountGraph);
