import { useEffect, useMemo } from 'react';
import { resetLoadSuccess } from '../store'; // Adjust the import as needed
import { RootState, useAppDispatch, useAppSelector } from './storeHooks';

interface ConfigurationState {
  [key: string]: { loadSuccess: boolean };
}

export const useResetLoadSuccess = (activeTableKeys: string[]) => {
  const dispatch = useAppDispatch();
  const configurationData = useAppSelector((state: RootState) => state.configuration) as ConfigurationState;

  const keysToReset = useMemo(
    () => Object.keys(configurationData).filter((key) => !activeTableKeys.includes(key)),
    [activeTableKeys, configurationData],
  );

  useEffect(() => {
    keysToReset.forEach((key) => {
      if (configurationData[key].loadSuccess) {
        dispatch(resetLoadSuccess({ tableKey: key }));
      }
    });
  }, [keysToReset, configurationData, dispatch]);
};
