import i18n from 'i18next';

export type optionType = { label: string; value: string; disabled?: boolean };

type OptionsResult = {
  options: optionType[];
};

export const convertOptionList = ({
  originalOptions,
  defaultOptions,
  output = { value: 'id', label: 'label' },
}: any): OptionsResult => {
  if (!Array.isArray(originalOptions)) {
    return { options: [] };
  }
  let normalizedOptions = originalOptions
    .map((option) => {
      if (typeof option !== 'object' || option === null) {
        return null;
      }

      const id = option[output.value];
      const name = option[output.label];

      if (id === undefined || name === undefined) {
        return null;
      }

      return {
        value: id,
        label: typeof name === 'string' ? name.trim() : String(name),
      };
    })
    .filter((option): option is optionType => option !== null);

  if (defaultOptions === 'all') {
    normalizedOptions = [{ value: 'all', label: i18n.t('form:common.all') }, ...normalizedOptions];
  }

  return { options: normalizedOptions };
};
