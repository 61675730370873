import i18n from 'i18next';
import { call, delay, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { accessToken, getAllStorageData, refreshToken } from '@/storage';
import { API, setHeaders } from '@/utils';
import { Headers, StatusType } from '@enums';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  addSnack,
  appInitError,
  fetchTokenContinuously,
  fetchTokenContinuouslyError,
  fetchTokenContinuouslySuccess,
  logOut,
  setAppLoading,
  systemError,
  systemUpdateNetworkConnection,
  systemUpdatePageFocus,
  updateApiConnectionStatus,
  updateToken,
  updateTokenSuccess,
} from '@store/slices';
import jwt_decode from 'jwt-decode';

const TOKEN_EXPIRATION_TIME = 60; //1 minute
const MIN_VALID_DURATION = 30; // 30 seconds
const TOKEN_CHECK_FREQUENCY = 30000;

function isTokenExpired(token: string): boolean {
  try {
    const decoded: any = jwt_decode(token);
    return decoded.exp <= Math.floor(Date.now() / 1000);
  } catch (e) {
    return true;
  }
}

function getAccessToken() {
  return localStorage.getItem('access_token');
}

function* updateNetworkConnectionSaga() {
  const isBackOnline = yield select((state) => state.system.networkConnection.backOnline);
  try {
    yield put(
      addSnack({
        type: isBackOnline ? StatusType.Success : StatusType.Error,
        message: i18n.t(isBackOnline ? 'common:connection.restored' : 'common:connection.disconnected'),
        clear: isBackOnline,
      }),
    );
    if (isBackOnline) {
      yield put(systemUpdatePageFocus({ isActive: true }));
    }
  } catch (error) {
    yield put(systemError(error instanceof Error ? error : new Error('Unknown error')));
  }
}

function* checkTokenAfterInactivitySaga({ payload: { isActive } }: PayloadAction<{ isActive: boolean }>) {
  if (isActive) {
    yield put(setAppLoading(true));
    try {
      const access_token = yield call(accessToken.getItem);
      if (access_token && !isTokenExpired(access_token)) {
        yield call(updateTokenIfNeeded);
      } else {
        yield put(logOut());
      }
    } catch (e) {
      yield put(logOut());
    } finally {
      yield put(setAppLoading(false));
    }
  }
}

function* updateTokenIfNeeded() {
  try {
    const { current_access_token: accessToken } = yield call(getAllStorageData);

    const decoded = jwt_decode<{ exp: number }>(accessToken);
    const currentTime = Math.floor(Date.now() / 1000);
    const timeRemaining = decoded.exp - currentTime;

    if (timeRemaining < TOKEN_EXPIRATION_TIME) {
      yield put(updateToken());
      const { success } = yield race({
        success: take(updateTokenSuccess.type),
        failure: take(fetchTokenContinuouslyError.type),
      });

      if (success) {
        const newToken = yield call(getAccessToken);
        const newDecoded = jwt_decode<{ exp: number }>(newToken);
        const newTimeRemaining = newDecoded.exp - Math.floor(Date.now() / 1000);

        return newTimeRemaining >= MIN_VALID_DURATION ? 'refreshed' : 'failed';
      } else {
        return 'failed';
      }
    } else if (timeRemaining <= MIN_VALID_DURATION) {
      return 'failed';
    } else {
      return 'valid';
    }
  } catch (error) {
    return 'failed';
  }
}

function* fetchTokenContinuouslySaga() {
  try {
    const result = yield call(updateTokenIfNeeded);

    if (result === 'failed') {
      yield put(fetchTokenContinuouslyError());
      yield put(appInitError('Error refreshing token'));
      return;
    }
    yield put(fetchTokenContinuouslySuccess());
  } catch (error) {
    yield put(logOut());
    return;
  }
}

function* fetchTokenContinuouslySuccessSaga() {
  window.history.replaceState(null, '', window.location.pathname);
  yield delay(TOKEN_CHECK_FREQUENCY);
  yield put({ type: fetchTokenContinuously.type });
}

function* updateTokenSaga() {
  try {
    const { current_refresh_token } = yield call(getAllStorageData);
    if (!current_refresh_token) throw new Error('Invalid refresh token');

    if (isTokenExpired(current_refresh_token)) {
      yield put(logOut());
      return;
    }

    yield call(setHeaders, { [Headers.Authorization]: `Bearer ${current_refresh_token}` });
    const { data } = yield call(API.post, '/user/refresh');
    const { access_token, refresh_token } = data;
    if (!access_token || !refresh_token) throw new Error('Invalid token response');

    yield call(accessToken.setItem, access_token);
    yield call(refreshToken.setItem, refresh_token);
    yield call(setHeaders, { [Headers.Authorization]: `Bearer ${access_token}` });

    yield put(updateTokenSuccess());
  } catch (e) {
    yield put(fetchTokenContinuouslyError());
    yield put(logOut());
  }
}

function* updateTokenSuccessSaga() {
  const isAPIWorking = yield select((state) => state.system.apiConnection.isConnected);
  if (!isAPIWorking) {
    yield put(updateApiConnectionStatus({ status: true }));
  }
}

export function* systemSagaWatcher() {
  yield takeLatest(systemUpdatePageFocus.type, checkTokenAfterInactivitySaga);
  yield takeLatest(systemUpdateNetworkConnection.type, updateNetworkConnectionSaga);

  yield takeLatest(updateToken.type, updateTokenSaga);
  yield takeLatest(updateToken.type, updateTokenSuccessSaga);
  yield takeLatest(fetchTokenContinuously.type, fetchTokenContinuouslySaga);
  yield takeLatest(fetchTokenContinuouslySuccess.type, fetchTokenContinuouslySuccessSaga);
}
