import { useChartDimensions } from '@hooks';
import { formatYAxisValue } from '@utils';
import { ApexOptions } from 'apexcharts';
import { motion } from 'framer-motion';
import { memo, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

export const CdnConfigurationDashboardCacheChart = memo(({ label }: { label: any }) => {
  const dimensionData = useMemo(
    () => ({
      mobile: { height: 250 },
      tablet: { height: 300 },
      desktop: { height: 350 },
      large: { height: 450 },
    }),
    [],
  );

  const { chartHeight } = useChartDimensions({ dimensionsData: dimensionData });

  const { seriesData } = useMemo(() => {
    const trafficValue = [] as number[];
    const trafficKey = [] as string[];
    let max = 0;

    (label?.dataSets ?? []).forEach(({ cache_status = '', count = 0 }: { cache_status: string; count: number }) => {
      trafficValue.push(count);
      trafficKey.push(cache_status);
      if (count > max) max = count;
    });

    return { seriesData: { trafficValue, trafficKey }, maxValue: max };
  }, [label?.dataSets]);

  const options: ApexOptions = useMemo(
    () => ({
      labels: seriesData.trafficKey,
      legend: {
        position: 'bottom',
      },
      tooltip: {
        y: {
          formatter: (value) => formatYAxisValue({ value, useUnit: true, decimalPlaces: 2 }),
        },
      },
      theme: {
        mode: 'light',
        monochrome: {
          enabled: true,
          color: '#4aa1ff',
          shadeTo: 'light',
          shadeIntensity: 0.95,
        },
      },
      chart: {
        animations: {
          enabled: window.innerWidth > 768, // Enable animations only for larger screens
          easing: 'easeinout',
          speed: 500, // Reduced from 800
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
    }),
    [seriesData.trafficKey],
  );

  const series = useMemo(() => seriesData.trafficValue, [seriesData.trafficValue]);

  return (
    <motion.div
      className="flex justify-center mt-8"
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
    >
      <ReactApexChart options={options} series={series} type="pie" height={chartHeight} />
    </motion.div>
  );
});
