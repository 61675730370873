import i18n from 'i18next';

import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  addSnack,
  deleteFormContent,
  deleteFormContentError,
  fetchFormList,
  fetchFormListError,
  fetchFormListSuccess,
  fetchSortingListSuccess,
  submitFormRequest,
  submitFormSuccess,
} from '@store';

import { universalFormConfig } from '@constants';
import { StatusType } from '@enums';
import { API, generateApiUrlAndStatus } from '@utils';

export function* fetchFormListSaga({ payload }: PayloadAction<any, string>) {
  const { formType, params, globalOptions, sorting = false } = payload;

  try {
    const { basePath, formatData } = universalFormConfig(formType);
    let url = basePath;
    if (globalOptions?.withoutParams) {
      url = `${basePath}/${params.application_id}`;
    }
    const responseData = yield call(API.get, url, globalOptions?.withoutParams ? undefined : { params });

    const { data, headers } = responseData;

    const paginationHeader = headers['x-pagination'] || headers['X-Pagination'];
    const paginationInfo = paginationHeader ? JSON.parse(paginationHeader) : null;

    if (sorting && data && Array.isArray(data)) {
      const updatedResponse = data.map((item, index) => ({
        ...item,
        original_id: index + 1,
      }));
      yield put(
        fetchSortingListSuccess({
          formType,
          responseResult: updatedResponse,
        }),
      );
    } else {
      const formatedData = yield call(formatData, data);
      yield put(
        fetchFormListSuccess({
          formType,
          list: formatedData,
          paginationInfo,
        }),
      );
    }
  } catch (error) {
    yield put(fetchFormListError({ formType, responseResult: error as Error }));
  }
}

export function* submitConfigurationFormSaga({ payload }: PayloadAction<any, string>) {
  const {
    formType,
    returnResult = false,
    sorting = false,
    disabledToast = false,
    ignoreResponse = false,
    formData,
    content_id,
  } = payload;
  const { baseName, basePath, sortPostPath, successMessage } = universalFormConfig(formType);

  if (!basePath || !successMessage) {
    console.error('Invalid table key provided:', formType);
    return;
  }

  const { url, submitToastCode } = generateApiUrlAndStatus({
    isSorting: sorting,
    sortingPath: sortPostPath,
    contentId: content_id,
    baseApiPath: basePath,
  });

  try {
    const responseData = yield call(API.post, url, formData);

    const { data, headers } = responseData;

    const paginationHeader = headers['x-pagination'] || headers['X-Pagination'];
    const paginationInfo = paginationHeader ? JSON.parse(paginationHeader) : null;

    if (responseData) {
      if (!disabledToast) {
        yield put(
          addSnack({ type: StatusType.Success, message: i18n.t(`${successMessage}.${baseName}${submitToastCode}`) }),
        );
      }
      yield put(
        submitFormSuccess({
          formType,
          content: data,
        }),
      );
      if (returnResult) {
        yield put(
          fetchFormListSuccess({
            formType,
            list: responseData.data,
            paginationInfo,
          }),
        );
      }
    } else if (ignoreResponse) {
      yield put(
        submitFormSuccess({
          formType,
          content: true,
        }),
      );
    } else {
      yield put(addSnack({ type: StatusType.Error, message: `No ${formType} data available from the API` }));
      yield put(fetchFormListError({ formType, responseResult: `No ${formType} data available from the API` }));
    }
  } catch (error) {
    const axiosError = error as AxiosError<{ data: any }>;
    if (axiosError.response) {
      const { data } = axiosError.response.data;
      yield put(fetchFormListError({ formType, responseResult: data }));
    }
  }
}

export function* deleteFormRowSaga({ payload }: PayloadAction<any>) {
  const { formType } = payload;
  const { successMessage } = universalFormConfig(formType);

  try {
    //   if (content_id) {
    //     yield call(API.delete, `${basePath}/${content_id}`);
    //   }
    //   if (application_id) {
    //     yield put(fetchFormList({ formType, params }));
    //   }
    yield put(addSnack({ type: StatusType.Success, message: i18n.t(`${successMessage}.deleteSuccessful`) }));
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorDetails = errorResponse?.response?.data ?? error;
    yield put(deleteFormContentError({ formType, error: errorDetails }));
  }
}

export function* formSagaWatcher() {
  yield takeLatest(fetchFormList.type, fetchFormListSaga);
  yield takeLatest(submitFormRequest.type, submitConfigurationFormSaga);
  yield takeLatest(deleteFormContent.type, deleteFormRowSaga);
}
