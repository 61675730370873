import { all } from 'redux-saga/effects';

import { authSagaWatcher } from './authSaga';
import { cdnSagaWatcher } from './cdnSaga';
import { configurationSagaWatcher } from './configurationSaga';
import { formSagaWatcher } from './formSaga';
import { initSagaTakeEvery } from './initSaga';
import { profileSagaWatcher } from './profileSaga';
import { systemSagaWatcher } from './systemSaga';
import { userSagaWatcher } from './userSaga';
import { workspaceSagaWatcher } from './workspaceSaga';

export default function* rootSaga() {
  yield all([
    systemSagaWatcher(),
    userSagaWatcher(),
    initSagaTakeEvery(),
    cdnSagaWatcher(),
    configurationSagaWatcher(),
    profileSagaWatcher(),
    authSagaWatcher(),
    workspaceSagaWatcher(),
    formSagaWatcher(),
  ]);
}
