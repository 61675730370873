import { Button, Text } from '@/components';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const NoPermissions = () => {
  const { t: tcError } = useTranslation('common', { keyPrefix: 'errors' });
  const { t: tCommon } = useTranslation('common', { keyPrefix: 'button' });
  const navigate = useNavigate();

  const handleNavigateBack = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <div className="items-center max-w-80 justify-center flex flex-col gap-10 m-auto">
      <XMarkIcon className="w-16 h-16 text-white bg-red-700 rounded-full p-2" />
      <div className="flex flex-col gap-5 text-center ">
        <Text color="dark:text-white">{tcError('accessDenied')}</Text>
        <Text $level={3}>{tcError('sorryAboutThatButYouDontHavePermissionToAccessThisPage')}</Text>
      </div>
      <Button color="blue" customizeColor onClick={handleNavigateBack}>
        {tCommon('goBack')}
      </Button>
    </div>
  );
};
