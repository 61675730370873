import { PropsWithChildren } from 'react';
import { ConfigurationActionButton } from '../Buttons';
import { ActionHandlers } from '../DraggableTable';
import { Text } from '../Text';
import { Cards } from './Cards';

interface ConfigurationCardProps<T> {
  title: string;
  ActionButtonProps: {
    actionHandlers: ActionHandlers<T>;
    loading: boolean;
    setOpen?: () => void;
    createLabel: string;
  };
}

export const ConfigurationCard = <T,>({
  title,
  ActionButtonProps,
  children,
}: PropsWithChildren<ConfigurationCardProps<T>>) => {
  return (
    <Cards
      rounded="rounded-2xl"
      withoutPadding
      className="flex flex-col bg-white bg-opacity-90 px-8 py-7 gap-6 max-w-full w-full"
    >
      <div className="flex flex-col xl:flex-row xl:items-center gap-8 justify-between">
        <Text $level={2} color="text-black" className="font-medium">
          {title}
        </Text>
        <ConfigurationActionButton {...ActionButtonProps} />
      </div>
      {children}
    </Cards>
  );
};
