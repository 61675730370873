import { PlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTES } from '@/constants';
import { Breadcrumbs, Button, Cards, CdnCards, ConfirmationModal, Skeleton, SkeltonType } from '@components';
import { EditTableRoute, FormType, StatusType, TableActionType } from '@enums';
import { useAppDispatch } from '@hooks';
import { addSnack, fetchFormList, useFormList, useFormLoadingStatus, useWorkspaceError } from '@store';
import { useActionHandlers } from '@utils';

import { getAllStorageData } from '@/storage';
import { CdnApplicationModal } from './subComponents';

export enum CdnStatus {
  Active = 1,
  InActive = 0,
}

export interface CdnData {
  id: string;
  name: string;
  status: CdnStatus;
  allow_wildcard: boolean;
  allow_domain_count: number;
  allow_total_domain_count: number;
  allow_page_rules_count: number;
}

export const Cdn = () => {
  const dispatch = useAppDispatch();

  const { t: tCdn } = useTranslation('cdn');
  const { t: tRouteModal } = useTranslation('configuration', { keyPrefix: 'modals.route' });
  const { t: tModal } = useTranslation('configuration', { keyPrefix: 'modals.origin' });
  const { t: tRouteToast } = useTranslation('configuration', { keyPrefix: 'toasts.route' });
  const { t: tCommon } = useTranslation('common');

  const CdnForm = FormType.Cdn;
  const { current_workspace_id: workspace_id } = getAllStorageData();
  const cdnData = useFormList(CdnForm);
  const loading = useFormLoadingStatus(CdnForm);

  const [open, setOpen] = useState<boolean>(false);
  const [openDeleteCdnDialog, setOpenDeleteCdnDialog] = useState<boolean>(false);
  const [currentCdnData, setCurrentCdnData] = useState<CdnData>();

  const actionHandlers = useActionHandlers<CdnData>({
    setData: setCurrentCdnData,
    setDeleteOpen: setOpenDeleteCdnDialog,
    routePath: ROUTES.configuration.main,
    tableKey: 'cdn',
    editTableRoute: EditTableRoute.Cdn,
    activeHandlers: [TableActionType.Create, TableActionType.Edit, TableActionType.Delete],
  });

  const handleCdnDeleteClose = () => {
    dispatch(addSnack({ type: StatusType.Success, message: tRouteToast('deleteSuccessful') }));
    setOpenDeleteCdnDialog(false);
    dispatch(fetchFormList({ formType: CdnForm, params: { workspace_id } }));
  };

  const systemError = useWorkspaceError();

  return (
    <div className="flex flex-1 flex-col space-y-8">
      <Breadcrumbs />
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
        <div className="space-y-2">
          <article className="font-medium">{tCdn('websitesAndApplication')}</article>
          <article className="font-light text-sm text-gray-400">
            {tCdn('listOfWebsitesAndApplicationsThatAreUnderThisAccount')}
          </article>
        </div>
        <div className="flex gap-x-6 items-center justify-start pt-6 md:pt-0">
          <Button
            disabled={systemError ?? loading}
            onClick={() => setOpen(true)}
            className="order-1 md:order-3"
            icon={<PlusIcon className="w-4 h-4" />}
          >
            <article className="ellipsis-text">{tCdn('forms.createApplicationButton')}</article>
          </Button>
        </div>
      </div>
      <div className="mt-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6 p-0.5">
          {!!cdnData.length &&
            cdnData.map((item: CdnData, index: number) => (
              <Cards key={index} rounded="rounded-xl" withoutPadding className="flex h-full w-full max-h-72">
                <CdnCards data={item} actionHandlers={actionHandlers} />
              </Cards>
            ))}
          {!cdnData.length &&
            loading &&
            Array.from({ length: 8 }, (_, index) => (
              <Cards key={index} rounded="rounded-xl" withoutPadding className="flex max-h-72 h-full w-full">
                <div className="flex flex-1 flex-col p-6 relative space-y-6 animate-pulse">
                  <div className="flex justify-between">
                    <div className="flex flex-col flex-1 space-y-2">
                      <Skeleton />
                      <Skeleton className="w-2/3 h-5" />
                    </div>
                    <Skeleton type={SkeltonType.Avatar} />
                  </div>
                  <Skeleton className=" w-2/3 h-4" />

                  <div className="flex gap-x-3">
                    <Skeleton type={SkeltonType.Button} />
                    <Skeleton type={SkeltonType.Button} />
                  </div>
                </div>
              </Cards>
            ))}
          {!cdnData.length && !loading && (
            <Cards rounded="rounded-xl" withoutPadding className="flex max-h-72 h-full w-full">
              <div className="flex flex-1 flex-col items-center justify-center min-h-40">
                <article className="text-gray-400 text-sm">No Application found</article>
              </div>
            </Cards>
          )}
        </div>
      </div>
      <CdnApplicationModal open={open} onClose={() => setOpen(false)} />
      <ConfirmationModal
        title={tRouteModal('routeDelete')}
        content={tModal('areYouSureDelete', { domain: currentCdnData?.name })}
        confirmButton={{ children: tCommon('button.confirm'), onClick: handleCdnDeleteClose }}
        open={openDeleteCdnDialog}
        type={StatusType.Delete}
        onClose={() => setOpenDeleteCdnDialog(false)}
      />
    </div>
  );
};
