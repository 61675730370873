import { FC, memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

interface ResponsiveProps {
  children: ReactNode;
  showAbove?: Breakpoint;
  showBelow?: Breakpoint;
}

const breakpoints: Record<Breakpoint, number> = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

const memoizedMatchMedia = (() => {
  const cache: Record<string, MediaQueryList> = {};
  return (query: string) => {
    if (!cache[query] && typeof window !== 'undefined') {
      cache[query] = window.matchMedia(query);
    }
    return cache[query];
  };
})();

export const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState<boolean>(() =>
    typeof window !== 'undefined' ? memoizedMatchMedia(query).matches : false,
  );

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const mediaQuery = memoizedMatchMedia(query);

    const handleChange = (): void => setMatches(mediaQuery.matches);

    mediaQuery.addEventListener('change', handleChange);

    handleChange();

    return () => mediaQuery.removeEventListener('change', handleChange);
  }, [query]);

  return matches;
};

export const Responsive: FC<ResponsiveProps> = memo(({ children, showAbove, showBelow }) => {
  const getMediaQuery = useCallback((above?: Breakpoint, below?: Breakpoint): string => {
    if (above) return `(min-width: ${breakpoints[above]}px)`;
    if (below) return `(max-width: ${breakpoints[below] - 1}px)`;
    return '';
  }, []);

  const mediaQuery = useMemo(() => getMediaQuery(showAbove, showBelow), [getMediaQuery, showAbove, showBelow]);
  const isVisible = useMediaQuery(mediaQuery);
  const memoizedChildren = useMemo(() => children, [children]);

  if (!showAbove && !showBelow) return null;
  return isVisible ? <>{memoizedChildren}</> : null;
});

export { breakpoints };
