import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getCustomCacheRulesColumns } from '@/constants';
import { CdnConfigurationTab } from '@/enums';
import { useConfigurationList, useConfigurationLoading, useConfigurationPagination } from '@/store';
import { ConfigurationCard, DraggableTable } from '@components';

export const CustomCacheForm = ({ actionHandlers }) => {
  const tableKey = CdnConfigurationTab.CustomCache;

  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.cache.custom' });
  const { t: tButton } = useTranslation('configuration', { keyPrefix: 'buttons.cache.custom' });

  const isDataLoading = useConfigurationLoading(tableKey);
  const customCacheListData = useConfigurationList(tableKey);
  const customCachePagination = useConfigurationPagination(tableKey);

  const memorizedColumns = useMemo(getCustomCacheRulesColumns, []);
  return (
    <ConfigurationCard
      title={tTabs('customCacheRulesConfiguration')}
      ActionButtonProps={{
        actionHandlers: actionHandlers,
        loading: isDataLoading,
        createLabel: tButton('addNewCustomRules'),
      }}
    >
      <DraggableTable
        rows={customCacheListData}
        isDataLoading={isDataLoading}
        pagination={customCachePagination}
        tableKey={tableKey}
        onChangePage={() => console.log('hi')}
        columns={memorizedColumns}
        isDraggable={false}
        actionHandlers={actionHandlers}
      />
    </ConfigurationCard>
  );
};
