import { FormikProps } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ConfigurationTab, Responsive } from '@components';
import { CdnConfigurationTab } from '@enums';
import { useAppDispatch, useResetLoadSuccess } from '@hooks';
import { getMultipleRequest, useConfigurationSubmitting } from '@store';
import { validateValueEqual } from '@utils';

import { SettingsButtons } from '@components/Buttons/settingsActionButton';
import { SecurityForm } from './securityForm';

export const Security = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const tableKey = CdnConfigurationTab.Security;

  const { t: tSecurityModal } = useTranslation('configuration', { keyPrefix: 'modals.security' });
  const { t: tModal } = useTranslation('configuration', { keyPrefix: 'modals' });

  const tabs = [{ name: tSecurityModal('security'), key: 'security' }];

  const formikRef = useRef<FormikProps<any> | null>(null);
  const [openResetSettingsDialog, setOpenResetSettingsDialog] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  const isSubmitting = useConfigurationSubmitting(tableKey);

  useResetLoadSuccess([tableKey]);

  useEffect(() => {
    if (application_id) {
      dispatch(
        getMultipleRequest({
          tab: tableKey,
          requests: [{ tableKey, application_id }],
        }),
      );
    }
  }, [application_id]);

  const handleCurrentTab = useCallback((currentTab: string) => {
    setActiveTab(currentTab);
  }, []);

  const handleResetSettingsModal = useCallback(() => {
    setOpenResetSettingsDialog((prev) => !prev);
  }, []);

  const handleSaveSettings = useCallback(() => {
    formikRef?.current?.handleSubmit();
  }, []);

  const handleFormikInstance = useCallback((formik: FormikProps<any> | null) => {
    formikRef.current = formik;
  }, []);

  return (
    <div className="flex flex-col flex-1 gap-6 sm:gap-8">
      <div className="flex flex-col sm:flex-row justify-between gap-4">
        <ConfigurationTab arrayTab={tabs} currentTab={activeTab} setCurrentTab={handleCurrentTab} />
        <Responsive showAbove="sm">
          {validateValueEqual(activeTab, 'security') && (
            <SettingsButtons
              activeTab={activeTab}
              tabName="security"
              resetTitle={tSecurityModal('securityReset')}
              resetContent={tSecurityModal('areYouSureReset')}
              openResetDialog={openResetSettingsDialog}
              handleResetModal={handleResetSettingsModal}
              handleSaveModal={handleSaveSettings}
              tModal={tModal}
              formikRef={formikRef}
              isLoading={isSubmitting}
            />
          )}
        </Responsive>
      </div>
      {validateValueEqual(activeTab, 'security') && (
        <SecurityForm
          onResetClose={handleResetSettingsModal}
          onFormikInstance={handleFormikInstance}
          formikRef={formikRef}
        />
      )}
      <Responsive showBelow="sm">
        {validateValueEqual(activeTab, 'security') && (
          <SettingsButtons
            activeTab={activeTab}
            tabName="security"
            resetTitle={tSecurityModal('securityReset')}
            resetContent={tSecurityModal('areYouSureReset')}
            openResetDialog={openResetSettingsDialog}
            handleResetModal={handleResetSettingsModal}
            handleSaveModal={handleSaveSettings}
            tModal={tModal}
            formikRef={formikRef}
          />
        )}
      </Responsive>
    </div>
  );
};
