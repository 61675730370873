import { FormikProps } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ConfigurationTab, ConfirmationModal, Responsive, SettingsButtons } from '@components';
import { ROUTES } from '@constants';
import { CdnConfigurationTab, StatusType, TableActionType } from '@enums';
import { useAppDispatch, useResetLoadSuccess } from '@hooks';
import { deleteConfigurationData, getMultipleRequest, useConfigurationSubmitting } from '@store';
import { CustomWAFFormType, WhitelistWAFForm } from '@types';
import { useActionHandlers, validateValueEqual } from '@utils';

import { CustomWAFForm, PredefinedWAFRules, WAFWhiteListModal, WhiteListWAFForm } from './';

export const Waf = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const predefinedWAFKey = CdnConfigurationTab.PredefinedWAF;
  const customWAFKey = CdnConfigurationTab.CustomWAF;
  const whitelistWAFKey = CdnConfigurationTab.WhitelistWAF;

  const { t: tModal } = useTranslation('configuration', { keyPrefix: 'modals' });
  const { t: tWAFModal } = useTranslation('configuration', { keyPrefix: 'modals.waf' });
  const { t: tWAFbutton } = useTranslation('configuration', { keyPrefix: 'buttons.waf' });
  const { t: tCommon } = useTranslation('common');

  const tabs = [
    { name: tWAFbutton('wafRules'), key: 'wafRules' },
    // { name: tWAFbutton('customWaf'), key: 'customWaf' },
    // { name: tWAFbutton('wafWhitelist'), key: 'wafWhitelist' },
  ];

  const formikRef = useRef<FormikProps<any> | null>(null);
  const [currentCustomWAFData, setCurrentCustomWAFData] = useState<CustomWAFFormType>();
  const [currentWhiteListData, setCurrentWhiteListData] = useState<WhitelistWAFForm>();
  const [activeTab, setActiveTab] = useState(tabs[0].key);
  const [editApplication, setEditApplication] = useState<boolean>(false);
  const [openWhiteListModal, setOpenWhiteListModal] = useState<boolean>(false);
  const [openResetSettingsDialog, setOpenResetSettingsDialog] = useState<boolean>(false);
  const [openDeleteCustomWAFDialog, setOpenDeleteCustomWAFDialog] = useState<boolean>(false);
  const [openDeleteWAFWhiteListDialog, setOpenDeleteWAFWhiteListDialog] = useState<boolean>(false);

  const isPredefinedSubmitting = useConfigurationSubmitting(CdnConfigurationTab.PredefinedWAF);

  useResetLoadSuccess([predefinedWAFKey, customWAFKey, whitelistWAFKey]);

  useEffect(() => {
    if (application_id) {
      dispatch(
        getMultipleRequest({
          tab: CdnConfigurationTab.Origin,
          requests: [
            { tableKey: predefinedWAFKey, application_id },
            // { tableKey: customWAFKey, application_id },
            // { tableKey: whitelistWAFKey, application_id },
          ],
        }),
      );
    }
  }, [application_id]);

  const wafActionHandlers = useActionHandlers<CustomWAFFormType>({
    setData: setCurrentCustomWAFData,
    setDeleteOpen: setOpenDeleteCustomWAFDialog,
    setEdit: setEditApplication,
    tableKey: customWAFKey,
    routePath: ROUTES.configuration.waf.customRules,
    activeHandlers: [TableActionType.Create, TableActionType.Edit, TableActionType.Delete],
  });

  const wafWhiteListActionHandlers = useActionHandlers<WhitelistWAFForm>({
    setData: setCurrentWhiteListData,
    setEditOpen: setOpenWhiteListModal,
    setDeleteOpen: setOpenDeleteWAFWhiteListDialog,
    setEdit: setEditApplication,
    tableKey: whitelistWAFKey,
    activeHandlers: [TableActionType.Create, TableActionType.Edit, TableActionType.Delete],
  });

  const handleResetSettingsModal = useCallback(() => {
    setOpenResetSettingsDialog(!openResetSettingsDialog);
  }, [openResetSettingsDialog]);

  const handleSaveSettings = useCallback(() => {
    formikRef?.current?.handleSubmit();
  }, []);

  const handleCustomWAFDeleteClose = useCallback(() => {
    if (currentCustomWAFData?.id) {
      dispatch(
        deleteConfigurationData({ tableKey: customWAFKey, application_id, content_id: currentCustomWAFData?.id }),
      );
    }
    setOpenDeleteCustomWAFDialog(false);
  }, []);

  const handleWAFWhiteListDeleteClose = useCallback(() => {
    if (currentWhiteListData?.id) {
      dispatch(
        deleteConfigurationData({ tableKey: whitelistWAFKey, application_id, content_id: currentWhiteListData?.id }),
      );
    }
    setOpenDeleteWAFWhiteListDialog(false);
  }, []);

  const handleCurrentTab = useCallback((currentTab: string) => {
    setActiveTab(currentTab);
  }, []);

  const handleFormikInstance = useCallback((formik: FormikProps<any> | null) => {
    formikRef.current = formik;
  }, []);

  const tabContent = () => {
    switch (activeTab) {
      case 'customWaf':
        return <CustomWAFForm actionHandlers={wafActionHandlers} />;
      case 'wafWhitelist':
        return <WhiteListWAFForm actionHandlers={wafWhiteListActionHandlers} />;
      default:
        return (
          <PredefinedWAFRules
            onResetClose={handleResetSettingsModal}
            onSaveClose={handleSaveSettings}
            onFormikInstance={handleFormikInstance}
          />
        );
    }
  };

  return (
    <div className="flex flex-1 flex-col w-full space-y-8">
      <div className="flex flex-col flex-1 gap-6 sm:gap-8">
        <div className="flex flex-col sm:flex-row justify-between gap-4">
          <ConfigurationTab arrayTab={tabs} currentTab={activeTab} setCurrentTab={handleCurrentTab} />
          <Responsive showAbove="sm">
            {validateValueEqual(activeTab, 'wafRules') && (
              <SettingsButtons
                activeTab={activeTab}
                tabName="waf"
                resetTitle={tWAFModal('wafReset')}
                resetContent={tWAFModal('areYouSureReset')}
                openResetDialog={openResetSettingsDialog}
                handleResetModal={handleResetSettingsModal}
                handleSaveModal={handleSaveSettings}
                tModal={tModal}
                isLoading={isPredefinedSubmitting}
                formikRef={formikRef}
              />
            )}
          </Responsive>
        </div>
        {tabContent()}

        <Responsive showBelow="sm">
          {validateValueEqual(activeTab, 'wafRules') && (
            <SettingsButtons
              activeTab={activeTab}
              tabName="waf"
              resetTitle={tWAFModal('wafReset')}
              resetContent={tWAFModal('areYouSureReset')}
              openResetDialog={openResetSettingsDialog}
              handleResetModal={handleResetSettingsModal}
              handleSaveModal={handleSaveSettings}
              tModal={tModal}
              isLoading={isPredefinedSubmitting}
              formikRef={formikRef}
            />
          )}
        </Responsive>
        {openWhiteListModal && (
          <WAFWhiteListModal
            data={currentWhiteListData}
            isEdit={editApplication}
            open={openWhiteListModal}
            onClose={() => setOpenWhiteListModal(false)}
          />
        )}

        <ConfirmationModal
          title={tWAFModal('wafDelete')}
          content={tModal('areYouSureDelete', { domain: currentCustomWAFData?.name })}
          confirmButton={{ children: tCommon('button.confirm'), onClick: handleCustomWAFDeleteClose }}
          open={openDeleteCustomWAFDialog}
          type={StatusType.Delete}
          onClose={() => setOpenDeleteCustomWAFDialog(false)}
        />
        <ConfirmationModal
          title={tWAFModal('wafDelete')}
          content={tModal('areYouSureDelete', { domain: currentCustomWAFData?.name })}
          confirmButton={{ children: tCommon('button.confirm'), onClick: handleWAFWhiteListDeleteClose }}
          open={openDeleteWAFWhiteListDialog}
          type={StatusType.Delete}
          onClose={() => setOpenDeleteWAFWhiteListDialog(false)}
        />
      </div>
    </div>
  );
};
