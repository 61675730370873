import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export const useErrorFormInner = ({ submitError, isSubmitting }) => {
  const { setFieldError, setFieldTouched } = useFormikContext();

  useEffect(() => {
    if (!isSubmitting && submitError) {
      const convertNewlinesToBr = (str) => {
        return typeof str === 'string' ? str.replace(/\n/g, '<br />') : str;
      };

      const safeSetFieldError = (field, error) => {
        try {
          setFieldError(field, convertNewlinesToBr(error));
          setFieldTouched(field, true, false);
        } catch (e) {
          console.error(`Error setting field error for ${field}:`, e);
        }
      };

      const handleError = (field, error) => {
        if (Array.isArray(error)) {
          error.forEach((err, index) => {
            if (typeof err === 'object' && err !== null) {
              Object.entries(err).forEach(([subField, subError]) => {
                const errorMessage = typeof subError === 'string' ? subError : JSON.stringify(subError);
                safeSetFieldError(`${field}`, errorMessage);
              });
            } else {
              const errorMessage = typeof err === 'string' ? err : JSON.stringify(err);
              safeSetFieldError(`${field}[${index}]`, errorMessage);
            }
          });
        } else if (typeof error === 'string') {
          safeSetFieldError(field, error);
        } else if (typeof error === 'object' && error !== null) {
          Object.entries(error).forEach(([subField, subError]) => {
            const errorMessage = typeof subError === 'string' ? subError : JSON.stringify(subError);
            safeSetFieldError(`${field}.${subField}`, errorMessage);
          });
        }
      };

      if (typeof submitError === 'string') {
        safeSetFieldError('submitError', submitError);
      } else if (typeof submitError === 'object' && submitError !== null) {
        Object.entries(submitError).forEach(([field, error]) => {
          handleError(field, error);
        });
      }
    }
  }, [isSubmitting, submitError, setFieldError, setFieldTouched]);
};
