import { initData } from '@/storage';
import {
  clearWorkSpaceId,
  fetchTokenContinuously,
  fetchTokenContinuouslyError,
  fetchTokenContinuouslySuccess,
  getUserSummary,
} from '@store';
import { appInit } from '@store/slices/initSlice';
import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getAllStorageData } from '../storage';

const getNormalizedLanguage = () => {
  const browserLanguage = navigator.language.toLowerCase();

  if (browserLanguage.startsWith('en')) {
    return 'en';
  } else if (browserLanguage.startsWith('zh')) {
    return 'cn';
  } else {
    return 'en';
  }
};

function* initSaga() {
  const { current_init_data } = yield call(getAllStorageData);
  if (!current_init_data) {
    try {
      const detectTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
      const initializeInit = {
        timeZone: detectTimeZone(),
        displayMode: 'light',
        userId: null,
        language: getNormalizedLanguage(),
      };
      yield call(initData.setItem, initializeInit);
      yield put(clearWorkSpaceId());
    } catch (e) {
      console.error(e);
    }
  }
  yield put(fetchTokenContinuously());
  const { success } = yield race({
    success: take(fetchTokenContinuouslySuccess),
    error: take(fetchTokenContinuouslyError),
  });

  if (success) {
    yield put(getUserSummary());
  }
}

export function* initSagaTakeEvery() {
  yield takeLatest(appInit.type, initSaga);
}
