import { useElementScrollLock } from '@/hooks';
import * as Headless from '@headlessui/react';
import clsx from 'clsx';
import { Form } from 'formik';
import { motion } from 'framer-motion';
import type React from 'react';
import { fontLevelClass, Text } from '../Text/text';

export const modalSizes = {
  xs: 'sm:max-w-xs',
  sm: 'sm:max-w-sm',
  md: 'sm:max-w-md',
  lg: 'sm:max-w-lg',
  xl: 'sm:max-w-xl',
  '2xl': 'sm:max-w-2xl',
  '3xl': 'sm:max-w-3xl',
  '4xl': 'sm:max-w-4xl',
  '5xl': 'sm:max-w-5xl',
};

export function DialogModal({
  open,
  onClose,
  size = 'lg',
  className,
  notForm = false,
  children,
  withoutPadding = false,
  ...props
}: {
  size?: keyof typeof modalSizes;
  className?: string;
  children: React.ReactNode;
  withoutPadding?: boolean;
  notForm?: boolean;
  open: boolean;
  onClose: () => void;
} & Omit<Headless.DialogProps, 'className'>) {
  useElementScrollLock('mainContainer', open);

  const backdropVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const modalVariants = {
    hidden: { opacity: 0, y: '100%' },
    visible: { opacity: 1, y: 0 },
  };

  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <Headless.Dialog open={open} onClose={onClose} className="fixed inset-0 z-40 overflow-y-auto" {...props}>
      <motion.div
        initial="hidden"
        animate={open ? 'visible' : 'hidden'}
        variants={backdropVariants}
        transition={{ duration: 0.3, ease: 'easeOut' }}
        className="fixed inset-0 bg-zinc-950/25 dark:bg-zinc-950/50 backdrop-blur-sm"
        onClick={handleBackdropClick}
        style={{ pointerEvents: 'auto' }}
      />

      <div className="fixed inset-0 w-screen overflow-y-auto padded-scrollbar-track pt-6 sm:pt-0">
        <div
          className={clsx(
            notForm ? 'px-6 pb-6 items-center' : 'items-end sm:items-center',
            'min-h-full flex justify-center sm:p-4',
          )}
        >
          <motion.div
            initial="hidden"
            animate={open ? 'visible' : 'hidden'}
            variants={modalVariants}
            transition={{ duration: 0.3, ease: 'easeOut' }}
            onClick={(e) => e.stopPropagation()}
            className={clsx(
              className,
              modalSizes[size],
              notForm ? 'rounded-3xl' : 'rounded-t-3xl',
              withoutPadding
                ? ''
                : 'p-[theme(spacing.8)]  pt-[theme(spacing.10)] sm:pt-[--gutter] sm:[--gutter:theme(spacing.8)]',
              'row-start-2 w-full min-w-0 bg-white shadow-card ring-1 ring-zinc-950/10 sm:rounded-3xl dark:bg-zinc-900 dark:ring-white/10 forced-colors:outline',
            )}
          >
            {children}
          </motion.div>
        </div>
      </div>
    </Headless.Dialog>
  );
}

export function ModalTitle({
  className,
  level = 2,
  ...props
}: { className?: string; level?: number } & Omit<Headless.DialogTitleProps, 'className'>) {
  return (
    <Headless.DialogTitle
      {...props}
      className={clsx(
        className,
        fontLevelClass[level],
        'text-balance font-medium text-zinc-950 sm:text-base/6 dark:text-white leading-5',
      )}
    />
  );
}

export function ModalDescription({
  className,
  $level = 5,
  ...props
}: { className?: string; level?: number } & Omit<Headless.DescriptionProps<typeof Text>, 'className'>) {
  return (
    <Headless.Description
      as={Text}
      {...props}
      $level={$level}
      className={clsx(className, $level && fontLevelClass[$level], ' mt-4 sm:mt-3 text-pretty leading-4')}
    />
  );
}

type DivProps = React.ComponentPropsWithoutRef<'div'>;
type FormProps = React.ComponentPropsWithoutRef<'form'>;

interface ModalBodyProps extends DivProps {
  notForm?: boolean;
}

export function ModalBody({ className, notForm = false, ...props }: ModalBodyProps) {
  const commonClassName = clsx(className, 'gap-4 sm:gap-5 flex flex-col');

  if (notForm) {
    return <div {...props} className={commonClassName} />;
  }

  return <Form {...(props as FormProps)} className={commonClassName} />;
}

export function ModalActions({ className, ...props }: Readonly<React.ComponentPropsWithoutRef<'div'>>) {
  return (
    <div
      {...props}
      className={clsx(className, 'mt-3 flex flex-row items-center justify-end gap-7 *:w-full sm:flex-row w-auto')}
    />
  );
}
