import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ConfigurationTab, Responsive, SettingsButtons } from '@components';
import { CdnConfigurationTab, TableActionType } from '@enums';
import { useAppDispatch } from '@hooks';
import { getMultipleRequest } from '@store';
import { useActionHandlers, validateValueEqual } from '@utils';

import { FormikProps } from 'formik';
import { PredefinedPageRulesForm } from './subCards';
import { PageRulesForm, PageRulesModal } from './subComponents';

export const PageRules: FC = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const tableKey = CdnConfigurationTab.PredefinedPageRules;

  const { t: tModal } = useTranslation('configuration', { keyPrefix: 'modals' });
  const { t: tPageRulesModal } = useTranslation('configuration', { keyPrefix: 'modals.pageRules' });
  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.pageRules' });

  useEffect(() => {
    if (application_id) {
      dispatch(
        getMultipleRequest({
          tab: CdnConfigurationTab.PageRules,
          requests: [{ tableKey, application_id }],
        }),
      );
    }
  }, [application_id]);

  const formikRef = useRef<FormikProps<any> | null>(null);
  const [openCustomPageRulesModal, setOpenCustomPageRulesModal] = useState<boolean>(false);
  const [editApplication, setEditApplication] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<any>();
  const [openResetSettingsDialog, setOpenResetSettingsDialog] = useState<boolean>(false);

  const tabs = [
    { name: tTabs('pageRules'), key: 'pageRules' },
    // { name: 'Custom configuration', key: 'customConfiguration' },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].key);

  const handleCurrentTab = useCallback((currentTab: string) => {
    setActiveTab(currentTab);
  }, []);

  const PageRulesActionHandlers = useActionHandlers<any>({
    setData: setCurrentData,
    setEditOpen: setOpenCustomPageRulesModal,
    setEdit: setEditApplication,
    tableKey: 'pageRules',
    activeHandlers: [TableActionType.Create, TableActionType.Edit, TableActionType.Delete, TableActionType.Priority],
  });

  const handleResetSettingsModal = useCallback(() => {
    setOpenResetSettingsDialog((prev) => !prev);
  }, []);

  const handleSaveSettings = useCallback(() => {
    formikRef?.current?.handleSubmit();
  }, []);

  const handleFormikInstance = useCallback((formik: FormikProps<any> | null) => {
    formikRef.current = formik;
  }, []);

  return (
    <div className="flex flex-col flex-1 space-y-8">
      <div className="flex justify-between">
        <ConfigurationTab arrayTab={tabs} currentTab={activeTab} setCurrentTab={handleCurrentTab} />
        <Responsive showAbove="sm">
          {validateValueEqual(activeTab, 'pageRules') && (
            <SettingsButtons
              activeTab={activeTab}
              tabName="pageRules"
              resetTitle={tPageRulesModal('resetSettings')}
              resetContent={tPageRulesModal('areYouSureReset')}
              saveTitle={tPageRulesModal('saveSettings')}
              saveContent={tPageRulesModal('areYouSureSave')}
              openResetDialog={openResetSettingsDialog}
              handleResetModal={handleResetSettingsModal}
              handleSaveModal={handleSaveSettings}
              tModal={tModal}
              formikRef={formikRef}
            />
          )}
        </Responsive>
      </div>
      <div className={`space-y-8`}>
        {validateValueEqual(activeTab, 'pageRules') ? (
          <PredefinedPageRulesForm
            onSaveClose={handleSaveSettings}
            onResetClose={handleResetSettingsModal}
            onFormikInstance={handleFormikInstance}
          />
        ) : (
          <PageRulesForm actionHandlers={PageRulesActionHandlers} />
        )}
      </div>
      <Responsive showBelow="sm">
        {validateValueEqual(activeTab, 'pageRules') && (
          <SettingsButtons
            activeTab={activeTab}
            tabName="pageRules"
            resetTitle={tPageRulesModal('resetSettings')}
            resetContent={tPageRulesModal('areYouSureReset')}
            saveTitle={tPageRulesModal('saveSettings')}
            saveContent={tPageRulesModal('areYouSureSave')}
            openResetDialog={openResetSettingsDialog}
            handleResetModal={handleResetSettingsModal}
            handleSaveModal={handleSaveSettings}
            tModal={tModal}
            formikRef={formikRef}
          />
        )}
      </Responsive>
      {openCustomPageRulesModal && (
        <PageRulesModal
          data={currentData}
          isEdit={editApplication}
          open={openCustomPageRulesModal}
          onClose={() => setOpenCustomPageRulesModal(false)}
        />
      )}
    </div>
  );
};
