import { Fragment } from 'react';

import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';

import appLogo from '@assets/logo.webp';

import { Button } from '@components';
import { VariantType } from '@enums';
import { useAppDispatch, useSidebarNavigation } from '@hooks';
import { setSidebarCollapsible, useSidebarCollapsed } from '@store';

import { useTranslation } from 'react-i18next';
import { NavigationCategory } from './NavigationCategory';

export const Sidebar = ({ status, sidebarAction }) => {
  const { t: tCommon } = useTranslation('common');

  const dispatch = useAppDispatch();

  const categories = useSidebarNavigation();
  const isCollapse = useSidebarCollapsed();

  const sidebarWidth = isCollapse ? 'w-20' : 'min-w-64';
  const toggleSidebar = () => dispatch(setSidebarCollapsible());

  return (
    <>
      <Transition show={status} as={Fragment}>
        <Dialog as="div" className={`fixed inset-0 overflow-hidden z-30 xl:hidden w-72`} onClose={sidebarAction}>
          <TransitionChild
            as={Fragment}
            enter="transform ease-in-out duration-300"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transform ease-in-out duration-300"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="flex p-4 bg-theme-primary-dark flex-col h-screen w-72 text-white overflow-y-auto shadow-md padded-scrollbar-track">
              <div className="flex flex-col h-screen w-full pb-8 gap-y-8">
                <div className="flex flex-col flex-1 space-y-6">
                  <div className="flex w-full"></div>
                  <NavigationCategory isCollapse={false} categories={categories} />
                </div>
                <div className="flex items-center justify-center font-semibold text-md pb-2">
                  <img src={appLogo} alt="7Bull Logo" className="w-10" />
                </div>
              </div>
            </div>
          </TransitionChild>
        </Dialog>
      </Transition>

      {/* Static sidebar for desktop */}
      <div
        className={`hidden xl:flex xl:inset-y-0 sticky ${sidebarWidth} xl:flex-col bg-white bg-opacity-90 shadow-card text-black transition-all duration-300 rounded-xl overflow-hidden mb-8`}
      >
        <div className={`flex flex-col max-h-screen h-full space-y-4 ${isCollapse ? 'items-center pb-3' : 'py-3 '}`}>
          <div className={`${isCollapse ? 'hidden' : 'flex'} items-center justify-between space-x-3 pt-3 px-6`}>
            <img src={appLogo} alt="7Bull Logo" className="transition-all duration-500 ease-in-out w-10" />
            <div className="flex-1">
              <article className="font-semibold text-lg">7Bull</article>
              <article className="text-us font-medium text-gray-400">{tCommon('unifiedDashboardPortal')}</article>
            </div>
          </div>
          <div
            className={`px-7 overflow-y-auto justify-between overflow-x-hidden flex flex-1 flex-col pb-3 gap-3 ${isCollapse ? 'items-center' : 'pt-4'}`}
          >
            <div className={`${isCollapse ? 'space-y-2' : 'space-y-5'}`}>
              <NavigationCategory isCollapse={isCollapse} categories={categories} />
            </div>
            <Button
              variant={VariantType.Transparent}
              withoutCenter
              withoutPadding
              iconWithoutM
              icon={
                isCollapse ? (
                  <ChevronDoubleRightIcon className="w-5 h-5" />
                ) : (
                  <ChevronDoubleLeftIcon className="w-5 h-5" />
                )
              }
              onClick={toggleSidebar}
              className={'rounded-md space-x-3 items-center text-slate-600 pt-3'}
            >
              {!isCollapse && <article className="text-slate-600">{tCommon('button.toggleSideBar')}</article>}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
