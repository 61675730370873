import { Formik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { applicationFormSchema } from '@/constants';
import { useAppDispatch } from '@/hooks';
import { getAllStorageData } from '@/storage';
import {
  resetConfiguration,
  resetCurrentForm,
  submitFormRequest,
  useFormContent,
  useFormError,
  useFormSaveStatus,
  useFormSubmissionStatus,
} from '@/store';
import { FormField, FormModal } from '@components';
import { columnDirection, FormType, InputType, originType } from '@enums';
import { Dictionaries, getOptionListFromCatalog, validateValueEqual } from '@utils';

interface CdnApplicationModalProps {
  open: boolean;
  onClose: () => void;
}

export const CdnApplicationModal = ({ open, onClose }: CdnApplicationModalProps) => {
  const { t: tCdnApplicationForm } = useTranslation('form', { keyPrefix: 'addApplication.content' });
  const { t: tModal } = useTranslation('cdn', { keyPrefix: 'modals' });
  const { t: tValidation } = useTranslation('validation', { keyPrefix: 'format' });
  const { t: tCommonIp } = useTranslation('common', { keyPrefix: 'ip' });
  const { t: tOriginForm } = useTranslation('configuration', { keyPrefix: 'forms.origin' });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { current_workspace_id: workspace_id } = getAllStorageData();

  const submitError = useFormError(FormType.Cdn);
  const isSubmitting = useFormSubmissionStatus(FormType.Cdn);
  const saveSuccess = useFormSaveStatus(FormType.Cdn);

  const currentCdnData = useFormContent(FormType.Cdn);

  const handleSubmit = (values: any) => {
    const formData = {
      ...values,
      verify_ssl_cert: values.type === originType.HTTPS ? values.verify_ssl_cert : null,
      workspace_id,
    };
    dispatch(submitFormRequest({ formType: FormType.Cdn, formData }));
  };

  useEffect(() => {
    if (!submitError && !isSubmitting && saveSuccess) {
      onClose();
      dispatch(resetCurrentForm(FormType.Cdn));
      dispatch(resetConfiguration());
      currentCdnData?.id && navigate(`${currentCdnData.id}/configuration`);
    }
  }, [currentCdnData, dispatch, isSubmitting, navigate, onClose, saveSuccess, submitError]);

  const initialValues: any = {
    name: '',
    domain: '',
    type: originType.HTTPS,
    origin: '',
    verify_ssl_cert: true,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={applicationFormSchema} onSubmit={handleSubmit}>
      {({ values, touched, errors }) => {
        return (
          <FormModal
            title={tModal('addNewApplication')}
            description={tModal('addNewApplicationDescription')}
            onClose={onClose}
            submitError={submitError}
            open={open}
            isSubmitting={isSubmitting}
            size="lg"
            confirmButton={{ children: tModal('addNewApplication') }}
          >
            <FormField
              direction={columnDirection.Row}
              label={tCdnApplicationForm('applicationName')}
              name="name"
              smallFont
              placeholder={tCdnApplicationForm('enterYourApplicationName')}
              isCorrect={touched.name && !errors.name}
              required
              fullWidth
            />
            <FormField
              direction={columnDirection.Row}
              label={tCdnApplicationForm('domainName')}
              name="domain"
              hints={tValidation('domain')}
              smallFont
              placeholder={tCdnApplicationForm('enterYourDomain')}
              required
              fullWidth
            />
            <FormField
              direction={columnDirection.Row}
              label={tCdnApplicationForm('originType')}
              className={'text-gray-400'}
              name="type"
              smallFont
              inputType={InputType.Select}
              fullWidth
              placeholder={tCdnApplicationForm('selectYourOriginType')}
              options={getOptionListFromCatalog(Dictionaries.OriginType)}
            />
            <FormField
              direction={columnDirection.Row}
              label={
                <article className="text-gray-700 text-left text-sm font-normal leading-6">
                  {`${tCdnApplicationForm('origin')}`}
                  <span className="text-gray-400 text-us"> {`(${tCommonIp('ipv4Address')})`}</span>
                </article>
              }
              smallFont
              hints={tValidation('ip')}
              name="origin"
              placeholder={tCdnApplicationForm('enterYourOrigin')}
              fullWidth
            />
            {validateValueEqual(values.type, originType.HTTPS) && (
              <FormField
                label={tOriginForm('verifySSL')}
                name="verify_ssl_cert"
                inputType={InputType.Switch}
                direction={columnDirection.Row}
                smallFont
                mobileTextInput="justify-between"
              />
            )}
          </FormModal>
        );
      }}
    </Formik>
  );
};
