import './i18n';

import { FC, lazy, Suspense, useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import useNavigatorOnline from 'use-navigator-online';

import { ROUTES } from '@/constants';
import { GlobalLoader, ProtectedRoute } from '@components';
import { ConfigurationLayout, MainContentContainer, MainLayout } from '@containers';
import { useAppDispatch, useCheckUserActivity, useClearInterval, useRoutePermissions, useTokenManager } from '@hooks';
import { appInit, runSaga, systemUpdateNetworkConnection, useInitLoading } from '@store';

import { Cdn } from '@pages/Cdn/Cdn';
import { Cache } from '@pages/Cdn/subPages/Cache/Cache';
import { CdnDashboard } from '@pages/Cdn/subPages/CdnDashboard/CdnDashboard';
import { Domain } from '@pages/Cdn/subPages/Domain/Domain';
import { Origins } from '@pages/Cdn/subPages/Origins/Origins';
import { PageRules } from '@pages/Cdn/subPages/PageRules/PageRules';
import { Ssl } from '@pages/Cdn/subPages/SSL/SSL';
import { Security } from '@pages/Cdn/subPages/Security/Security';
import { Waf } from '@pages/Cdn/subPages/WAF/WAF';
import { CustomWAFRules } from '@pages/Cdn/subPages/WAF/subComponents/CustomWAFModal';
import { HomePage } from '@pages/HomePage/HomePage';
import { Login } from '@pages/Login/Login';
import { Maintenance } from '@pages/Maintenance/Maintenance';
import { ProfileSettings } from '@pages/Profile/ProfileSettings';

import rootSaga from '@sagas';
import AppConfiguration from './pages/Cdn/subPages/Configuration/AppConfiguration';

const Snacks = lazy(() => import('./components/Snacks/Snacks'));

export const App: FC = () => {
  const dispatch = useAppDispatch();
  const dispatchRef = useRef(dispatch);

  useTokenManager();
  useCheckUserActivity();
  useClearInterval();

  const { showRoute } = useRoutePermissions();
  const isInitLoading = useInitLoading();
  const { isOnline, isOffline, backOnline, backOffline } = useNavigatorOnline();

  useEffect(() => {
    if (backOnline || backOffline) {
      localStorage.setItem('lastPageActivityTimeStamp', JSON.stringify(new Date().getTime()));
      dispatch({
        type: systemUpdateNetworkConnection.type,
        payload: {
          isOnline,
          isOffline,
          backOnline,
          backOffline,
        },
      });
    }
  }, [backOnline, backOffline, isOnline, isOffline]);

  useEffect(() => {
    dispatchRef.current = dispatch;
  }, [dispatch]);

  useEffect(() => {
    runSaga(rootSaga);
    dispatchRef.current(appInit());
  }, []);

  if (isInitLoading) return <GlobalLoader overlay />;
  return (
    <Router>
      <DndProvider backend={HTML5Backend}>
        <Suspense fallback={<GlobalLoader overlay />}>
          <div className="relative h-full w-full overflow-hidden">
            <Snacks />
            <Routes>
              <Route
                path={ROUTES.login}
                element={
                  <ProtectedRoute isAllowed={showRoute.login}>
                    <Login />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES.maintenance}
                element={
                  <ProtectedRoute isAllowed>
                    <Maintenance />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES.homepage}
                element={
                  <ProtectedRoute isAllowed={showRoute.homepage}>
                    <MainContentContainer>
                      <MainLayout>
                        <HomePage />
                      </MainLayout>
                    </MainContentContainer>
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES.profileSettings}
                element={
                  <ProtectedRoute isAllowed={showRoute.profileSettings}>
                    <MainContentContainer>
                      <MainLayout>
                        <ProfileSettings />
                      </MainLayout>
                    </MainContentContainer>
                  </ProtectedRoute>
                }
              />
              <Route path={ROUTES.cdn}>
                <Route
                  index
                  element={
                    <ProtectedRoute isAllowed={showRoute.cdn}>
                      <MainContentContainer>
                        <MainLayout>
                          <Cdn />
                        </MainLayout>
                      </MainContentContainer>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`:id/${ROUTES.configuration.main}/*`}
                  element={
                    <ProtectedRoute isAllowed={showRoute.cdn}>
                      <MainContentContainer>
                        <ConfigurationLayout>
                          <MainLayout>
                            <Routes>
                              <Route index element={<Navigate to={ROUTES.configuration.dashboard} replace />} />
                              <Route path={ROUTES.configuration.domain} element={<Domain />} />
                              <Route path={ROUTES.configuration.origins} element={<Origins />} />
                              <Route path={ROUTES.configuration.ssl} element={<Ssl />} />
                              <Route path={ROUTES.configuration.cache} element={<Cache />} />
                              <Route path={ROUTES.configuration.dashboard} element={<CdnDashboard />} />
                              <Route path={ROUTES.configuration.pagerules} element={<PageRules />} />
                              <Route path={ROUTES.configuration.waf.main} element={<Waf />} />
                              <Route path={ROUTES.configuration.security} element={<Security />} />
                              <Route
                                path={`${ROUTES.configuration.waf.main}/${ROUTES.configuration.waf.customRules}`}
                                element={<CustomWAFRules />}
                              />
                              <Route path={ROUTES.configuration.appConfiguration} element={<AppConfiguration />} />
                            </Routes>
                          </MainLayout>
                        </ConfigurationLayout>
                      </MainContentContainer>
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<Navigate to={ROUTES.cdn} replace />} />
              </Route>
              <Route path="*" element={<Navigate to={ROUTES.homepage} replace />} />
            </Routes>
          </div>
        </Suspense>
      </DndProvider>
    </Router>
  );
};
