import { ipValidationReg } from '@/regexp';
import i18n from 'i18next';
import * as Yup from 'yup';

export const originFormSchema = Yup.object().shape({
  name: Yup.string().required(() => i18n.t('validation:origin.originRequired')),
  type: Yup.string().required(() => i18n.t('validation:origin.originTypeRequired')),
  origins: Yup.array()
    .of(
      Yup.object().shape({
        ip: Yup.string()
          .matches(
            ipValidationReg,
            () => `${i18n.t('validation:origin.checkIpFormat')} (${i18n.t('validation:format.ip')})`,
          )
          .required(() => i18n.t('validation:origin.filledIpRequired')),
      }),
    )
    .min(1, () => i18n.t('validation:origin.minimumOneIp')),
});
