import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getCdnWAFWhitelistColumns } from '@/constants';
import { CdnConfigurationTab } from '@/enums';
import { ConfigurationCard, DraggableTable } from '@components';
import { useConfigurationList, useConfigurationLoading, useConfigurationPagination } from '@store';

export const WhiteListWAFForm = ({ actionHandlers }) => {
  const tableKey = CdnConfigurationTab.WhitelistWAF;

  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.waf' });
  const { t: tButton } = useTranslation('configuration', { keyPrefix: 'buttons.waf' });

  const whiteListData = useConfigurationList(tableKey);
  const isWhiteListLoading = useConfigurationLoading(tableKey);
  const whiteListPagination = useConfigurationPagination(tableKey);

  const whiteListColumns = useMemo(getCdnWAFWhitelistColumns, []);

  return (
    <ConfigurationCard
      title={tTabs('whiteList')}
      ActionButtonProps={{
        actionHandlers: actionHandlers,
        loading: isWhiteListLoading,
        createLabel: tButton('addNewWhiteList'),
      }}
    >
      <DraggableTable
        rows={whiteListData}
        isDataLoading={isWhiteListLoading}
        pagination={whiteListPagination}
        tableKey={tableKey}
        onChangePage={() => console.log('hi')}
        columns={whiteListColumns}
        isDraggable={false}
        actionHandlers={actionHandlers}
      />
    </ConfigurationCard>
  );
};
