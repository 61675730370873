import { DialogPanel } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, DialogModal, getColorMapping, ModalActions, ModalBody, Text } from '@components';
import { StatusType, VariantType } from '@enums';

type ConfirmButtonProps = {
  children: ReactNode;
  onClick: () => void;
};

type DomainFormProps = {
  title: ReactNode;
  content: ReactNode;
  open: boolean;
  hideIcon?: boolean;
  onClose: () => void;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
  confirmButton?: ConfirmButtonProps;
  type?: StatusType;
  topTitle?: boolean;
  className?: string;
};

export const InformationModal = ({
  title,
  content,
  open,
  hideIcon = false,
  topTitle = false,
  confirmButton,
  className,
  size = 'md',
  onClose,
  type,
}: DomainFormProps) => {
  const { t: tButton } = useTranslation('common', { keyPrefix: 'button' });
  const colorMapping = getColorMapping(type ?? StatusType.Info);

  if (!open) return;
  return (
    <DialogModal notForm={true} open={open} onClose={onClose} withoutPadding size={size}>
      <DialogPanel className={clsx(className, 'gap-5 flex flex-col')}>
        <div className={`${topTitle ? 'border-b' : ''} flex items-center justify-between px-6 py-4`}>
          <div className="flex space-x-4 items-center">
            {!hideIcon && topTitle && colorMapping.icon({ className: 'w-5 h-5 ' })}
            <div>{topTitle && title}</div>
          </div>
          <Button
            variant={VariantType.Transparent}
            withoutPadding
            iconWithoutM
            className="p-2.5"
            rounded="rounded-full"
            hoverEffect
            icon={<XMarkIcon className="w-5 h-5 " onClick={onClose} />}
          />
        </div>
        <ModalBody notForm className="max-w-full px-6 pb-7">
          {!hideIcon && !topTitle && (
            <div className={`rotate-45 w-fit rounded p-1.5 ${colorMapping.background.light} ${colorMapping.text.main}`}>
              <div className="-rotate-45"> {colorMapping.icon({ className: 'size-8' })}</div>
            </div>
          )}
          <div className="space-y-3 flex flex-col w-full">
            {hideIcon && !topTitle && (
              <article className="text-lg font-medium leading-6 text-gray-700">{title}</article>
            )}
            {typeof content === 'string' ? (
              <Text $level={4} className="font-normal text-sm leading-6">
                {content}
              </Text>
            ) : (
              content
            )}
          </div>
          {confirmButton && (
            <ModalActions>
              <Button variant={VariantType.Outlined} onClick={onClose} className="items-center ring-theme-primary-main">
                <article className="ellipsis-text">{tButton('cancel')}</article>
              </Button>
              <Button className="bg-indigo-500 text-white" {...confirmButton} />
            </ModalActions>
          )}
        </ModalBody>
      </DialogPanel>
    </DialogModal>
  );
};
