import { Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormField, PredefinedCards, Skeleton } from '@components';
import { CdnConfigurationTab, InputMod, InputType, StatusType, pageRulesRedirectOptionType } from '@enums';
import { useAppDispatch } from '@hooks';
import {
  addSnack,
  resetConfigurationForm,
  submitConfigurationForm,
  useConfigurationError,
  useConfigurationList,
  useConfigurationLoading,
  useConfigurationSaveSuccess,
  useConfigurationSubmitting,
} from '@store';
import { Dictionaries, getOptionListFromCatalog } from '@utils';

interface PredefinedPageRulesViewProps {
  onResetClose: () => void;
  onSaveClose: () => void;
  onFormikInstance: any;
}

export const PredefinedPageRulesForm = ({
  onResetClose,
  onSaveClose,
  onFormikInstance,
}: PredefinedPageRulesViewProps) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const tableKey = CdnConfigurationTab.PredefinedPageRules;

  const { t: tToasts } = useTranslation('configuration', { keyPrefix: 'toasts.predefinedPageRules' });
  const { t: tForms } = useTranslation('configuration', { keyPrefix: 'forms.pageRules' });

  const [showCurrentToast, setShowCurrentToast] = useState<boolean>(false);
  const [currentDataKey, setCurrentDataKey] = useState<any>(null);

  const pageRulesList = useConfigurationList(tableKey);
  const submitError = useConfigurationError(tableKey);
  const isSubmitting = useConfigurationSubmitting(tableKey);
  const saveSuccess = useConfigurationSaveSuccess(tableKey);
  const isDataLoading = useConfigurationLoading(tableKey);

  const initialPageRulesValues = {
    http_redirection: pageRulesRedirectOptionType.No_Redirection,
  };

  const initialValues = {
    http_redirection: pageRulesList?.http_redirection ?? initialPageRulesValues.http_redirection,
  };

  const handleReset = () => {
    dispatch(
      submitConfigurationForm({
        formData: { application_id, ...initialPageRulesValues },
        tableKey,
        returnResult: true,
      }),
    );
    setCurrentDataKey(null);
    onResetClose();
  };
  const handleSubmit = (values) => {
    dispatch(
      submitConfigurationForm({
        formData: { application_id, ...values },
        tableKey,
        returnResult: true,
      }),
    );
  };

  useEffect(() => {
    if (!submitError && !isSubmitting && saveSuccess) {
      if (showCurrentToast && currentDataKey) {
        dispatch(addSnack({ type: StatusType.Success, message: tToasts(`${currentDataKey}Configured`) }));
        setShowCurrentToast(false);
        setCurrentDataKey(null);
      } else {
        dispatch(resetConfigurationForm(tableKey));
      }
    }
  }, [application_id, currentDataKey, isSubmitting, saveSuccess, showCurrentToast, submitError]);

  if (isDataLoading)
    return (
      <div className="space-y-4">
        {[...Array(1)].map((_, index) => (
          <Skeleton key={index} className="flex w-full rounded-2xl h-32" />
        ))}
      </div>
    );

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} onReset={handleReset}>
      {(formik) => {
        if (onFormikInstance) onFormikInstance(formik);
        return (
          <Fragment>
            <PredefinedCards
              title={tForms('httpHttpsRedirection')}
              description={tForms('chooseHowYouWouldLikeToHnadleHTTPAndHTTPSRedirectionForYourSite')}
            >
              <FormField
                label={tForms('redirection')}
                name="http_redirection"
                inputType={InputType.Select}
                inputMod={InputMod.Filled}
                withoutLabel
                smallFont
                fullWidth
                loading={isSubmitting}
                placeholder={tForms('pleaseSelectYourRedirectDestination')}
                options={getOptionListFromCatalog(Dictionaries.PageRulesRedirectOption)}
              />
            </PredefinedCards>
          </Fragment>
        );
      }}
    </Formik>
  );
};
