import i18n from 'i18next';

import { Column } from '@components';
import { CellType } from '@enums';
import { Dictionaries, getCodeValueFromCatalog, getColumnSizes } from '@utils';

export const getCustomCacheRulesColumns = (): Column[] => [
  {
    label: i18n.t('configuration:tables.id'),
    cellType: CellType.Custom,
    value: 'id',
    format: (data) => <div className="ml-3 text-us h-full font-normal text-theme-primary-main">{data}</div>,
    multiLines: true,
    ...getColumnSizes(70),
  },
  {
    label: i18n.t('configuration:tables.rulesName'),
    value: 'name',
    multiLines: true,
    ...getColumnSizes(200),
    cellType: CellType.Custom,
    format: (value, rowData) => (
      <div className="flex flex-col space-y-1">
        <article className="font-normal">{value}</article>
        <article className="font-normal text-xs text-gray-400">
          {rowData.cacheKey.map((key) => getCodeValueFromCatalog(Dictionaries.Variable, key.components)).join(', ')}
        </article>
      </div>
    ),
  },
  {
    label: i18n.t('configuration:tables.action'),
    value: 'actions',
    cellType: CellType.Action,
    multiLines: true,
    ...getColumnSizes(100),
  },
];
