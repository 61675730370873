import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CdnConfigurationTab, StatusType, TableActionType } from '@/enums';
import { ConfirmationModal } from '@components';
import { useAppDispatch, useResetLoadSuccess } from '@hooks';
import { deleteConfigurationData, getMultipleRequest, useConfigurationList } from '@store';
import { SSLConfigurationForm } from '@types';

import { useActionHandlers } from '@/utils';
import { TableActionHandlerType } from '@enums';
import { useParams } from 'react-router-dom';
import { SSLForm, SSLModal } from './subComponents';
import { ViewModal } from './subComponents/viewModal';

export const Ssl: FC = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const tableKey = CdnConfigurationTab.SSL;

  const { t: tModal } = useTranslation('configuration', { keyPrefix: 'modals' });
  const { t: tSSLModal } = useTranslation('configuration', { keyPrefix: 'modals.ssl' });
  const { t: tCommon } = useTranslation('common');

  const [open, setOpen] = useState<boolean>(false);
  const [openViewDialog, setOpenViewDialog] = useState<boolean>(false);
  const [editApplication, setEditApplication] = useState<boolean>(false);
  const [currentSSLData, setCurrentSSLData] = useState<SSLConfigurationForm>();
  const [openDeleteSSLDialog, setOpenDeleteSSLDialog] = useState<boolean>(false);

  useResetLoadSuccess([tableKey]);

  useEffect(() => {
    if (application_id) {
      dispatch(
        getMultipleRequest({
          tab: CdnConfigurationTab.SSL,
          requests: [{ tableKey, application_id, hasPagination: true }],
        }),
      );
    }
  }, [application_id]);

  const sslDataList = useConfigurationList(tableKey);

  const SSLActionHandlers = useActionHandlers<any>({
    tableKey,
    setData: setCurrentSSLData,
    setViewOpen: setOpenViewDialog,
    setDeleteOpen: setOpenDeleteSSLDialog,
    setEdit: setEditApplication,
    setEditOpen: setOpen,
    activeHandlers: [TableActionType.Create, TableActionType.Delete],
    disabledHandlers: sslDataList.length < 2 ? [TableActionHandlerType.handlePriority] : [],
  });

  const handleSSLDeleteClose = () => {
    if (currentSSLData?.id) {
      dispatch(deleteConfigurationData({ tableKey, application_id, content_id: currentSSLData?.id }));
    }
    setOpenDeleteSSLDialog(false);
  };

  return (
    <div className="flex flex-1">
      <SSLForm actionHandlers={SSLActionHandlers} />
      {open && <SSLModal data={currentSSLData} isEdit={editApplication} open={open} onClose={() => setOpen(false)} />}
      {openViewDialog && (
        <ViewModal data={currentSSLData} open={openViewDialog} onClose={() => setOpenViewDialog(false)} />
      )}
      <ConfirmationModal
        title={tSSLModal('sslDelete')}
        content={tModal('areYouSureDelete', { domain: currentSSLData?.name })}
        confirmButton={{ children: tCommon('button.confirm'), onClick: handleSSLDeleteClose }}
        open={openDeleteSSLDialog}
        type={StatusType.Delete}
        onClose={() => setOpenDeleteSSLDialog(false)}
      />
    </div>
  );
};
