import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

import { CdnConfigurationTab } from '@/enums';
import { useConfigurationSubmitting } from '@/store';
import { Button, PredefinedCards, Text } from '@components';

export const CachePurge = ({ openPurgeDialog }) => {
  const tableKey = CdnConfigurationTab.PredefinedCache;

  const { t: tButton } = useTranslation('configuration', { keyPrefix: 'buttons.cache' });
  const { t: tForm } = useTranslation('configuration', { keyPrefix: 'forms.cache' });

  const isSubmitting = useConfigurationSubmitting(tableKey);

  return (
    <PredefinedCards
      title={tForm('purgeCache')}
      description={
        <div className="flex flex-col gap-4">
          <Text $level={5} color="text-gray-400" className="font-light text-justify leading-4">
            {tForm('cacheFilesWillForceTheCDNToFetchTheLatestVersionsOfTheseFilesFromYourServer')}
          </Text>
          <div className="flex bg-blue-50 fill-blue-400 rounded-md p-3 text-blue-500">
            <InformationCircleIcon className="mr-3 flex min-w-5 h-5" />
            <article className="text-xs bg-blue-50 fill-blue-400 rounded-md  text-blue-500">
              {tForm(
                'clearingTheCacheMayTemporarilyDegradeYourWebsitesPerformanceAndProlongTheTimeItTakesToLoadFilesFromYourSourceServer',
              )}
            </article>
          </div>
        </div>
      }
    >
      <Button className="w-full bg-indigo-500 text-white" loading={isSubmitting} onClick={openPurgeDialog}>
        {tButton('clearAll')}
      </Button>
    </PredefinedCards>
  );
};
