import { Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { breakpoints, Button, FormField, PredefinedCards, Skeleton, ToggleButton, useMediaQuery } from '@components';
import {
  cacheLevelType,
  cacheTTLOptionType,
  CdnConfigurationTab,
  columnDirection,
  InputMod,
  InputType,
  StatusType,
} from '@enums';
import { useAppDispatch } from '@hooks';
import {
  addSnack,
  resetConfigurationForm,
  submitConfigurationForm,
  useConfigurationError,
  useConfigurationList,
  useConfigurationLoading,
  useConfigurationSaveSuccess,
  useConfigurationSubmitting,
} from '@store';
import { Dictionaries, getOptionListFromCatalog } from '@utils';

import { CacheFileExtensionModal } from '../subComponents/modals/CacheFileExtensionModal';

interface PredefinedCacheViewProps {
  onResetClose: () => void;
  onSaveClose: () => void;
  onFormikInstance: (formik) => void;
  formikRef: any;
}

export const PredefinedCacheView = ({ onResetClose, formikRef, onFormikInstance }: PredefinedCacheViewProps) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const tableKey = CdnConfigurationTab.PredefinedCache;

  const { t: tToasts } = useTranslation('configuration', { keyPrefix: 'toasts.predefinedCache' });
  const { t: tForm } = useTranslation('configuration', { keyPrefix: 'forms.cache' });
  const { t: tDescription } = useTranslation('description', { keyPrefix: 'configuration.cache' });
  const { t: tButton } = useTranslation('configuration', { keyPrefix: 'buttons.cache' });

  const isDataLoading = useConfigurationLoading(tableKey);
  const predefinedCacheList = useConfigurationList(tableKey);
  const submitError = useConfigurationError(tableKey);
  const isSubmitting = useConfigurationSubmitting(tableKey);
  const saveSuccess = useConfigurationSaveSuccess(tableKey);
  const isAboveSm = useMediaQuery(`(min-width: ${breakpoints.sm}px)`);

  const [openConfigureExtensionModal, setOpenConfigureExtensionModal] = useState<boolean>(false);
  const [showCurrentToast, setShowCurrentToast] = useState<boolean>(false);
  const [currentDataKey, setCurrentDataKey] = useState<string | null>(null);

  const predefinedCacheInitialValues = {
    status: true,
    cache_mode: cacheLevelType.Standard,
    server_cache_ttl: cacheTTLOptionType.Follow_Origin_Cache_Header,
    browser_cache_ttl: cacheTTLOptionType.Follow_Origin_Cache_Header,
    overwrite_cache_header: '0',
    extensions: [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'tiff',
      'webp',
      'ico',
      'svg',
      'js',
      'css',
      'woff',
      'woff2',
      'ttf',
      'tif',
      'tiff',
    ],
  };

  const initialValues = {
    status: predefinedCacheList?.status ?? predefinedCacheInitialValues.status,
    cache_mode: predefinedCacheList?.cache_mode ?? predefinedCacheInitialValues.cache_mode,
    server_cache_ttl: predefinedCacheList?.server_cache_ttl ?? predefinedCacheInitialValues.server_cache_ttl,
    browser_cache_ttl: predefinedCacheList?.browser_cache_ttl ?? predefinedCacheInitialValues.browser_cache_ttl,
    overwrite_cache_header:
      predefinedCacheList?.overwrite_cache_header ?? predefinedCacheInitialValues.overwrite_cache_header,
    extensions:
      predefinedCacheList?.extensions?.map((extension, index) => `${index !== 0 ? '\n' : ''}${extension}`) ??
      predefinedCacheInitialValues.extensions,
  };

  const handleReset = () => {
    const { ...updatedValues } = predefinedCacheInitialValues;

    dispatch(
      submitConfigurationForm({
        formData: { application_id, ...updatedValues },
        tableKey,
        returnResult: true,
      }),
    );
    setCurrentDataKey(null);
    onResetClose();
  };

  const handleSubmit = (values) => {
    const { ...updatedValues } = values;
    dispatch(
      submitConfigurationForm({
        formData: {
          application_id,
          ...updatedValues,
        },
        tableKey,
      }),
    );
  };

  const handleConfigure = () => {
    setOpenConfigureExtensionModal(!openConfigureExtensionModal);
  };

  const onOptionClick = (event, formik, name) => {
    const selectOptions = ['cache_mode', 'server_cache_ttl', 'browser_cache_ttl'];
    const currentOption = selectOptions.includes(name) ? event.target.value : event;
    formik.setFieldValue(name, currentOption);
    dispatch(
      submitConfigurationForm({
        formData: { application_id, [name]: currentOption },
        disabledToast: true,
        tableKey,
        returnResult: true,
      }),
    );
    setShowCurrentToast(true);
    setCurrentDataKey(name);
  };

  useEffect(() => {
    if (!submitError && !isSubmitting && saveSuccess) {
      if (showCurrentToast && currentDataKey) {
        dispatch(addSnack({ type: StatusType.Success, message: tToasts(`${currentDataKey}Configured`) }));
        setShowCurrentToast(false);
        setCurrentDataKey(null);
      } else {
        dispatch(resetConfigurationForm(tableKey));
      }
    }
  }, [isSubmitting, saveSuccess, submitError]);

  if (isDataLoading) {
    return (
      <div className="space-y-4">
        {[...Array(7)].map((_, index) => (
          <Skeleton key={index} className="flex w-full rounded-2xl h-32" />
        ))}
      </div>
    );
  }

  return (
    <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} onReset={handleReset}>
      {(formik) => {
        if (onFormikInstance) onFormikInstance(formik);

        const handleModalResetAndClose = (modalSetter, openModal, fieldName) => {
          dispatch(resetConfigurationForm(tableKey));
          formik.setFieldValue(fieldName, initialValues[fieldName]);
          formik.setFieldError(fieldName, undefined);
          modalSetter(!openModal);
        };

        const handleConfigurationExtensionModal = () => {
          handleModalResetAndClose(setOpenConfigureExtensionModal, openConfigureExtensionModal, 'extensions');
        };

        return (
          <Fragment>
            <PredefinedCards
              besideTitle={!isAboveSm}
              title={tForm('enabled')}
              description={
                <div className="space-y-5">
                  <article className="text-xs font-light text-justify text-gray-400">
                    {tForm('togglePredefinedCacheRules')}
                  </article>
                </div>
              }
            >
              <FormField
                withoutLabel
                label="Enabled"
                name="status"
                fullWidth
                smallFont
                loading={isSubmitting}
                direction={columnDirection.Row}
                inputType={InputType.Custom}
              >
                <ToggleButton name="status" />
              </FormField>
            </PredefinedCards>
            <PredefinedCards
              title={tForm('cacheLevel')}
              description={tForm('determineHowMuchOfYourWebsitesStaticContentYouWant7BullToCache')}
            >
              <FormField
                label={tForm('cacheLevel')}
                name="cache_mode"
                inputType={InputType.Select}
                inputMod={InputMod.Filled}
                withoutLabel
                smallFont
                loading={isSubmitting}
                fullWidth
                disabled={!formik.values?.status || isSubmitting}
                placeholder={tForm('pleaseSelectYourCacheLevel')}
                options={getOptionListFromCatalog(Dictionaries.CachingLevelOption)}
              />
            </PredefinedCards>

            <PredefinedCards
              title={tForm('cacheOverwriteHeader')}
              description={tForm(
                'specifyCacheOverwriteHeaderToControlCachingBehaviorEnsuringUpdatedContentIsServedPromptlyAndCachedVersionsAreBypassedAsNeeded',
              )}
            >
              <FormField
                label={tForm('cacheOverwriteHeader')}
                name="overwrite_cache_header"
                inputType={InputType.Select}
                inputMod={InputMod.Filled}
                withoutLabel
                smallFont
                fullWidth
                loading={isSubmitting}
                disabled={!formik.values?.status || isSubmitting}
                placeholder={tForm('pleaseSelectYourOverwriteHeader')}
                options={getOptionListFromCatalog(Dictionaries.CacheOverwriteHedaer)}
              />
            </PredefinedCards>
            <PredefinedCards
              title={tForm('serverCacheTimeToLive')}
              description={tForm(
                'duringThisPeriodRequestsForFilesAreServedFromTheServerCacheReducingLoadOnTheOriginServerAndSpeedingUpResponses',
              )}
            >
              <FormField
                label={tForm('serverCacheTimeToLive')}
                name="server_cache_ttl"
                inputType={InputType.Select}
                inputMod={InputMod.Filled}
                withoutLabel
                smallFont
                fullWidth
                loading={isSubmitting}
                disabled={!formik.values?.status || isSubmitting}
                placeholder={tForm('pleaseSelectYourServerCache')}
                options={getOptionListFromCatalog(Dictionaries.CacheTTLOption)}
              />
            </PredefinedCards>
            <PredefinedCards
              title={tForm('browserCacheTimeToLive')}
              description={tForm('determineTheLengthOfTime7BullInstructsAVisitorsBrowserToCacheFiles')}
            >
              <FormField
                label={tForm('browserCacheTimeToLive')}
                name="browser_cache_ttl"
                inputType={InputType.Select}
                inputMod={InputMod.Filled}
                withoutLabel
                smallFont
                loading={isSubmitting}
                fullWidth
                disabled={!formik.values?.status || isSubmitting}
                placeholder={tForm('pleaseSelectYourBrowserCache')}
                options={getOptionListFromCatalog(Dictionaries.CacheTTLOption)}
              />
            </PredefinedCards>
            <PredefinedCards
              title={tForm('cacheFileExtension')}
              description={tForm('configureWhichFileTypes7BullShouldCacheByEnteringDifferentFileExtensions')}
            >
              <Button
                onClick={handleConfigure}
                fullWidth
                disabled={!formik.values?.status}
                loading={isSubmitting}
                className="flex"
              >
                {tButton('configure')}
              </Button>
            </PredefinedCards>
            {openConfigureExtensionModal && (
              <CacheFileExtensionModal
                open={openConfigureExtensionModal}
                title={tForm('configureCacheFileExtension')}
                placeholder={tForm('enterYourCacheRulesName')}
                description={tDescription('configureCacheFileExtension')}
                formik={formik}
                onSubmit={onOptionClick}
                onClose={handleConfigurationExtensionModal}
                origin={tableKey}
                name="extensions"
              />
            )}
          </Fragment>
        );
      }}
    </Formik>
  );
};
