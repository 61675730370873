import { Dictionaries } from '@/utils';
import {
  ACTION_CN,
  API_SPAM_LIMIT_OPTION_CN,
  BOOLEAN_VARIABLE_TYPE_CN,
  BROWSER_CACHE_CN,
  CACHE_OVERWRITE_HEADER_CN,
  CACHE_TTL_OPTION_CN,
  CACHING_LEVEL_CN,
  CDN_CN,
  CLIENT_COUNTRY_TYPE_CN,
  CLIENT_VARIATBLE_TYPE_CN,
  COMMON_CN,
  CONFIGURATION_CN,
  DASHBOARD_BLOCK_ACTION,
  DASHBOARD_CN,
  DASHBOARD_FILTER_CN,
  DEFAULT_OPTION_CN,
  DESCRIPTION_CN,
  DISPLAY_MODE_CN,
  ERRORS_CN,
  FORM_CN,
  HOME_CN,
  LANGUAGE_CN,
  LIMIT_REQUEST_RATE_CN,
  LIMIT_REQUEST_RATE_REJECTION_ACTION_CN,
  LIMIT_RESPONSE_DATA_RATE_UNIT_CN,
  MAINTENANCE_CN,
  OPERATOR_CN,
  ORIGIN_TYPE_CN,
  PAGERULES_REDIRECT_OPTION_CN,
  PROFILE_SETTINGS_CN,
  REDIRECT_CURRENT_CN,
  REDIRECT_HOST_CN,
  REDIRECT_STATUS_CODE_CN,
  REGIONS_CN,
  REQUEST_METHOD_VARIABLE_TYPE_CN,
  REQUEST_RATE_UNIT_CN,
  RESPONSE_COOKIE_VALUE_CN,
  RESPONSE_HEADER_VARIABLE_CN,
  ROLES_CN,
  SIGN_IN_CN,
  SSL_TYPE_CN,
  TIME_CN,
  UNDER_ATTACK_MODE_CN,
  VALIDATION_CN,
  VARIABLE_CN,
  WAF_ACTION_CN,
  WAF_LANGUAGE_CN,
  WAF_RULES_CN,
  WAF_SENSITIVITY_CN,
  WAF_TYPE_CN,
} from './cn';

import {
  ACTION_EN,
  API_SPAM_LIMIT_OPTION_EN,
  BOOLEAN_VARIABLE_TYPE_EN,
  BROWSER_CACHE_EN,
  CACHE_OVERWRITE_HEADER_EN,
  CACHE_TTL_OPTION_EN,
  CACHING_LEVEL_EN,
  CDN_EN,
  CLIENT_COUNTRY_TYPE_EN,
  CLIENT_VARIATBLE_TYPE_EN,
  COMMON_EN,
  CONFIGURATION_EN,
  DASHBOARD_BLOCK_ACTION_EN,
  DASHBOARD_EN,
  DASHBOARD_FILTER_EN,
  DEFAULT_OPTION_EN,
  DESCRIPTION_EN,
  DISPLAY_MODE_EN,
  ERRORS_EN,
  FORM_EN,
  HOME_EN,
  LANGUAGE_EN,
  LIMIT_REQUEST_RATE_EN,
  LIMIT_REQUEST_RATE_REJECTION_ACTION_EN,
  LIMIT_RESPONSE_DATA_RATE_UNIT_EN,
  MAINTENANCE_EN,
  OPERATOR_EN,
  ORIGIN_TYPE_EN,
  PAGERULES_REDIRECT_OPTION_EN,
  PROFILE_SETTINGS_EN,
  REDIRECT_CURRENT_EN,
  REDIRECT_HOST_EN,
  REDIRECT_STATUS_CODE_EN,
  REGIONS_EN,
  REQUEST_METHOD_VARIABLE_TYPE_EN,
  REQUEST_RATE_UNIT_EN,
  RESPONSE_COOKIE_VALUE_EN,
  RESPONSE_HEADER_VARIABLE_EN,
  ROLES_EN,
  SIGN_IN_EN,
  SSL_TYPE_EN,
  TIME_EN,
  UNDER_ATTACK_MODE_EN,
  VALIDATION_EN,
  VARIABLE_EN,
  WAF_ACTION_EN,
  WAF_LANGUAGE_EN,
  WAF_RULES_EN,
  WAF_SENSITIVITY_EN,
  WAF_TYPE_EN,
} from './en';
import { COMMON_SYSTEM, ERRORS_SYSTEM, HOME_SYSTEM, LANGUAGES_SYSTEM, SIGN_IN_SYSTEM } from './system';

export const resources = {
  en: {
    common: COMMON_EN,
    [Dictionaries.Languages]: LANGUAGE_EN,
    [Dictionaries.DisplayMode]: DISPLAY_MODE_EN,
    [Dictionaries.Roles]: ROLES_EN,
    [Dictionaries.Variable]: VARIABLE_EN,
    [Dictionaries.Operator]: OPERATOR_EN,
    [Dictionaries.OriginType]: ORIGIN_TYPE_EN,
    [Dictionaries.WAFAction]: WAF_ACTION_EN,
    [Dictionaries.SSLType]: SSL_TYPE_EN,
    [Dictionaries.Time]: TIME_EN,
    [Dictionaries.WAFRules]: WAF_RULES_EN,
    [Dictionaries.WAFSensitivity]: WAF_SENSITIVITY_EN,
    [Dictionaries.WAFLanguage]: WAF_LANGUAGE_EN,
    [Dictionaries.WAFType]: WAF_TYPE_EN,
    [Dictionaries.Regions]: REGIONS_EN,
    [Dictionaries.Action]: ACTION_EN,
    [Dictionaries.ClientVariableType]: CLIENT_VARIATBLE_TYPE_EN,
    [Dictionaries.BooleanVariableType]: BOOLEAN_VARIABLE_TYPE_EN,
    [Dictionaries.ClientCountryType]: CLIENT_COUNTRY_TYPE_EN,
    [Dictionaries.RequestMethodVariableType]: REQUEST_METHOD_VARIABLE_TYPE_EN,
    [Dictionaries.SchemeVariableType]: ORIGIN_TYPE_EN,
    [Dictionaries.BrowserCacheOption]: BROWSER_CACHE_EN,
    [Dictionaries.DefaultOption]: DEFAULT_OPTION_EN,
    [Dictionaries.CachingLevelOption]: CACHING_LEVEL_EN,
    [Dictionaries.LimitRequestRate]: LIMIT_REQUEST_RATE_EN,
    [Dictionaries.LimitRequestRateRejectionAction]: LIMIT_REQUEST_RATE_REJECTION_ACTION_EN,
    [Dictionaries.LimitResponseDataRateUnit]: LIMIT_RESPONSE_DATA_RATE_UNIT_EN,
    [Dictionaries.RedirectCurrent]: REDIRECT_CURRENT_EN,
    [Dictionaries.RedirectHost]: REDIRECT_HOST_EN,
    [Dictionaries.RedirectStatusCode]: REDIRECT_STATUS_CODE_EN,
    [Dictionaries.ResponseCookieValue]: RESPONSE_COOKIE_VALUE_EN,
    [Dictionaries.ResponseHeaderVariable]: RESPONSE_HEADER_VARIABLE_EN,
    [Dictionaries.ApiSpamLimitOption]: API_SPAM_LIMIT_OPTION_EN,
    [Dictionaries.CacheTTLOption]: CACHE_TTL_OPTION_EN,
    [Dictionaries.PageRulesRedirectOption]: PAGERULES_REDIRECT_OPTION_EN,
    [Dictionaries.UnderAttackMode]: UNDER_ATTACK_MODE_EN,
    [Dictionaries.DashboardFilter]: DASHBOARD_FILTER_EN,
    [Dictionaries.RequestRateUnit]: REQUEST_RATE_UNIT_EN,
    [Dictionaries.DashboardBlockAction]: DASHBOARD_BLOCK_ACTION_EN,
    [Dictionaries.CacheOverwriteHedaer]: CACHE_OVERWRITE_HEADER_EN,
    profileSettings: PROFILE_SETTINGS_EN,
    signIn: SIGN_IN_EN,
    homepage: HOME_EN,
    dashboard: DASHBOARD_EN,
    maintenance: MAINTENANCE_EN,
    errors: ERRORS_EN,
    form: FORM_EN,
    cdn: CDN_EN,
    configuration: CONFIGURATION_EN,
    validation: VALIDATION_EN,
    description: DESCRIPTION_EN,
  },
  cn: {
    common: COMMON_CN,
    [Dictionaries.Languages]: LANGUAGE_CN,
    [Dictionaries.DisplayMode]: DISPLAY_MODE_CN,
    [Dictionaries.Roles]: ROLES_CN,
    [Dictionaries.Variable]: VARIABLE_CN,
    [Dictionaries.Operator]: OPERATOR_CN,
    [Dictionaries.OriginType]: ORIGIN_TYPE_CN,
    [Dictionaries.WAFAction]: WAF_ACTION_CN,
    [Dictionaries.SSLType]: SSL_TYPE_CN,
    [Dictionaries.Time]: TIME_CN,
    [Dictionaries.WAFRules]: WAF_RULES_CN,
    [Dictionaries.WAFSensitivity]: WAF_SENSITIVITY_CN,
    [Dictionaries.WAFLanguage]: WAF_LANGUAGE_CN,
    [Dictionaries.WAFType]: WAF_TYPE_CN,
    [Dictionaries.Regions]: REGIONS_CN,
    [Dictionaries.Action]: ACTION_CN,
    [Dictionaries.ClientVariableType]: CLIENT_VARIATBLE_TYPE_CN,
    [Dictionaries.BooleanVariableType]: BOOLEAN_VARIABLE_TYPE_CN,
    [Dictionaries.ClientCountryType]: CLIENT_COUNTRY_TYPE_CN,
    [Dictionaries.RequestMethodVariableType]: REQUEST_METHOD_VARIABLE_TYPE_CN,
    [Dictionaries.SchemeVariableType]: ORIGIN_TYPE_CN,
    [Dictionaries.BrowserCacheOption]: BROWSER_CACHE_CN,
    [Dictionaries.DefaultOption]: DEFAULT_OPTION_CN,
    [Dictionaries.CachingLevelOption]: CACHING_LEVEL_CN,
    [Dictionaries.LimitRequestRate]: LIMIT_REQUEST_RATE_CN,
    [Dictionaries.LimitRequestRateRejectionAction]: LIMIT_REQUEST_RATE_REJECTION_ACTION_CN,
    [Dictionaries.LimitResponseDataRateUnit]: LIMIT_RESPONSE_DATA_RATE_UNIT_CN,
    [Dictionaries.RedirectCurrent]: REDIRECT_CURRENT_CN,
    [Dictionaries.RedirectHost]: REDIRECT_HOST_CN,
    [Dictionaries.RedirectStatusCode]: REDIRECT_STATUS_CODE_CN,
    [Dictionaries.ResponseCookieValue]: RESPONSE_COOKIE_VALUE_CN,
    [Dictionaries.ResponseHeaderVariable]: RESPONSE_HEADER_VARIABLE_CN,
    [Dictionaries.ApiSpamLimitOption]: API_SPAM_LIMIT_OPTION_CN,
    [Dictionaries.CacheTTLOption]: CACHE_TTL_OPTION_CN,
    [Dictionaries.PageRulesRedirectOption]: PAGERULES_REDIRECT_OPTION_CN,
    [Dictionaries.UnderAttackMode]: UNDER_ATTACK_MODE_CN,
    [Dictionaries.DashboardFilter]: DASHBOARD_FILTER_CN,
    [Dictionaries.RequestRateUnit]: REQUEST_RATE_UNIT_CN,
    [Dictionaries.DashboardBlockAction]: DASHBOARD_BLOCK_ACTION,
    [Dictionaries.CacheOverwriteHedaer]: CACHE_OVERWRITE_HEADER_CN,
    profileSettings: PROFILE_SETTINGS_CN,
    signIn: SIGN_IN_CN,
    homepage: HOME_CN,
    dashboard: DASHBOARD_CN,
    errors: ERRORS_CN,
    maintenance: MAINTENANCE_CN,
    form: FORM_CN,
    cdn: CDN_CN,
    configuration: CONFIGURATION_CN,
    validation: VALIDATION_CN,
    description: DESCRIPTION_CN,
  },
  system: {
    common: COMMON_SYSTEM,
    languages: LANGUAGES_SYSTEM,
    signIn: SIGN_IN_SYSTEM,
    home: HOME_SYSTEM,
    errors: ERRORS_SYSTEM,
  },
} as const;

export type TranslationResources = typeof resources;
