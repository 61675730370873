import i18n from 'i18next';

import { Column } from '@components';
import { CellType } from '@enums';
import { getColumnSizes } from '@utils';

export const getPageRulesTableColumns = (): Column[] => [
  { label: i18n.t('configuration:tables.id'), value: 'id', ...getColumnSizes(60) },
  { label: i18n.t('configuration:tables.rules'), value: 'name', ...getColumnSizes(110) },
  { label: i18n.t('configuration:tables.action'), value: 'actions', cellType: CellType.Action, ...getColumnSizes(80) },
];

export const getPageRulesPriorityTableColumns = (): Column[] => [
  { label: '', value: 'id', ...getColumnSizes(60) },
  { label: i18n.t('configuration:tables.rules'), value: 'name', ...getColumnSizes(110) },
];
