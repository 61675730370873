import i18n from 'i18next';
import { useMemo } from 'react';

import { Column } from '@components';
import { CellType } from '@enums';
import { useGetOriginOptions } from '@hooks';
import { getColumnSizes } from '@utils';

export const useCdnRouteListColumns = (): Column[] => {
  const { getOriginByValue } = useGetOriginOptions();

  return useMemo(
    () => [
      { label: i18n.t('configuration:tables.id'), value: 'id', ...getColumnSizes(70) },
      { label: i18n.t('configuration:tables.name'), value: 'name', ...getColumnSizes(100) },
      {
        label: i18n.t('configuration:tables.origin'),
        value: 'origin_id',
        format: (value: string) => getOriginByValue(value),
        ...getColumnSizes(130),
      },
      {
        label: i18n.t('configuration:tables.action'),
        value: 'actions',
        cellType: CellType.Action,
        ...getColumnSizes(90),
      },
    ],
    [getOriginByValue],
  );
};

export const getCdnRoutePriorityColumns = (): Column[] => [
  { label: i18n.t('configuration:tables.id'), value: 'original_id', ...getColumnSizes(100) },
  { label: i18n.t('configuration:tables.name'), value: 'name', ...getColumnSizes(150) },
];
