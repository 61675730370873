import { ERROR_CODES, ERROR_MESSAGE } from '../global/errors';

export const getAxiosResponseErrorCodeExceptionsList = async () => {
  return [ERROR_CODES.ERR_CANCELED, ERROR_CODES.ERR_NETWORK];
};

export const getAxiosResponseErrorUrlExceptionList = async () => {
  return [];
};

export const getAxiosResponseErrorMessageExceptionList = async () => {
  return [
    ERROR_MESSAGE.DEFAULT_CACHE_RULES_NOT_FOUND,
    ERROR_MESSAGE.DEFAULT_PAGE_RULES_NOT_FOUND,
    ERROR_MESSAGE.DEFAULT_WAF_NOT_FOUND,
    ERROR_MESSAGE.LOGIN_EXPIRED,
    ERROR_MESSAGE.LOGIN_VERIFACTION_FAILED,
    ERROR_MESSAGE.NON_REFERSH_TOKENS_ARE_ALLOWED,
    ERROR_MESSAGE.TOKEN_EXPIRED_OR_INVALID,
    ERROR_MESSAGE.APPLICATION_NOT_FOUND,
  ];
};
