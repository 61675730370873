import { TableCellsIcon } from '@heroicons/react/20/solid';
import { PlusIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { TableActionHandlerType, VariantType } from '@enums';
import { ActionHandlers } from '../DraggableTable';
import { Button } from './Button';

interface ConfigurationActionButtonProps<T> {
  actionHandlers: ActionHandlers<T>;
  setOpen?: () => void;
  createLabel?: string;
  loading?: boolean;
}

export const ConfigurationActionButton = <T,>({
  actionHandlers,
  setOpen,
  loading = false,
  createLabel,
}: ConfigurationActionButtonProps<T>) => {
  const { t: tcButton } = useTranslation('configuration', { keyPrefix: 'buttons' });
  if (!actionHandlers) return;
  const renderActionButton = (actionKey) => {
    const handler = actionHandlers[actionKey] as () => void;
    const commonProps = {
      loading: loading,
      className: 'ring-indigo-600 text-indigo-600',
      variant: VariantType.Outlined,
    };
    if (!handler) return null;
    const disabledButton = actionHandlers?.handleDisable?.() ?? ([] as string[]);
    switch (actionKey) {
      case TableActionHandlerType.handleCreate:
        return (
          <Button
            {...commonProps}
            variant={VariantType.Contained}
            onClick={handler}
            disabled={disabledButton.length > 0 && disabledButton.includes(actionKey)}
            iconWithoutM={!createLabel}
            icon={<PlusIcon className="w-4 h-4" />}
          >
            {createLabel && <article className="ellipsis-text">{createLabel}</article>}
          </Button>
        );
      case TableActionHandlerType.handlePriority:
        return (
          <Button
            {...commonProps}
            onClick={setOpen}
            disabled={disabledButton.length > 0 && disabledButton.includes(actionKey)}
            icon={<TableCellsIcon className="w-4 h-4" />}
          >
            {tcButton('sortingPriority')}
          </Button>
        );
      case TableActionHandlerType.handleVerification:
        return (
          <Button
            {...commonProps}
            onClick={() => handler()}
            disabled={disabledButton.length > 0 && disabledButton.includes(actionKey)}
            icon={<ShieldCheckIcon className="w-4 h-4" />}
          >
            {tcButton('verifyAll')}
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex items-center gap-5">
      {Object.keys(actionHandlers).map((actionKey, index) => {
        return <Fragment key={index}>{renderActionButton(actionKey as TableActionHandlerType)}</Fragment>;
      })}
    </div>
  );
};
