import i18n from 'i18next';

import { Chip, Column, InformationModal } from '@components';
import { CellType, ConditionOperatorType, ConditionVariableType } from '@enums';
import { Dictionaries, getCodeValueFromCatalog, getColumnSizes } from '@utils';
import { useState } from 'react';

export const getCdnWAFWhitelistColumns = (): Column[] => [
  { label: i18n.t('configuration:tables.id'), value: 'id', multiLines: true, ...getColumnSizes(70) },
  {
    label: i18n.t('configuration:tables.whiteListName'),
    value: 'name',
    multiLines: true,
    ...getColumnSizes(130),
  },
  {
    label: i18n.t('configuration:tables.conditions'),
    value: 'rules',
    cellType: CellType.Custom,
    multiLines: true,
    ...getColumnSizes(160),
    format: (data) => {
      return (
        <div className="space-y-1 min-h-full">
          {data.map((waf, index) => {
            return (
              <div className="font-light space-y-1" key={index}>
                {waf.operator === ConditionOperatorType.stringEqual && waf.field === ConditionVariableType.URI ? (
                  <article className="flex flex-grow space-x-1 font-medium text-sm ">
                    <article>{getCodeValueFromCatalog(Dictionaries.Variable, waf.field)}</article>
                    <article className="text-red-500">
                      {getCodeValueFromCatalog(Dictionaries.Operator, waf.operator)}
                    </article>
                  </article>
                ) : null}
                <ul className="list-disc list-inside">
                  {waf?.value.map((v, index) => {
                    return (
                      <li key={index} className="text-xs truncate">
                        {`"${v.content}"`}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      );
    },
  },
  {
    label: i18n.t('configuration:tables.rules'),
    value: 'skippedWafRules',
    multiLines: true,
    ...getColumnSizes(180),
    cellType: CellType.Custom,
    format: (data) => {
      return (
        <div className="text-sm space-y-1">
          <div className="space-y-1">
            {data?.map((rule, ruleId) => {
              const [openDialog, setOpenDialog] = useState<boolean>(false);
              const handleDialog = () => {
                setOpenDialog(!openDialog);
              };
              return (
                <div key={ruleId} className="">
                  <Chip
                    smallFont
                    onChipClick={handleDialog}
                    label={getCodeValueFromCatalog(Dictionaries.WAFRules, rule.value)}
                  />
                  <InformationModal
                    title={getCodeValueFromCatalog(Dictionaries.WAFRules, rule.value)}
                    topTitle
                    content={
                      <article className="text-xs leading-5 text-justify pt-1">
                        {i18n.t(`description:configuration.waf.custom.${rule.value}_description`)}
                      </article>
                    }
                    onClose={handleDialog}
                    open={openDialog}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    },
  },
  {
    label: i18n.t('configuration:tables.action'),
    value: 'actions',
    cellType: CellType.Action,
    multiLines: true,
    ...getColumnSizes(100),
  },
];
