import { Formik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { routeFormSchema } from '@/constants';
import { useGetConfigurationOptions } from '@/hooks/useGetConfigurationOptions';
import { RouteConfigurationForm } from '@/types/forms/OriginForm';
import { convertOptionList } from '@/utils';
import { FormField, FormModal } from '@components';
import {
  CdnConfigurationTab,
  ConditionOperatorType,
  ConditionVariableType,
  InputType,
  columnDirection,
  columnSpace,
} from '@enums';
import { useAppDispatch } from '@hooks';
import {
  getConfigurationList,
  resetConfigurationForm,
  submitConfigurationForm,
  useCdnSummary,
  useConfigurationError,
  useConfigurationSaveSuccess,
  useConfigurationSubmitting,
} from '@store';
import { useParams } from 'react-router-dom';

interface RouteFormProps {
  data?: RouteConfigurationForm;
  open: boolean;
  isEdit: boolean;
  onClose: () => void;
}

export const RouteModal = ({ data, open, isEdit, onClose }: RouteFormProps) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const tableKey = CdnConfigurationTab.Route;

  const { t: tForm } = useTranslation('configuration', { keyPrefix: 'forms.route' });
  const { t: tModal } = useTranslation('configuration', { keyPrefix: 'modals.route' });
  const { t: tButton } = useTranslation('configuration', { keyPrefix: 'buttons.route' });
  const { t: tTables } = useTranslation('common', { keyPrefix: 'tables' });

  const submitError = useConfigurationError(tableKey);
  const isSubmitting = useConfigurationSubmitting(tableKey);
  const saveSuccess = useConfigurationSaveSuccess(tableKey);
  const CdnSummaryConfiguration = useCdnSummary();

  const { options: originOptions } = useGetConfigurationOptions({
    tableKey: CdnConfigurationTab.Origin,
    dataKey: 'id',
  });

  const { options: domainOptions } = useMemo(
    () =>
      convertOptionList({
        originalOptions: CdnSummaryConfiguration?.domain,
        output: { value: 'domain', label: 'domain' },
      }),
    [CdnSummaryConfiguration?.domain],
  );

  const handleSubmit = (values: RouteConfigurationForm) => {
    isEdit
      ? dispatch(
          submitConfigurationForm({
            formData: { ...values },
            tableKey,
            content_id: data?.id,
          }),
        )
      : dispatch(
          submitConfigurationForm({
            formData: { application_id, ...values },
            tableKey,
          }),
        );
  };

  useEffect(() => {
    if (!submitError && !isSubmitting && saveSuccess) {
      onClose();
      dispatch(resetConfigurationForm(tableKey));
      application_id && dispatch(getConfigurationList({ tableKey, application_id, hasPagination: true }));
    }
  }, [application_id, dispatch, isEdit, isSubmitting, onClose, saveSuccess, submitError, tableKey]);

  const initialValues: RouteConfigurationForm = {
    name: data?.name ?? '',
    origin: data?.origin_id ?? '',
    conditions: data?.conditions ?? [],
    connect_timeout: data?.connect_timeout ?? '5',
    send_timeout: data?.send_timeout ?? '5',
    read_timeout: data?.read_timeout ?? '5',
  };

  if (!open) return;
  console.log(domainOptions);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={routeFormSchema({ optionList: domainOptions })}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <FormModal
            title={tModal(isEdit ? 'editApplicationRoute' : 'addApplicationRoute')}
            description={tModal(isEdit ? 'editRouteDescription' : 'addNewRouteDescription')}
            onClose={onClose}
            submitError={submitError}
            isSubmitting={isSubmitting}
            size="lg"
            open={open}
            confirmButton={{ children: tButton(isEdit ? 'editRoute' : 'addRoute') }}
          >
            <FormField
              label={tForm('rulesName')}
              name="name"
              direction={columnDirection.Row}
              smallFont
              required
              placeholder={tForm('enterYourRulesName')}
            />
            <FormField
              inputType={InputType.Table}
              label={tForm('conditions')}
              columnPercentage={columnSpace.TwoOverFive}
              format={tForm}
              withoutLabel
              direction={columnDirection.Column}
              name="conditions"
              fullWidth
              smallFont
              placeholder={''}
              emptyData={{
                main: {
                  field: ConditionVariableType.URI,
                  argument: '',
                  operator: ConditionOperatorType.stringEqual,
                  values: [{ value: '' }],
                },
              }}
            />
            <FormField
              label={tForm('originServer')}
              name="origin"
              inputType={InputType.Select}
              placeholder={tForm('selectYourOriginServer')}
              options={originOptions}
              direction={columnDirection.Row}
              smallFont
              className="text-sm"
              fullWidth
              required
            />
            <FormField
              label={tForm('connectionTimeOut')}
              name="connect_timeout"
              direction={columnDirection.Row}
              smallFont
              min={1}
              max={60}
              fullWidth
              placeholder={'0'}
              adornment={
                <article className="text-us flex items-center justify-end text-center w-full text-slate-500">
                  {tTables('seconds')}
                </article>
              }
              type="number"
            />
            <FormField
              label={tForm('sendTimeOut')}
              name="send_timeout"
              smallFont
              fullWidth
              placeholder={'0'}
              min={1}
              max={60}
              direction={columnDirection.Row}
              adornment={
                <article className="text-us flex items-center justify-end text-center w-full text-slate-500">
                  {tTables('seconds')}
                </article>
              }
              type="number"
            />
            <FormField
              label={tForm('readTimeOut')}
              direction={columnDirection.Row}
              smallFont
              fullWidth
              min={1}
              max={1800}
              name="read_timeout"
              placeholder={'0'}
              adornment={
                <article className="text-us flex items-center justify-end text-center w-full text-slate-500">
                  {tTables('seconds')}
                </article>
              }
              type="number"
            />
          </FormModal>
        );
      }}
    </Formik>
  );
};
