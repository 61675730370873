import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { workSpaceId } from '@/storage';
import {
  getWorkSpaceListError,
  getWorkSpaceListSuccess,
  getWorkspaceList,
  updateWorkSpaceId,
  updateWorkSpaceIdError,
  updateWorkSpaceIdSuccess,
} from '@store';
import { API } from '@utils';

export function* getWorkSpaceListSaga() {
  try {
    const { data: responseData } = yield call(API.get, '/workspace');
    const { workspace_list, default_workspace_id } = responseData;
    const current_workspace_id = workSpaceId.getItem();

    if (!current_workspace_id || current_workspace_id !== default_workspace_id) {
      yield call(workSpaceId.setItem, default_workspace_id);
    }
    yield put(getWorkSpaceListSuccess({ workspace_list }));
  } catch (error) {
    yield put(getWorkSpaceListError(error));
  }
}

export function* updateWorkspaceIdSaga({ payload }: PayloadAction<any>) {
  try {
    const { workspace_id } = payload;

    const currentWorkspaceId = workSpaceId.getItem();

    if (workspace_id !== currentWorkspaceId) {
      const { data: responseData } = yield call(API.get, '/workspace');
      const { default_workspace_id, workspace_list } = responseData;
      yield call(workSpaceId.setItem, default_workspace_id);
      yield put(updateWorkSpaceIdSuccess({ workspace_list }));
    }
  } catch (error) {
    yield put(updateWorkSpaceIdError(error));
  }
}

export function* workspaceSagaWatcher() {
  yield takeLatest(getWorkspaceList.type, getWorkSpaceListSaga);
  yield takeLatest(updateWorkSpaceId.type, updateWorkspaceIdSaga);
}
