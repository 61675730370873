import { useLocation } from 'react-router-dom';

import { Cog6ToothIcon, GlobeAmericasIcon, HomeIcon } from '@heroicons/react/24/outline';

import { ROUTES } from '@/constants';
import { useRoutePermissions } from '@/hooks';
import { useTranslation } from 'react-i18next';

export const useSidebarNavigation = () => {
  const { showRoute } = useRoutePermissions();
  const { pathname } = useLocation();
  const { t: tCommon } = useTranslation('common', { keyPrefix: 'navigator' });

  const navigationData = [
    {
      title: tCommon('navigation'),
      subPath: [
        {
          name: 'homepage',
          href: ROUTES.homepage,
          icon: HomeIcon,
          current: false,
          hidden: !showRoute.homepage,
        },
        {
          name: 'cdn',
          href: ROUTES.cdn,
          icon: GlobeAmericasIcon,
          current: false,
          hidden: !showRoute.cdn,
          notification: 5,
        },
      ],
    },
    {
      title: tCommon('account'),
      subPath: [
        {
          name: 'profileSettings',
          href: ROUTES.profileSettings,
          icon: Cog6ToothIcon,
          current: false,
          hidden: !showRoute.profileSettings,
        },
      ],
    },
  ];

  const getNavigationData = () => {
    return navigationData.map((category) => ({
      ...category,
      subPath: category.subPath.map((item) => ({
        ...item,
        current: pathname.startsWith(item.href),
      })),
    }));
  };

  return getNavigationData();
};
