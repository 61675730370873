import { FormType } from '@enums';

export const noop = <T>(data: T) => data;

interface Configuration {
  baseName: string;
  basePath: string;
  verifyPath?: string;
  successMessage: string;
  formatData: (data: any) => any;
  sortPath?: string;
  sortPostPath?: string;
  mockResponse?: any;
}

export const universalFormConfig = (key: FormType): Configuration => {
  const configurations: Record<string, Configuration> = {
    [FormType.Cdn]: {
      baseName: 'cdn',
      basePath: '/cdn/application',
      successMessage: 'form:toasts.cdn',
      formatData: noop,
    },
  };

  const config = configurations[key];
  return config;
};
