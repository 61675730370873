import { useCallback, useEffect, useState } from 'react';

import { breakpoints, useMediaQuery } from '@components';

interface DimensionData {
  height: number;
  yAxisWidth?: number;
  strokeWidth?: number;
}

interface DimensionDataProps<T> {
  dimensionsData: {
    mobile: DimensionData & T;
    tablet: DimensionData & T;
    desktop: DimensionData & T;
    large: DimensionData & T;
  };
}

const defaultDimensionData: DimensionData = {
  height: 400,
  yAxisWidth: 120,
  strokeWidth: 0.2,
};

export const useChartDimensions = <T extends object>({ dimensionsData }: DimensionDataProps<T>) => {
  const isBelowSm = useMediaQuery(`(max-width: ${breakpoints.sm}px)`);
  const isBelowLg = useMediaQuery(`(max-width: ${breakpoints.lg}px)`);
  const isBelow2xl = useMediaQuery(`(max-width: ${breakpoints['2xl']}px)`);

  const [chartHeight, setChartHeight] = useState(defaultDimensionData.height);
  const [yAxisWidth, setYAxisWidth] = useState(defaultDimensionData.yAxisWidth);
  const [strokeWidth, setStrokeWidth] = useState(defaultDimensionData.strokeWidth);

  const updateDimensions = useCallback(() => {
    const mobile = dimensionsData.mobile ?? defaultDimensionData;
    const tablet = dimensionsData.tablet ?? defaultDimensionData;
    const desktop = dimensionsData.desktop ?? defaultDimensionData;
    const large = dimensionsData.large ?? defaultDimensionData;

    if (isBelowSm) {
      setChartHeight(mobile.height);
      setYAxisWidth(mobile.yAxisWidth);
      setStrokeWidth(mobile.strokeWidth);
    } else if (isBelowLg) {
      setChartHeight(tablet.height);
      setYAxisWidth(tablet.yAxisWidth);
      setStrokeWidth(tablet.strokeWidth);
    } else if (isBelow2xl) {
      setChartHeight(desktop.height);
      setYAxisWidth(desktop.yAxisWidth);
      setStrokeWidth(desktop.strokeWidth);
    } else {
      setChartHeight(large.height);
      setYAxisWidth(large.yAxisWidth);
      setStrokeWidth(large.strokeWidth);
    }
  }, [isBelowSm, isBelowLg, isBelow2xl, dimensionsData]);

  useEffect(() => {
    updateDimensions();
  }, [updateDimensions]);

  return { chartHeight, yAxisWidth, strokeWidth };
};
