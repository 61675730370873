import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useActionHandlers } from '@/utils';
import { ConfirmationModal } from '@components';
import {
  CdnConfigurationTab,
  StatusType,
  TableActionHandlerType,
  TableActionType,
  originType,
  verificationType,
} from '@enums';
import { useAppDispatch, useResetLoadSuccess } from '@hooks';
import {
  deleteConfigurationData,
  getCdnSummary,
  getMultipleRequest,
  resetConfigurationForm,
  resetPagination,
  useCdnSummary,
  useCdnSummaryLoading,
  useConfigurationDeleteSuccess,
  useConfigurationLoading,
  useConfigurationPagination,
  useConfigurationVerifySuccess,
  verifyDomainName,
} from '@store';
import { useParams } from 'react-router-dom';
import { DomainForm, DomainModal } from './subComponents';

export interface DomainDataProps {
  id?: number;
  domain: string;
  origin?: any;
  type?: originType;
  name: string;
  status?: verificationType;
  connect_timeout?: number;
  send_timeout?: number;
  read_timeout?: number;
  verify_ssl_cert?: boolean;
}

export const Domain = () => {
  const dispatch = useAppDispatch();

  const { id: application_id } = useParams();
  const cdnSummaryConfiguration = useCdnSummary();
  const isCdnSummaryLoading = useCdnSummaryLoading();

  const tableKey = CdnConfigurationTab.Domain;

  const { t: tModal } = useTranslation('configuration', { keyPrefix: 'modals' });
  const { t: tRouteModal } = useTranslation('configuration', { keyPrefix: 'modals.domain' });
  const { t: tCommon } = useTranslation('common');

  const [editApplication, setEditApplication] = useState<boolean>(false);
  const [openDomainModal, setOpenDomainModal] = useState<boolean>(false);
  const [openVerificationModal, setOpenVerificationModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [currentVerify, setCurrentVerify] = useState<'Single' | 'All' | null>(null);
  const [currentData, setCurrentData] = useState<DomainDataProps>();

  const domainPagination = useConfigurationPagination(tableKey);
  const domainLoading = useConfigurationLoading(tableKey);
  const verifyDomainSuccess = useConfigurationVerifySuccess(tableKey);
  const deleteDomainSuccess = useConfigurationDeleteSuccess(tableKey);

  const cname = cdnSummaryConfiguration?.cname ?? '';

  useResetLoadSuccess([tableKey]);

  useEffect(() => {
    if (application_id) {
      dispatch(resetPagination(tableKey));
      dispatch(
        getMultipleRequest({
          tab: CdnConfigurationTab.Origin,
          requests: [{ tableKey, application_id, hasPagination: true }],
        }),
      );
    }
  }, [application_id]);

  useEffect(() => {
    if (application_id && ((currentVerify === 'All' && !domainLoading) || verifyDomainSuccess || deleteDomainSuccess)) {
      dispatch(getCdnSummary(application_id));
      dispatch(resetConfigurationForm(tableKey));
    }
    () => setCurrentVerify(null);
  }, [verifyDomainSuccess, currentVerify, domainLoading, deleteDomainSuccess]);

  const disableHandler = [
    ...(!cname ||
    !cdnSummaryConfiguration?.domain?.length ||
    cdnSummaryConfiguration?.domain.every(({ cname_verified }) => cname_verified)
      ? [TableActionHandlerType.handleVerification]
      : []),
    ...(domainPagination?.total >= cdnSummaryConfiguration?.allow_domain_count
      ? [TableActionHandlerType.handleCreate]
      : []),
  ];

  const domainActionHandlers = useActionHandlers<DomainDataProps>({
    setData: setCurrentData,
    setEditOpen: setOpenDomainModal,
    setVerifyOpen: setOpenVerificationModal,
    setDeleteOpen: setOpenDeleteModal,
    setEdit: setEditApplication,
    tableKey,
    activeHandlers: [
      TableActionType.Create,
      TableActionType.Edit,
      TableActionType.Verification,
      TableActionType.Delete,
      TableActionType.Check,
    ],
    disabledHandlers: disableHandler,
  });

  const handleVerificationClose = () => {
    setCurrentVerify(currentData ? 'Single' : 'All');
    dispatch(
      verifyDomainName({
        application_id,
        tableKey,
        content_id: currentData?.id,
      }),
    );
    setOpenVerificationModal(false);
  };

  const handleDeleteClose = () => {
    if (currentData?.id) {
      dispatch(deleteConfigurationData({ tableKey, content_id: currentData.id }));
    }
    setOpenDeleteModal(false);
  };

  return (
    <div className="flex flex-1">
      <DomainForm actionHandlers={domainActionHandlers} />
      <DomainModal
        data={currentData}
        isEdit={editApplication}
        open={openDomainModal}
        onClose={() => setOpenDomainModal(false)}
      />
      <ConfirmationModal
        title={tRouteModal('domainDelete')}
        content={tModal('areYouSureDelete', { domain: currentData?.domain })}
        confirmButton={{ children: tCommon('button.delete'), onClick: handleDeleteClose }}
        open={openDeleteModal}
        type={StatusType.Delete}
        onClose={() => setOpenDeleteModal(false)}
      />
      {cdnSummaryConfiguration && !isCdnSummaryLoading && openVerificationModal && (
        <ConfirmationModal
          title={tRouteModal('domainVerification')}
          content={`${tModal(currentData ? 'domain.areYouSureVerifyDomain' : 'domain.areYouSureVerifyAllDomain', { domain: currentData?.domain, cname: cdnSummaryConfiguration?.cname })} ? `}
          confirmButton={{ children: tCommon('button.confirm'), onClick: handleVerificationClose }}
          open={openVerificationModal}
          onClose={() => setOpenVerificationModal(false)}
        />
      )}
    </div>
  );
};
