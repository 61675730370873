import { ReactNode } from 'react';

export const capitalize = <T>(value: T | null | undefined): ReactNode => {
  if (typeof value === 'string') {
    return value.replace(/\b\w/g, (c) => c.toUpperCase());
  }
  if (value === null || value === undefined) {
    return '';
  }
  return value as ReactNode;
};

export const capitalizeFirstLetter = (text = ''): string => text.replace(/^\w/, (c) => c.toUpperCase());
