export enum EditTableRoute {
  Cdn,
  Configuration,
}

export enum TableActionType {
  Create = 'CREATE',
  View = 'VIEW',
  Edit = 'EDIT',
  Delete = 'DELETE',
  Verification = 'VERIFICATION',
  Priority = 'PRIORITY',
  Check = 'CHECK',
  Disable = 'DISABLE',
}

export enum TableActionHandlerType {
  handleEdit = 'handleEdit',
  handleDelete = 'handleDelete',
  handleCreate = 'handleCreate',
  handleView = 'handleView',
  handleVerification = 'handleVerification',
  handlePriority = 'handlePriority',
  handleCheck = 'handleCheck',
  handleRefresh = 'handleRefresh',
  handleDisable = 'handleDisable',
}
