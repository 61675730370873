import { Dialog } from '@headlessui/react';
import { MinusCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { FieldArray, Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getWAFConditionsColumns } from '@/constants';
import { Button, DialogModal, FormField } from '@components';
import {
  ConditionOperatorType,
  ConditionVariableType,
  InputType,
  StatusType,
  VariantType,
  columnDirection,
  columnSpace,
} from '@enums';
import { useAppDispatch } from '@hooks';
import { addSnack } from '@store';
import { Dictionaries, getOptionListFromCatalog } from '@utils';

interface ConfigurationModalProps {
  data?: any;
  open: boolean;
  isEdit: boolean;
  onClose: () => void;
}

export const ConfigurationModal = ({ data, open, isEdit, onClose }: ConfigurationModalProps) => {
  const { t: tForm } = useTranslation('form');
  const { t: tButton } = useTranslation('common', { keyPrefix: 'button' });

  const dispatch = useAppDispatch();
  const handleSubmit = (values: any) => {
    dispatch(
      addSnack({
        type: StatusType.Success,
        message: 'Successfully added cache rules',
      }),
    );
    onClose();
  };

  const initialValues: any = {
    name: data?.name ?? '',
    cacheKey: data?.cacheKey ?? [],
    browserCache: data?.browserCache ?? {},
    fileExtension: data?.fileExtension ?? '',
    headtoGet: data?.headtoGet ?? true,
    conditions: data?.conditions ?? [
      {
        field: ConditionVariableType.URI,
        argument: '',
        operator: ConditionOperatorType.stringEqual,
        values: [{ value: '' }],
      },
    ],
  };
  const WAFConditionTableColumns = useMemo(getWAFConditionsColumns, []);

  return (
    <DialogModal open={open} onClose={onClose}>
      <Dialog.Panel className="min-w-[43rem] border-gray-900/10 relative transform transition-all text-left bg-theme-surface-default  rounded-lg shadow-xl">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, setValues }) => {
            return (
              <Form className="bg-white rounded-lg  p-8">
                <div className="flex items-start justify-between pb-4">
                  <div className="flex flex-col space-y-3">
                    <article className="text-lg font-medium leading-6 text-gray-700">
                      {'Cache Rules Configuration'}
                    </article>
                    <article className="text-sm font-light leading-6 text-gray-500">
                      {'Specify which resources should be cached and for how long.'}
                    </article>
                  </div>
                  <Button
                    variant={VariantType.Icon}
                    hoverEffect
                    withoutPadding
                    rounded="rounded-full"
                    iconWithoutM
                    onClick={onClose}
                    icon={<XMarkIcon className="w-5 h-5" />}
                  />
                </div>

                <div className="divide-y-2  divide-dotted">
                  <FormField
                    label={`Custom cache rules name`}
                    name={'name'}
                    smallFont
                    className="py-3"
                    columnPercentage={columnSpace.Half}
                    centerItem
                    direction={columnDirection.ColumnRow}
                    description="Give your rule a descriptive name."
                    placeholder={'Enter your cache rules name'}
                    fullWidth
                  />
                  <FormField
                    inputType={InputType.Table}
                    label={tForm('conditions')}
                    className="py-3"
                    columnPercentage={columnSpace.Half}
                    columns={WAFConditionTableColumns}
                    name="conditions"
                    placeholder={''}
                    withoutLabel
                    fullWidth
                    emptyData={{
                      main: {
                        field: ConditionVariableType.URI,
                        argument: '',
                        operator: ConditionOperatorType.stringEqual,
                        values: [{ value: '' }],
                      },
                    }}
                  />
                  <FormField
                    label={`Cache File Extension`}
                    name={'fileExtension'}
                    smallFont
                    columnPercentage={columnSpace.Half}
                    centerItem
                    inputType={InputType.Area}
                    className="py-3"
                    direction={columnDirection.ColumnRow}
                    description="Specify the cache expiration time for resource content to effectively improve the hit rate of resources, enhance access performance, and reduce the need to go back to the origin server."
                    placeholder={'Enter your cache rules name'}
                    fullWidth
                  />
                  <FieldArray name="cacheKey">
                    {({ push, remove }) => (
                      <div className="py-5 flex flex-col">
                        <FormField
                          label={'Cache Key'}
                          direction={columnDirection.ColumnRow}
                          fullWidth
                          columnPercentage={columnSpace.Half}
                          name={'cacheKey'}
                          smallFont
                          description={
                            "Determine the length of time Unified instructs a visitor's browser to cache files."
                          }
                          inputType={InputType.Custom}
                        >
                          <div className="flex space-x-3 min-w-44 items-end justify-end">
                            {values.cacheKey.length > 0 ? (
                              <Button
                                onClick={() => setValues({ ...values, cacheKey: [] })}
                                withoutPadding
                                customizeColor
                                className="bg-red-500 text-white px-3.5 py-2.5 "
                              >
                                Remove this setting
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  push({ components: ConditionVariableType.URI });
                                  push({ components: ConditionVariableType.QueryString });
                                }}
                                withoutPadding
                                className="ring-theme-primary-main px-3.5 py-2.5"
                                variant={VariantType.Outlined}
                              >
                                Add settings
                              </Button>
                            )}
                          </div>
                        </FormField>
                        {values.cacheKey.length > 0 && (
                          <div className="pt-5 space-y-4">
                            {values.cacheKey.map((_item, index) => {
                              return (
                                <div key={index} className="flex flex-col gap-3">
                                  <div className="flex gap-3 items-start">
                                    <div className="flex flex-col space-y-4">
                                      <FormField
                                        label={
                                          <article className="text-gray-700 text-sm text-left min-w-28  font-normal leading-6">{`Component ${index + 1}`}</article>
                                        }
                                        name={`cacheKey.${index}.components`}
                                        inputType={InputType.Select}
                                        smallFont
                                        columnPercentage={columnSpace.Half}
                                        centerItem
                                        direction={columnDirection.Row}
                                        placeholder={'Select your cache key'}
                                        fullWidth
                                        options={getOptionListFromCatalog(Dictionaries.Variable)}
                                      />
                                      {values.cacheKey?.[index].components === ConditionVariableType.URIArgument && (
                                        <FormField
                                          label={`Component ${index + 1}`}
                                          name={`cacheKey.${index}.extraName`}
                                          inputType={InputType.Text}
                                          withoutLabel
                                          smallFont
                                          direction={columnDirection.Row}
                                          placeholder={'Enter your argument name'}
                                          fullWidth
                                          options={getOptionListFromCatalog(Dictionaries.Variable)}
                                        />
                                      )}
                                    </div>
                                    {values.cacheKey.length > 1 && (
                                      <Button
                                        icon={<MinusCircleIcon className={`w-6 h-6 text-red-500 mt-1.5`} />}
                                        iconWithoutM
                                        variant={VariantType.Transparent}
                                        withoutPadding
                                        className="text-black"
                                        onClick={() => remove(index)}
                                      />
                                    )}
                                  </div>
                                  {index === values.cacheKey.length - 1 && (
                                    <Button
                                      onClick={() => push({ components: ConditionVariableType.URI })}
                                      withoutPadding
                                      className="ring-theme-primary-main px-3.5 py-2.5 self-start mt-2"
                                      variant={VariantType.Outlined}
                                    >
                                      Add cache key settings
                                    </Button>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )}
                  </FieldArray>
                  <FieldArray name="browserCache">
                    {({ name }) => (
                      <div className="py-5">
                        <FormField
                          label={'Browser Cache'}
                          columnPercentage={columnSpace.Half}
                          smallFont
                          name={'browserCache'}
                          direction={columnDirection.ColumnRow}
                          description={
                            "Determine the length of time Unified instructs a visitor's browser to cache files."
                          }
                          inputType={InputType.Custom}
                        >
                          <div className="flex space-x-3 min-w-44 items-end justify-end">
                            {values.browserCache.enabled ? (
                              <Button
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    browserCache: {},
                                  })
                                }
                                withoutPadding
                                customizeColor
                                className="bg-red-500 text-white px-3.5 py-2.5 "
                              >
                                Remove this setting
                              </Button>
                            ) : (
                              <Button
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    browserCache: { enabled: true, time: '720' },
                                  })
                                }
                                withoutPadding
                                className="ring-theme-primary-main px-3.5 py-2.5"
                                variant={VariantType.Outlined}
                              >
                                Add settings
                              </Button>
                            )}
                          </div>
                        </FormField>
                        {values.browserCache.enabled && (
                          <FormField
                            label={`Expiration Time`}
                            name={`${name}.time`}
                            inputType={InputType.Select}
                            smallFont
                            className="pt-5"
                            columnPercentage={columnSpace.Half}
                            centerItem
                            direction={columnDirection.Row}
                            placeholder={'Select your expiration time'}
                            fullWidth
                            options={getOptionListFromCatalog(Dictionaries.BrowserCacheOption)}
                          />
                        )}
                      </div>
                    )}
                  </FieldArray>
                  <FormField
                    label={`Convert request method HEAD to GET`}
                    name="headtoGet"
                    inputType={InputType.Switch}
                    smallFont
                    columnPercentage={columnSpace.Half}
                    centerItem
                    className="pt-3"
                    description="Transforms requests using the HEAD method into GET requests, enabling the retrieval of resource headers without downloading the body."
                    direction={columnDirection.ColumnRow}
                    placeholder={tForm('selectYourOriginType')}
                    fullWidth
                  />
                </div>
                <div className="sm:grid sm:grid-cols-2 gap-8 pt-8">
                  <Button
                    variant={VariantType.Outlined}
                    className="rounded-md ring-theme-primary-main ring-opacity-30"
                    onClick={onClose}
                  >
                    <article className="ellipsis-text">{tButton('cancel')}</article>
                  </Button>
                  <Button type="submit">{tButton(isEdit ? 'save' : 'create')}</Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Dialog.Panel>
    </DialogModal>
  );
};
