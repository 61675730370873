import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useActionHandlers, validateValueEqual } from '@/utils';
import { ConfirmationModal } from '@components';
import { CdnConfigurationTab, StatusType, TableActionHandlerType, TableActionType } from '@enums';
import { useAppDispatch, useResetLoadSuccess } from '@hooks';
import {
  deleteConfigurationData,
  getMultipleRequest,
  useConfigurationList,
  useConfigurationLoadSuccess,
  useConfigurationPagination,
} from '@store';
import { OriginConfigurationForm, RouteConfigurationForm } from '@types';

import { OriginForm, OriginModal, RouteForm, RouteModal } from './subComponents';

export const Origins = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const routeTableKey = CdnConfigurationTab.Route;
  const originTableKey = CdnConfigurationTab.Origin;

  const { t: tModal } = useTranslation('configuration', { keyPrefix: 'modals' });
  const { t: tRouteModal } = useTranslation('configuration', { keyPrefix: 'modals.route' });
  const { t: tOriginModal } = useTranslation('configuration', { keyPrefix: 'modals.origin' });
  const { t: tCommon } = useTranslation('common');

  const [currentRouteData, setCurrentRouteData] = useState<RouteConfigurationForm>();
  const [currentOriginData, setCurrentOriginData] = useState<OriginConfigurationForm>();
  const [openDeleteRouteDialog, setOpenDeleteRouteDialog] = useState<boolean>(false);
  const [openDeleteOriginDialog, setOpenDeleteOriginDialog] = useState<boolean>(false);
  const [openRoute, setOpenRoute] = useState<boolean>(false);
  const [openOrigin, setOpenOrigin] = useState<boolean>(false);

  const [editApplication, setEditApplication] = useState<boolean>(false);

  useResetLoadSuccess([routeTableKey, originTableKey]);

  const originLoadSuccess = useConfigurationLoadSuccess(originTableKey);
  const routeLoadSuccess = useConfigurationLoadSuccess(routeTableKey);
  const routePagination = useConfigurationPagination(routeTableKey);

  const useCdnConfigurationData = (application_id, originLoadSuccess, routeLoadSuccess) => {
    useEffect(() => {
      if (application_id) {
        dispatch(
          getMultipleRequest({
            tab: CdnConfigurationTab.Origin,
            requests: [
              { tableKey: routeTableKey, application_id, hasPagination: true },
              { tableKey: originTableKey, application_id, hasPagination: true },
            ],
          }),
        );
      }
    }, [application_id]);
  };

  const originListData = useConfigurationList(originTableKey);

  useCdnConfigurationData(application_id, originLoadSuccess, routeLoadSuccess);
  const originActionHandlers = useActionHandlers<OriginConfigurationForm>({
    setData: setCurrentOriginData,
    setEditOpen: setOpenOrigin,
    setDeleteOpen: setOpenDeleteOriginDialog,
    setEdit: setEditApplication,
    tableKey: originTableKey,
    activeHandlers: [TableActionType.Create, TableActionType.Edit, TableActionType.Delete],
  });

  const getDisabledHandlers = (): TableActionHandlerType[] => {
    if (validateValueEqual(0, originListData.length)) {
      return [TableActionHandlerType.handleCreate, TableActionHandlerType.handlePriority];
    } else if (routePagination?.total < 2) {
      return [TableActionHandlerType.handlePriority];
    } else {
      return [];
    }
  };

  const routeActionHandlers = useActionHandlers<RouteConfigurationForm>({
    setData: setCurrentRouteData,
    setEditOpen: setOpenRoute,
    setDeleteOpen: setOpenDeleteRouteDialog,
    setEdit: setEditApplication,
    tableKey: routeTableKey,
    activeHandlers: [TableActionType.Create, TableActionType.Edit, TableActionType.Delete, TableActionType.Priority],
    disabledHandlers: getDisabledHandlers(),
  });

  const handleRouteDeleteClose = () => {
    currentRouteData?.id &&
      dispatch(
        deleteConfigurationData({
          tableKey: routeTableKey,
          application_id,
          content_id: currentRouteData.id,
        }),
      );
    setOpenDeleteRouteDialog(false);
  };

  const handleOriginDeleteClose = () => {
    currentOriginData?.id &&
      dispatch(
        deleteConfigurationData({
          tableKey: originTableKey,
          application_id,
          content_id: currentOriginData.id,
        }),
      );
    setOpenDeleteOriginDialog(false);
  };

  return (
    <div className="flex flex-1 flex-col w-full space-y-8">
      <RouteForm actionHandlers={routeActionHandlers} />
      <OriginForm actionHandlers={originActionHandlers} />
      {openRoute && (
        <RouteModal
          data={currentRouteData}
          isEdit={editApplication}
          open={openRoute}
          onClose={() => setOpenRoute(false)}
        />
      )}

      <OriginModal
        data={currentOriginData}
        isEdit={editApplication}
        open={openOrigin}
        onClose={() => setOpenOrigin(false)}
      />
      <ConfirmationModal
        title={tRouteModal('routeDelete')}
        content={tModal('areYouSureDelete', { domain: currentRouteData?.name })}
        confirmButton={{ children: tCommon('button.confirm'), onClick: handleRouteDeleteClose }}
        open={openDeleteRouteDialog}
        type={StatusType.Delete}
        onClose={() => setOpenDeleteRouteDialog(false)}
      />
      <ConfirmationModal
        title={tOriginModal('originDelete')}
        content={tModal('areYouSureDelete', { domain: currentOriginData?.name })}
        confirmButton={{ children: tCommon('button.confirm'), onClick: handleOriginDeleteClose }}
        open={openDeleteOriginDialog}
        type={StatusType.Delete}
        onClose={() => setOpenDeleteOriginDialog(false)}
      />
    </div>
  );
};
