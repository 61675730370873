import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { getWAFConditionsColumns } from '@/constants';
import { Button, DialogModal, FormField } from '@components';
import {
  ConditionOperatorType,
  ConditionVariableType,
  InputType,
  PageRulesAction,
  VariantType,
  columnDirection,
  redirect_currentCustom,
  redirect_hostCustom,
  redirect_statusCode,
} from '@enums';
import { useMemo } from 'react';

interface SSLModalProps {
  data?: any;
  open: boolean;
  isEdit: boolean;
  onClose: () => void;
}

export const PageRulesModal = ({ data, open, isEdit, onClose }: SSLModalProps) => {
  const { t: tButton } = useTranslation('common', { keyPrefix: 'button' });
  const { t: tForm } = useTranslation('form', { keyPrefix: 'pageRules' });
  const { t: tRouteForm } = useTranslation('form', { keyPrefix: 'addRoute' });
  const { t: tPageRulesForm } = useTranslation('configuration', { keyPrefix: 'forms.pageRules' });
  const { t: tCommonForm } = useTranslation('configuration', { keyPrefix: 'forms' });

  const handleSubmit = (values: any) => {
    // dispatch(
    //   addSnack({
    //     type: StatusType.Success,
    //     message: isEdit ? tToast('editSSLSuccessful') : tToast('addSSLSuccessful'),
    //   }),
    // );
    // onClose();
  };

  const initialValues: any = {
    name: data?.name ?? '',
    rules: data?.rules ?? [],
    actions: data?.actions ?? [
      {
        field: PageRulesAction.Redirect,
        uri: redirect_currentCustom.Current,
        uriArguments: redirect_currentCustom.Current,
        scheme: redirect_hostCustom.Current,
        host: redirect_currentCustom.Current,
        port: '',
        statusCode: redirect_statusCode.MovedTemporarily,
      },
    ],
  };

  const WAFConditionTableColumns = useMemo(getWAFConditionsColumns, []);
  return (
    <DialogModal open={open} onClose={onClose}>
      <Dialog.Panel className="min-w-[38rem] border-gray-900/10 relative transform transition-all text-left bg-theme-surface-default  rounded-lg shadow-xl">
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, touched, errors }) => {
            return (
              <Form className="bg-white rounded-lg p-8 space-y-8">
                <div className="flex items-center justify-between">
                  <article className="text-lg font-medium leading-6 text-gray-700">
                    {tForm(isEdit ? 'editTitle' : 'title')}
                  </article>
                  <Button
                    variant={VariantType.Icon}
                    hoverEffect
                    withoutPadding
                    rounded="rounded-full"
                    iconWithoutM
                    onClick={onClose}
                    icon={<XMarkIcon className="w-5 h-5" />}
                  />
                </div>
                <div className="grid grid-cols-1 gap-y-6">
                  <FormField
                    label={tRouteForm('content.name')}
                    name="name"
                    direction={columnDirection.Row}
                    smallFont
                    placeholder={tRouteForm('content.enterYourName')}
                    isCorrect={touched.name && !errors.name}
                  />
                  <FormField
                    inputType={InputType.Table}
                    label={tRouteForm('content.conditions')}
                    format={tCommonForm}
                    columns={WAFConditionTableColumns}
                    name="rules"
                    withoutLabel
                    placeholder={''}
                    emptyData={{
                      main: {
                        field: ConditionVariableType.URI,
                        argument: '',
                        operator: ConditionOperatorType.stringEqual,
                        values: [{ value: '' }],
                      },
                    }}
                  />
                  <FormField
                    inputType={InputType.Table}
                    label={'Action'}
                    columns={WAFConditionTableColumns}
                    name="actions"
                    withoutLabel
                    format={tPageRulesForm}
                    placeholder={''}
                    emptyData={{
                      main: {
                        field: PageRulesAction.Redirect,
                        uri: redirect_currentCustom.Current,
                        uriArguments: redirect_currentCustom.Current,
                        scheme: redirect_hostCustom.Current,
                        host: redirect_currentCustom.Current,
                        port: '',
                        statusCode: redirect_statusCode.MovedTemporarily,
                      },
                    }}
                  />
                </div>
                <div className="sm:grid sm:grid-cols-2 gap-8">
                  <Button
                    variant={VariantType.Outlined}
                    className="rounded-md ring-theme-primary-main ring-opacity-30"
                    onClick={onClose}
                  >
                    <article className="ellipsis-text">{tButton('cancel')}</article>
                  </Button>
                  <Button type="submit">{tButton(isEdit ? 'save' : 'create')}</Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Dialog.Panel>
    </DialogModal>
  );
};
