import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import { memo } from 'react';

import { Column, ColumnLayout, DraggableCell, DraggableItem } from '@components';

interface DraggableRowProps<T extends DraggableItem> {
  item: T;
  index: number;
  columns: Column[];
  columnLayouts: ColumnLayout[];
  isDraggable: boolean;
  actionHandlers?: any;
  tableKey: string;
}

export const DraggableRow = memo(
  <T extends DraggableItem>({
    item,
    index,
    columns,
    columnLayouts,
    isDraggable,
    actionHandlers,
    tableKey,
  }: DraggableRowProps<T>) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: item.id,
      disabled: !isDraggable,
    });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: isDragging ? 999 : 'auto',
    };

    return (
      <div
        {...attributes}
        {...listeners}
        ref={setNodeRef}
        style={style}
        className={classNames('flex flex-1', {
          'bg-white': index % 2 === 0,
          'bg-gray-50': index % 2 !== 0,
          'cursor-grab active:cursor-grabbing touch-none select-none': isDraggable,
          'cursor-text': !isDraggable,
        })}
      >
        {columns.map((column, colIndex) => (
          <DraggableCell
            key={colIndex}
            rowId={index}
            column={column}
            item={item}
            layout={columnLayouts[colIndex]}
            actionHandlers={actionHandlers}
            tableKey={tableKey}
          />
        ))}
      </div>
    );
  },
);
