import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { ConfigurationTab } from '@/components';
import { CdnConfigurationDashboardGraph, CdnConfigurationTab } from '@/enums';
import { useAppDispatch } from '@/hooks';
import { getMultipleRequest, useConfigurationFilterList } from '@/store';

import { DashboardFilter } from './dashboardFilter';
import { AttackLog } from './subPages/attackLog';
import { ErrorLog } from './subPages/errorLog';
import { LogPage } from './subPages/logPage';
import { TrafficPage } from './subPages/trafficPage';

export type CountryData = {
  country: {
    code: string;
    name: string;
    title: string;
    coordinates: [number, number];
  };
  value: number;
};

export const CdnDashboard = () => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const { id: application_id } = useParams();

  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.dashboard' });

  const tabs = [
    { name: tTabs('traffic'), key: 'traffic' },
    { name: tTabs('log'), key: 'log' },
    // { name: tTabs('attackLog'), key: 'attackLog' },
    { name: tTabs('errorLog'), key: 'errorLog' },
  ];

  const filterList = useConfigurationFilterList({ tableKey: CdnConfigurationTab.Dashboard });
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  const totalTrafficFilters = useMemo(
    () => filterList.filter((filter) => filter.origin === CdnConfigurationDashboardGraph.TotalTraffic),
    [filterList],
  );

  useEffect(() => {
    if (pathname && filterList && activeTab === 'traffic') {
      dispatch(
        getMultipleRequest({
          tab: CdnConfigurationTab.Dashboard,
          origin: CdnConfigurationDashboardGraph.TotalTraffic,
          requests,
        }),
      );
    }
  }, [pathname, totalTrafficFilters, activeTab]);

  const requests = [
    { graphTypeKey: CdnConfigurationDashboardGraph.Map, application_id },
    { graphTypeKey: CdnConfigurationDashboardGraph.RequestPerIp, application_id },
    { graphTypeKey: CdnConfigurationDashboardGraph.QPS, application_id },
    { graphTypeKey: CdnConfigurationDashboardGraph.TopTenURL, application_id },
    { graphTypeKey: CdnConfigurationDashboardGraph.AttackCount, application_id },
    { graphTypeKey: CdnConfigurationDashboardGraph.Cache, application_id },
    { graphTypeKey: CdnConfigurationDashboardGraph.TotalTraffic, application_id },
  ];

  const handleCurrentTab = (data: string) => {
    setActiveTab(data);
  };

  const tabContent = () => {
    switch (activeTab) {
      case 'traffic':
        return <TrafficPage />;
      case 'log':
        return <LogPage />;
      case 'attackLog':
        return <AttackLog />;
      case 'errorLog':
        return <ErrorLog />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-1 flex-col w-full gap-8">
      <div className="flex flex-row justify-between gap-4">
        <ConfigurationTab arrayTab={tabs} currentTab={activeTab} setCurrentTab={handleCurrentTab} />
        {activeTab === 'traffic' ? <DashboardFilter currentTab={activeTab} /> : null}
      </div>
      <div className="gap-8">{tabContent()}</div>
    </div>
  );
};
