import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getCustomWAFRulesColumns } from '@/constants';
import { CdnConfigurationTab } from '@/enums';
import { ConfigurationCard, DraggableTable } from '@components';
import { useConfigurationList, useConfigurationLoading, useConfigurationPagination } from '@store';

export const CustomWAFForm = ({ actionHandlers }) => {
  const tableKey = CdnConfigurationTab.CustomWAF;

  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.waf' });
  const { t: tButton } = useTranslation('configuration', { keyPrefix: 'buttons.waf' });

  const customWAFListData = useConfigurationList(tableKey);
  const isCustomWAFLoading = useConfigurationLoading(tableKey);
  const customWAFPagination = useConfigurationPagination(tableKey);

  const cdnOriginTableColumns = useMemo(getCustomWAFRulesColumns, []);

  return (
    <ConfigurationCard
      title={tTabs('customWAFRules')}
      ActionButtonProps={{
        actionHandlers: actionHandlers,
        loading: isCustomWAFLoading,
        createLabel: tButton('createNewCustomRules'),
      }}
    >
      <DraggableTable
        rows={customWAFListData}
        isDataLoading={isCustomWAFLoading}
        pagination={customWAFPagination}
        tableKey={tableKey}
        onChangePage={() => console.log('hi')}
        columns={cdnOriginTableColumns}
        isDraggable={false}
        actionHandlers={actionHandlers}
      />
    </ConfigurationCard>
  );
};
