import { Formik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormField, FormModal } from '@components';
import { originFormSchema } from '@constants';
import { CdnConfigurationTab, InputType, columnDirection, originType } from '@enums';
import { useAppDispatch } from '@hooks';
import {
  getConfigurationList,
  resetConfigurationForm,
  submitConfigurationForm,
  useConfigurationError,
  useConfigurationSaveSuccess,
  useConfigurationSubmitting,
} from '@store';
import { OriginConfigurationForm } from '@types';
import { Dictionaries, getOptionListFromCatalog, validateValueEqual } from '@utils';

interface RouteFormProps {
  data?: OriginConfigurationForm;
  open: boolean;
  isEdit: boolean;
  onClose: () => void;
}

export const OriginModal = ({ data, open, isEdit, onClose }: RouteFormProps) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const tableKey = CdnConfigurationTab.Origin;

  const { t: tForm } = useTranslation('configuration', { keyPrefix: 'forms.origin' });
  const { t: tToast } = useTranslation('configuration', { keyPrefix: 'toasts.origin' });
  const { t: tFormat } = useTranslation('validation', { keyPrefix: 'format' });
  const { t: tModal } = useTranslation('configuration', { keyPrefix: 'modals.origin' });
  const { t: tButton } = useTranslation('configuration', { keyPrefix: 'buttons.origin' });

  const submitError = useConfigurationError(tableKey);
  const isSubmitting = useConfigurationSubmitting(tableKey);
  const saveSuccess = useConfigurationSaveSuccess(tableKey);

  const handleSubmit = (values: any) => {
    const updatedValues = {
      ...values,
      origins: values.origins
        .filter((entry) => entry.ip.trim() !== '')
        .map((entry) => ({
          ...entry,
          port: validateValueEqual(values.type, originType.HTTP) ? 80 : 443,
        })),
      verify_ssl_cert: values.type === originType.HTTPS ? values.verify_ssl_cert : true,
    };
    isEdit
      ? dispatch(
          submitConfigurationForm({
            formData: { ...updatedValues },
            tableKey,
            content_id: data?.id,
          }),
        )
      : dispatch(
          submitConfigurationForm({
            formData: { application_id, ...updatedValues },
            tableKey,
          }),
        );
  };

  useEffect(() => {
    if (!submitError && !isSubmitting && saveSuccess) {
      onClose();
      dispatch(resetConfigurationForm(tableKey));
      application_id && dispatch(getConfigurationList({ tableKey, application_id, hasPagination: true }));
    }
  }, [application_id, dispatch, isEdit, isSubmitting, onClose, saveSuccess, submitError, tToast, tableKey]);

  const initialValues: any = {
    name: data?.name ?? '',
    type: data?.type ?? originType.HTTPS,
    origins: data?.ip ?? (data?.origins && data.origins.length > 0) ? data?.origins : [{ ip: '' }],
    verify_ssl_cert: data?.verify_ssl_cert ?? false,
  };

  if (!open) return;

  return (
    <Formik initialValues={initialValues} validationSchema={originFormSchema} onSubmit={handleSubmit}>
      {({ values }) => {
        return (
          <FormModal
            title={tModal(isEdit ? 'editOrigin' : 'createNewOrigin')}
            description={tModal(isEdit ? 'editOriginDescription' : 'addNewOriginDescription')}
            onClose={onClose}
            submitError={submitError}
            size="lg"
            open={open}
            isSubmitting={isSubmitting}
            confirmButton={{ children: tButton(isEdit ? 'editOrigin' : 'addOrigin') }}
          >
            <FormField
              label={tForm('originName')}
              smallFont
              name="name"
              placeholder={tForm('enterYourOriginName')}
              direction={columnDirection.Row}
            />
            <FormField
              label={tForm('originType')}
              name="type"
              inputType={InputType.Select}
              smallFont
              placeholder={tForm('selectYourOriginType')}
              fullWidth
              direction={columnDirection.Row}
              options={getOptionListFromCatalog(Dictionaries.OriginType)}
            />
            <FormField
              label={tForm('ipList')}
              name="origins"
              nestedName="ip"
              emptyData={{ ip: '' }}
              inputType={InputType.MultipleInput}
              smallFont
              placeholder={tFormat('ip')}
              fullWidth
              direction={columnDirection.Row}
            />
            {values.type === originType.HTTPS && (
              <FormField
                label={tForm('verifySSL')}
                name="verify_ssl_cert"
                inputType={InputType.Switch}
                smallFont
                direction={columnDirection.Row}
              />
            )}
          </FormModal>
        );
      }}
    </Formik>
  );
};
