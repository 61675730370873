import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';

import { ConfigurationTab, Responsive, SettingsButtons } from '@components';
import { CdnConfigurationTab } from '@enums';
import { getMultipleRequest, useConfigurationSubmitting } from '@store';
import { validateValueEqual } from '@utils';
import AppConfigurationForm from './AppConfigurationForm';

const AppConfiguration = () => {
  const dispatch = useDispatch();
  const { id: application_id } = useParams();
  const tableKey = CdnConfigurationTab.AppConfiguration;

  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.appConfiguration' });
  const tabs = [{ name: tTabs('appConfiguration'), key: 'appConfiguration' }];

  useEffect(() => {
    if (application_id) {
      dispatch(
        getMultipleRequest({
          tab: tableKey,
          requests: [{ tableKey: tableKey, application_id }],
        }),
      );
    }
  }, [application_id]);

  const formikRef = useRef<FormikProps<any> | null>(null);
  const [activeTab, setActiveTab] = useState(tabs[0].key);
  const isSubmitting = useConfigurationSubmitting(tableKey);

  const handleCurrentTab = useCallback((currentTab: string) => {
    setActiveTab(currentTab);
  }, []);

  const handleSaveSettings = useCallback(() => {
    formikRef?.current?.handleSubmit();
  }, []);

  const handleFormikInstance = useCallback((formik: FormikProps<any> | null) => {
    formikRef.current = formik;
  }, []);

  return (
    <div className="flex flex-col flex-1 gap-6 sm:gap-8">
      <div className="flex flex-col sm:flex-row justify-between gap-4">
        <ConfigurationTab arrayTab={tabs} currentTab={activeTab} setCurrentTab={handleCurrentTab} />
        <Responsive showAbove="sm">
          <SettingsButtons
            activeTab={activeTab}
            tabName="appConfiguration"
            handleSaveModal={handleSaveSettings}
            formikRef={formikRef}
            isLoading={isSubmitting}
            noResetButton
          />
        </Responsive>
      </div>
      {validateValueEqual(activeTab, 'appConfiguration') && (
        <AppConfigurationForm onFormikInstance={handleFormikInstance} />
      )}
      <Responsive showBelow="sm">
        {validateValueEqual(activeTab, 'appConfiguration') && (
          <SettingsButtons
            activeTab={activeTab}
            tabName="appConfiguration"
            handleSaveModal={handleSaveSettings}
            formikRef={formikRef}
            isLoading={isSubmitting}
            noResetButton
          />
        )}
      </Responsive>
    </div>
  );
};

export default AppConfiguration;
