import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { initData } from '@/storage';
import { resources } from '@translations/config';
import { Dictionaries } from '@utils';

i18n.use(initReactI18next).init({
  lng: initData.getItem()?.language ?? 'en',
  ns: ['common', Dictionaries.Roles, Dictionaries.Languages],
  fallbackLng: 'en',
  nsSeparator: ':',
  interpolation: {
    escapeValue: false,
  },
  resources,
});
