export const validateValueEqual = <T>(data: T, valueToCheck: any): boolean => {
  if (data !== undefined && valueToCheck !== undefined) {
    if (Array.isArray(data)) {
      return data.includes(valueToCheck);
    } else if (typeof data === 'object' && data !== null) {
      // Handle enum specific logic
      const values = Object.values(data);
      // Filter out numeric values for enums to avoid reverse mapping issues
      const filteredValues = values.filter(
        (val) => typeof val === 'string' || (typeof val === 'number' && !values.includes(val?.toString())),
      );

      return filteredValues.includes(valueToCheck);
    } else {
      return data === valueToCheck;
    }
  }
  return false;
};
