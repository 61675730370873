import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, DialogModal, getColorMapping } from '@components';
import { StatusType, VariantType } from '@enums';
import { DialogPanel } from '@headlessui/react';
import clsx from 'clsx';

type ConfirmButtonProps = {
  children: ReactNode;
  onClick: () => void;
};

type DomainFormProps = {
  title: ReactNode;
  content: ReactNode;
  open: boolean;
  hideIcon?: boolean;
  onClose: () => void;
  confirmButton?: ConfirmButtonProps;
  type?: StatusType;
  topTitle?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
  className?: string;
};

export const ConfirmationModal = ({
  title,
  content,
  open,
  hideIcon = false,
  topTitle = false,
  confirmButton,
  onClose,
  type,
  size = 'sm',
  className,
}: DomainFormProps) => {
  const { t: tButton } = useTranslation('common', { keyPrefix: 'button' });
  const colorMapping = getColorMapping(type ?? StatusType.Info);

  if (!open) return;
  return (
    <DialogModal notForm open={open} onClose={onClose} size={size}>
      <DialogPanel className={clsx(className, 'flex flex-col gap-4')}>
        <div className={`${topTitle ? 'border-b' : ''} flex items-center justify-between px-6`}>
          <div>{topTitle && title}</div>
        </div>
        <div className="flex flex-col items-center text-center space-y-6">
          {!hideIcon && (
            <div className={`rotate-45 rounded p-1.5 ${colorMapping.background.light} ${colorMapping.text.main}`}>
              <div className="-rotate-45"> {colorMapping.icon({ className: 'w-8 h-8' })}</div>
            </div>
          )}
          <div className="space-y-3">
            {!topTitle && <article className="text-lg font-medium leading-6 text-gray-700">{title}</article>}
            {typeof content === 'string' ? (
              <article className="font-normal text-sm leading-6">{content}</article>
            ) : (
              content
            )}
          </div>
        </div>
        {confirmButton && (
          <div className="flex gap-6 mt-2">
            <Button fullWidth variant={VariantType.Outlined} onClick={onClose} className={`items-center`}>
              <article className="ellipsis-text">{tButton('cancel')}</article>
            </Button>
            <Button fullWidth className="bg-indigo-500 text-white" {...confirmButton} />
          </div>
        )}
      </DialogPanel>
    </DialogModal>
  );
};
