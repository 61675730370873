import { Button, ConfirmationModal } from '@/components';
import { StatusType, VariantType } from '@/enums';

export const ViewModal = ({ data, open, onClose }) => {
  return (
    <ConfirmationModal
      title={<article className="font-medium">{'About UnifiedCpanel'}</article>}
      content={
        <div className="space-y-5 pt-4">
          <div className="flex flex-col justify-start text-left space-y-1.5">
            <article className="text-base">{`${data?.label ?? ''} `}</article>
            {data?.label === 'Legal address' ? (
              <Button
                withoutPadding
                customFont
                variant={VariantType.Transparent}
                className="font-light text-sm text-left text-indigo-400 hover:cursor-pointer"
              >
                {data.content}
              </Button>
            ) : (
              <article className="font-light text-sm">{data.content}</article>
            )}
          </div>
        </div>
      }
      open={open}
      topTitle
      hideIcon
      type={StatusType.Info}
      onClose={onClose}
    />
  );
};
