import jwt_decode from 'jwt-decode';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

import { getAllStorageData } from '@/storage';
import { logOut, updateToken } from '@store';
import { useAppDispatch } from '../storeHooks';

const TOKEN_REFRESH_BUFFER = 60; // Refresh 1 minute before expiry
const IDLE_THRESHOLD = 5 * 60 * 1000; // 5 minutes of inactivity
const CHECK_INTERVAL = 60 * 1000; // Check every minute

interface DecodedToken {
  exp: number;
}

export const useTokenManager = () => {
  const dispatch = useAppDispatch();
  const isIdleRef = useRef(false);
  const checkIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const { current_access_token: token } = getAllStorageData();

  const setIsIdle = useCallback((value: boolean) => {
    isIdleRef.current = value;
  }, []);

  const checkTokenExpiration = useCallback(() => {
    if (!token) return;

    try {
      const decoded = jwt_decode<DecodedToken>(token);
      const currentTime = Math.floor(Date.now() / 1000);
      const timeUntilExpiry = decoded.exp - currentTime;

      if (timeUntilExpiry <= TOKEN_REFRESH_BUFFER) {
        dispatch(updateToken());
      }
    } catch (error) {
      console.error('Error decoding token:', error);
      dispatch(logOut());
    }
  }, [dispatch]);

  const resetIdleTimer = useCallback(
    debounce(() => {
      setIsIdle(false);
    }, 300),
    [setIsIdle],
  );

  useEffect(() => {
    const idleTimer = setTimeout(() => setIsIdle(true), IDLE_THRESHOLD);
    const activityHandler = () => resetIdleTimer();

    const activityEvents = ['mousemove', 'keypress', 'click', 'touchstart'];
    activityEvents.forEach((event) => {
      window.addEventListener(event, activityHandler, { passive: true });
    });

    return () => {
      clearTimeout(idleTimer);
      activityEvents.forEach((event) => {
        window.removeEventListener(event, activityHandler);
      });
      resetIdleTimer.cancel();
    };
  }, [resetIdleTimer, setIsIdle]);

  useEffect(() => {
    const checkAndSetInterval = () => {
      if (!isIdleRef.current) {
        checkTokenExpiration();
        if (checkIntervalRef.current) clearInterval(checkIntervalRef.current);
        checkIntervalRef.current = setInterval(checkTokenExpiration, CHECK_INTERVAL);
      } else if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
      }
    };

    checkAndSetInterval();

    return () => {
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
      }
    };
  }, [checkTokenExpiration]);

  const handleVisibilityChange = useCallback(() => {
    if (!document.hidden && !isIdleRef.current) {
      checkTokenExpiration();
    }
  }, [checkTokenExpiration]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return null;
};
