import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getCdnOriginListColumns } from '@/constants';
import { CdnConfigurationTab } from '@/enums';
import { useAppDispatch } from '@/hooks';
import { updatePageChanges, useConfigurationList, useConfigurationLoading, useConfigurationPagination } from '@/store';
import { ConfigurationCard, DraggableTable } from '@components';
import { useParams } from 'react-router-dom';

export const OriginForm = ({ actionHandlers }) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const tableKey = CdnConfigurationTab.Origin;

  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.origin' });
  const { t: tButtons } = useTranslation('configuration', { keyPrefix: 'buttons.origin' });
  const { t: tTables } = useTranslation('description', { keyPrefix: 'configuration.tables' });

  const isLoading = useConfigurationLoading(tableKey);
  const originListData = useConfigurationList(tableKey);
  const originPagination = useConfigurationPagination(tableKey);

  const cdnOriginTableColumns = useMemo(getCdnOriginListColumns, [tTables]);
  const handlePageChange = (page) => {
    dispatch(updatePageChanges({ tableKey, page, application_id }));
  };
  return (
    <ConfigurationCard
      title={tTabs('originList')}
      ActionButtonProps={{
        actionHandlers: actionHandlers,
        loading: isLoading,
        createLabel: tButtons('addNewOrigin'),
      }}
    >
      <DraggableTable
        rows={originListData}
        columns={cdnOriginTableColumns}
        isDataLoading={isLoading}
        tableKey={tableKey}
        isDraggable={false}
        actionHandlers={actionHandlers}
        pagination={originPagination}
        onChangePage={handlePageChange}
      />
    </ConfigurationCard>
  );
};
