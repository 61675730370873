import { AnimatePresence, motion } from 'framer-motion';
import { ComponentType, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@components';
import { CdnConfigurationDashboardGraph, CdnConfigurationTab, CdnDashboardFilter } from '@enums';
import { useConfigurationDashboardData, useConfigurationFilter } from '@store';
import { Dictionaries, formatNumberWithSeparator, getCodeValueFromCatalog, getGraphTypeValue } from '@utils';

import HorizontalBarChart from '@/components/Charts/HorizontalBarChart';
import NoRecordFound from '@/components/Charts/NoRecordFound';
import WorldMap from '@/components/worldMap/worldMap';
import {
  CardWrapper,
  CdnConfigurationDashboardCacheChart,
  CdnConfigurationDashboardQpsGraph,
  CdnConfigurationDashboardTotalTrafficGraph,
  GraphCardContent,
} from '../subComponents';
import CdnConfigurationDashboardAttackCountGraph from '../subComponents/cdnDashboardGraphs/attackCountGraph';

interface CardData {
  title: string;
  data: any[];
  component: ComponentType<any>;
}

export const TrafficPage: FC = () => {
  const dashboardData = useConfigurationDashboardData();

  const { t: tModals } = useTranslation('configuration', { keyPrefix: 'modals.dashboard' });
  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.dashboard' });

  const filter = useConfigurationFilter({
    tableKey: CdnConfigurationTab.Dashboard,
    name: CdnDashboardFilter.Time,
    origin: CdnConfigurationDashboardGraph.TotalTraffic,
  });

  const filterValue = useMemo(
    () => getCodeValueFromCatalog(Dictionaries.DashboardFilter, filter.value),
    [filter.value],
  );

  const { mapData, qpsData, cacheData, attackCountData, totalTrafficData, uriData, clientIpData } = useMemo(
    () => ({
      mapData: getGraphTypeValue(dashboardData, CdnConfigurationDashboardGraph.Map),
      uriData: getGraphTypeValue(dashboardData, CdnConfigurationDashboardGraph.TopTenURL),
      clientIpData: getGraphTypeValue(dashboardData, CdnConfigurationDashboardGraph.RequestPerIp),
      qpsData: getGraphTypeValue(dashboardData, CdnConfigurationDashboardGraph.QPS),
      cacheData: getGraphTypeValue(dashboardData, CdnConfigurationDashboardGraph.Cache),
      attackCountData: getGraphTypeValue(dashboardData, CdnConfigurationDashboardGraph.AttackCount),
      totalTrafficData: getGraphTypeValue(dashboardData, CdnConfigurationDashboardGraph.TotalTraffic),
    }),
    [dashboardData],
  );

  const totalTraffic = useMemo(
    () => formatNumberWithSeparator(mapData.reduce((sum, item) => sum + (item.count || 0), 0)),
    [mapData],
  );

  const transformData = useCallback(<T extends Record<string, any>>(data: T[], xKey: keyof T, yKey: keyof T) => {
    if (!data.length) {
      return [];
    }

    return data.map((entry) => ({
      x: String(entry[xKey] ?? ''),
      y: typeof entry[yKey] === 'number' && !isNaN(entry[yKey]) ? entry[yKey] : 0,
    }));
  }, []);

  const uriRequestData = useMemo(() => transformData(uriData, 'url', 'count'), [uriData]);
  const clientIpRequestData = useMemo(() => transformData(clientIpData, 'ip_address', 'count'), [clientIpData]);

  const cards: any[] = useMemo(
    () => [
      {
        title: tModals('requestCountPerSecond'),
        data: qpsData,
        component: CdnConfigurationDashboardQpsGraph,
      },
      {
        title: tTabs('cacheVsNocache'),
        data: cacheData,
        component: CdnConfigurationDashboardCacheChart,
      },
      {
        title: tTabs('totalTraffic'),
        data: totalTrafficData,
        component: CdnConfigurationDashboardTotalTrafficGraph,
      },
      {
        title: tTabs('attackCount'),
        data: attackCountData,
        component: CdnConfigurationDashboardAttackCountGraph,
      },
      {
        title: tTabs('top10ClientUrlsByRequestCounts'),
        data: uriRequestData,
        component: HorizontalBarChart,
      },
      {
        title: tTabs('top10ClientIpsByRequestCounts'),
        data: clientIpRequestData,
        component: HorizontalBarChart,
      },
    ],
    [qpsData, cacheData, totalTrafficData, attackCountData, uriRequestData, clientIpRequestData, tModals, tTabs],
  );

  const { cardsWithData, cardsWithoutData } = useMemo(() => {
    return cards.reduce<{ cardsWithData: CardData[]; cardsWithoutData: CardData[] }>(
      (acc, card) => {
        if (card.data.length > 0) {
          acc.cardsWithData.push(card);
        } else {
          acc.cardsWithoutData.push(card);
        }
        return acc;
      },
      { cardsWithData: [], cardsWithoutData: [] },
    );
  }, [cards]);

  const responsiveClass = useMemo(() => {
    const count = cardsWithoutData.length;
    if (count >= 3) return 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3';
    if (count === 2) return 'grid-cols-1 md:grid-cols-2';
    return 'grid-cols-1';
  }, [cardsWithoutData.length]);

  return (
    <div className="flex flex-col flex-1 gap-8">
      <CardWrapper delay={0.1} card>
        <div className="flex flex-col gap-3">
          <motion.div initial={{ x: -20 }} animate={{ x: 0 }} className="font-medium text-2xl text-theme-primary-main">
            <Text $level={3} $customizeColor>
              {tModals('trafficMap')}
            </Text>
          </motion.div>
          <motion.div
            initial={{ x: -20 }}
            animate={{ x: 0 }}
            transition={{ delay: 0.2 }}
            className="font-medium text-zinc-500 text-sm"
          >
            <Text $level={5} $customizeColor>
              {tTabs('theApplicationHassRecordedAToTalOfNumberAccossAllRegions', {
                formattedcount: totalTraffic,
              })}
            </Text>
          </motion.div>
        </div>
        <motion.div
          className="gap-6 flex flex-col"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <WorldMap data={mapData ?? []} />
        </motion.div>
      </CardWrapper>

      <AnimatePresence>
        {cardsWithData.map((card, index) => (
          <CardWrapper key={card.title} delay={0.2 * (index + 1)}>
            <GraphCardContent title={card.title} hasData={true}>
              <card.component
                label={{
                  filterValue: filterValue,
                  dataSets: card.data,
                }}
              />
            </GraphCardContent>
          </CardWrapper>
        ))}
      </AnimatePresence>
      {cardsWithoutData.length > 0 && (
        <div className={`grid gap-8 ${responsiveClass}`}>
          <AnimatePresence>
            {cardsWithoutData.map((card, index) => (
              <CardWrapper key={card.title} delay={0.2 * (index + cardsWithData.length + 1)} className="h-full">
                <GraphCardContent title={card.title} hasData={false}>
                  <NoRecordFound />
                </GraphCardContent>
              </CardWrapper>
            ))}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};
