import { Button, getColorMapping, Text } from '@components';
import { StatusType, VariantType } from '@enums';
import Tippy from '@tippyjs/react';
import { capitalize } from '@utils';

interface BadgesProps<T> {
  name?: T;
  type?: StatusType;
  hint?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export const Badges = <T,>({ name, onClick, disabled, className, icon, type, hint = true }: BadgesProps<T>) => {
  const colorMapping = getColorMapping(type ?? StatusType.Info);
  const isSuccess = type === StatusType.Success;
  return (
    <Tippy
      disabled={!hint || disabled}
      content={<article className="text-us">{(name && capitalize(name)) ?? ''}</article>}
      placement="top"
    >
      <Button
        variant={VariantType.Transparent}
        withoutPadding
        disabled={disabled}
        className={`${!isSuccess ? 'hover:cursor-pointer' : ' hover:cursor-default'} inline-flex items-center gap-1.5 rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${colorMapping.background.light}  ${colorMapping.utilities.ring} ${colorMapping.text.main} ${className}`}
        onClick={onClick}
      >
        {icon ?? (
          <svg className={`size-1.5 animate-pulse ${colorMapping.background.main}`} viewBox="0 0 6 6">
            <circle cx={3} cy={3} r={3} />
          </svg>
        )}
        <Text className="ellipsis-text" $customizeColor $level={5}>
          {capitalize(name)}
        </Text>
      </Button>
    </Tippy>
  );
};
