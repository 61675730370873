import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { Button, FormField } from '@components';
import { signInSchema } from '@constants';
import { useAppDispatch, useErrorFormInner } from '@hooks';
import { login, useLoginError, useLoginLoading } from '@store';

import { InputMod, VariantType } from '@enums';
import { UserLoginRequest } from '@types';

export const SignInForm = () => {
  const dispatch = useAppDispatch();

  const { t: tSignIn } = useTranslation('signIn');

  const isLoginLoading = useLoginLoading();

  const loginError = useLoginError();

  const handleSubmit = (values: UserLoginRequest) => {
    dispatch(login(values));
  };

  const signInInitialValues: UserLoginRequest = {
    username: '',
    password: '',
  };

  const LoginFormInner = () => {
    useErrorFormInner({ submitError: loginError, isSubmitting: isLoginLoading });
    return null;
  };

  const inputVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <Formik initialValues={signInInitialValues} validationSchema={signInSchema} onSubmit={handleSubmit}>
      {() => (
        <Form className="space-y-6">
          <LoginFormInner />
          <motion.div
            variants={inputVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3, delay: 0.1 }}
          >
            <FormField
              name="username"
              inputMod={InputMod.Filled}
              withoutLabel
              fullWidth
              customizeColor
              withoutRing
              autoComplete="email"
              textInputClassName="bg-theme-neutral-main text-white rounded-lg px-4 py-3 focus:ring-2 focus:ring-theme-primary transition-all duration-300"
              label={tSignIn('form.username')}
              placeholder={tSignIn('form.enterYourUsername')}
            />
          </motion.div>
          <motion.div
            variants={inputVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <FormField
              name="password"
              inputMod={InputMod.Filled}
              type="password"
              fullWidth
              withoutLabel
              customizeColor
              withoutRing
              textInputClassName="bg-theme-neutral-main text-white rounded-lg px-4 py-3 focus:ring-2 focus:ring-theme-primary transition-all duration-300"
              label={tSignIn('form.password')}
              placeholder={tSignIn('form.enterYourPassword')}
            />
          </motion.div>
          <motion.div
            className="flex justify-end"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.3 }}
          >
            <Button
              loading={isLoginLoading}
              variant={VariantType.Transparent}
              withoutPadding
              rounded="rounded-lg"
              type="submit"
              className="bg-transparent px-2.5 pt-3 justify-end text-theme-text-main"
            >
              {tSignIn('signInButton')}
            </Button>
          </motion.div>
        </Form>
      )}
    </Formik>
  );
};
