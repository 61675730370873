export const ROUTES = {
  login: '/',
  maintenance: '/maintenance',
  cdn: '/cdn',
  notfound: '/404',
  homepage: '/homepage',
  profileSettings: '/profileSettings',
  configuration: {
    main: 'configuration',
    dashboard: 'dashboard',
    domain: 'domain',
    origins: 'origins',
    security: 'security',
    ssl: 'ssl',
    waf: {
      main: 'waf',
      customRules: 'customRules',
    },
    pagerules: 'pagerules',
    cache: 'cache',
    appConfiguration: 'appConfiguration',
  },
};
