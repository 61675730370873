import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';

import { breakpoints, FormField, PredefinedCards, Skeleton, ToggleButton, useMediaQuery } from '@components';
import { CdnConfigurationTab, columnDirection, InputMod, InputType, StorageUnits } from '@enums';
import { useAppDispatch } from '@hooks';
import { submitConfigurationForm, useCdnSummaryLoading, useCdnSummary } from '@store';
import { convertEnumToObjectArray } from '@utils';
import { appConfigurationFormSchema } from '@constants';

const AppConfigurationForm = ({ onFormikInstance }) => {
  const dispatch = useAppDispatch();
  const tableKey = CdnConfigurationTab.AppConfiguration;

  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.appConfiguration' });
  const { t: tForm } = useTranslation('configuration', { keyPrefix: 'forms.appConfiguration' });
  const isAboveSm = useMediaQuery(`(min-width: ${breakpoints.sm}px)`);
  const { id: application_id } = useParams();

  const isOptionLoading = useCdnSummaryLoading();
  const {
    configuration,
    name,
    http_port,
    https_port,
    max_body_size,
    max_post_argument,
    max_url_argument,
    enable_websocket,
  } = useCdnSummary();

  const {
    http_port: httpPortOption,
    https_port: httpsPortOption,
    max_body_size: maxBodySizeMaxValue,
    max_post_argument: maxPostArgumentMaxValue,
    max_url_argument: maxUrlArgumentMaxValue,
  } = configuration ?? [];

  const initialAppConfigurationValues = {
    http_port: http_port?.length > 0 ? http_port : [80],
    https_port: https_port?.length > 0 ? https_port : [443],
    max_body_size: max_body_size ?? 100,
    max_post_argument: max_post_argument ?? 100,
    max_url_argument: max_url_argument ?? 100,
    max_body_size_unit: 'mb',
    enable_websocket: enable_websocket ?? true,
    name: name ?? '',
  };

  const handleSubmit = (values) => {
    const { max_body_size_unit, ...updatedValues } = values;
    dispatch(
      submitConfigurationForm({
        formData: {
          ...updatedValues,
        },
        tableKey,
        content_id: application_id,
        returnResult: true,
      }),
    );
  };

  if (isOptionLoading) {
    return (
      <div className="space-y-8">
        {[...Array(6)].map((_, index) => (
          <Skeleton key={index} className="flex w-full rounded-2xl h-32" />
        ))}
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialAppConfigurationValues}
      validationSchema={appConfigurationFormSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        if (onFormikInstance) onFormikInstance(formik);
        return (
          <Fragment>
            <PredefinedCards title={tTabs('applicationName')} description={tTabs('updateApplicationName')}>
              <FormField
                label={tTabs('applicationName')}
                name="name"
                smallFont
                inputMod={InputMod.Filled}
                withoutLabel
                fullWidth
                inputType={InputType.Text}
                placeholder={tForm('applicationNamePlaceHolder')}
              />
            </PredefinedCards>
            <PredefinedCards
              title={tTabs('httpPort')}
              description={tTabs('httpPortDescription')}
              showChip
              selectedInput={formik?.values?.http_port}
            >
              <FormField
                label={tTabs('httpPort')}
                name="http_port"
                smallFont
                inputMod={InputMod.Filled}
                withoutLabel
                options={httpPortOption}
                withoutChip
                placeholder={tForm('httpPlaceHolder')}
                customizeColor
                textInputClassName="bg-white"
                fullWidth
                inputType={InputType.MultipleSelectInput}
                disabledSearch
              />
            </PredefinedCards>
            <PredefinedCards
              title={tTabs('httpsPort')}
              description={tTabs('httpsPortDescription')}
              selectedInput={formik?.values?.https_port}
              showChip
            >
              <FormField
                label={tTabs('httpsPort')}
                name="https_port"
                placeholder={tForm('httpsPlaceHolder')}
                smallFont
                inputMod={InputMod.Filled}
                withoutLabel
                options={httpsPortOption}
                withoutChip
                customizeColor
                textInputClassName="bg-white"
                fullWidth
                inputType={InputType.MultipleSelectInput}
                disabledSearch
              />
            </PredefinedCards>
            <PredefinedCards title={tTabs('maxBodySize')} description={tTabs('maxBodySizeDescription')}>
              <FormField
                label={tTabs('maxBodySize')}
                name="max_body_size"
                type="number"
                min={1}
                max={maxBodySizeMaxValue}
                inputMod={InputMod.Filled}
                withoutLabel
                smallFont
                adornmentWithoutM
                customizeColor
                textInputClassName="bg-white"
                fullWidth
                adornment={
                  <FormField
                    label={tForm('unit')}
                    name="max_body_size_unit"
                    inputType={InputType.Select}
                    withoutLabel
                    inputMod={InputMod.Filled}
                    smallFont
                    centerItem
                    customWidth="w-20"
                    withoutRing
                    className="flex border-l"
                    loading={false}
                    placeholder={tForm('selectYourUnit')}
                    options={convertEnumToObjectArray(StorageUnits)}
                  />
                }
              />
            </PredefinedCards>
            <PredefinedCards title={tTabs('maxPostArguments')} description={tTabs('maxPostArgumentsDescription')}>
              <FormField
                label={tTabs('maxPostArguments')}
                name="max_post_argument"
                type="number"
                min={1}
                max={maxPostArgumentMaxValue}
                inputMod={InputMod.Filled}
                withoutLabel
                smallFont
                adornmentWithoutM
                customizeColor
                textInputClassName="bg-white"
                fullWidth
              />
            </PredefinedCards>
            <PredefinedCards title={tTabs('maxUrlArguments')} description={tTabs('maxUrlArgumentsDescription')}>
              <FormField
                label={tTabs('maxUrlArguments')}
                name="max_url_argument"
                type="number"
                min={1}
                max={maxUrlArgumentMaxValue}
                inputMod={InputMod.Filled}
                withoutLabel
                smallFont
                adornmentWithoutM
                customizeColor
                textInputClassName="bg-white"
                fullWidth
              />
            </PredefinedCards>
            <PredefinedCards
              besideTitle={!isAboveSm}
              title={tForm('webSocket')}
              description={
                <div className="space-y-5">
                  <article className="text-xs font-light text-justify text-gray-400">
                    {tForm('webSocketDescription')}
                  </article>
                </div>
              }
            >
              <FormField
                withoutLabel
                label="Enabled"
                name="enable_websocket"
                fullWidth
                smallFont
                loading={false}
                direction={columnDirection.Row}
                inputType={InputType.Custom}
              >
                <ToggleButton name="enable_websocket" />
              </FormField>
            </PredefinedCards>
          </Fragment>
        );
      }}
    </Formik>
  );
};

export default AppConfigurationForm;
