import { FunnelIcon } from '@heroicons/react/24/outline';
import { Form, Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CdnConfigurationDashboardGraph, CdnConfigurationTab, CdnDashboardFilter, InputMod, InputType } from '@/enums';
import { Button, FormField, FormModal, ModalTitle, Responsive } from '@components';
import { useAppDispatch } from '@hooks';
import { updateFilterRequest, useCdnSummary, useConfigurationFilter } from '@store';
import { convertOptionList, Dictionaries, getOptionListFromCatalog } from '@utils';

export const DashboardFilter = ({ currentTab }) => {
  const dispatch = useAppDispatch();

  const { t: tForms } = useTranslation('configuration', { keyPrefix: 'forms.dashboard' });
  const { t: tModal } = useTranslation('configuration', { keyPrefix: 'modals.dashboard' });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const CdnConfigurationSummary = useCdnSummary();
  const isTrafficPage = currentTab === 'traffic';
  const timeFilter = useConfigurationFilter({
    tableKey: CdnConfigurationTab.Dashboard,
    origin: isTrafficPage ? CdnConfigurationDashboardGraph.TotalTraffic : CdnConfigurationDashboardGraph.TrafficLog,
    name: CdnDashboardFilter.Time,
  });

  const domainFilter = useConfigurationFilter({
    tableKey: CdnConfigurationTab.Dashboard,
    origin: isTrafficPage ? CdnConfigurationDashboardGraph.TotalTraffic : CdnConfigurationDashboardGraph.TrafficLog,
    name: CdnDashboardFilter.Domain,
  });

  const { options: domainOptions } = useMemo(
    () =>
      convertOptionList({
        originalOptions: CdnConfigurationSummary?.domain,
        defaultOptions: 'all',
        output: { value: 'id', label: 'domain' },
      }),
    [CdnConfigurationSummary?.domain],
  );

  const timeFilterOption = useMemo(() => getOptionListFromCatalog(Dictionaries.DashboardFilter) ?? [], []);

  const [initialValues, setInitialValues] = useState({
    [CdnDashboardFilter.Domain]: '15m',
    [CdnDashboardFilter.Time]: 'all',
  });

  useEffect(() => {
    if (timeFilter && domainFilter) {
      setInitialValues({
        [CdnDashboardFilter.Domain]: domainFilter.value,
        [CdnDashboardFilter.Time]: timeFilter.value,
      });
    }
  }, [domainFilter.value, timeFilter.value]);

  const onOptionClick = (event, setFieldValue, name) => {
    const currentOption = event.target.value;
    setFieldValue(name, currentOption);

    dispatch(
      updateFilterRequest({
        tableKey: CdnConfigurationTab.Dashboard,
        name: isTrafficPage ? CdnConfigurationDashboardGraph.TotalTraffic : CdnConfigurationDashboardGraph.TrafficLog,
        updatedValue: [{ graphTypeKey: name, filterValue: currentOption }],
      }),
    );
  };

  const handleSubmit = (values) => {
    dispatch(
      updateFilterRequest({
        tableKey: CdnConfigurationTab.Dashboard,
        name: isTrafficPage ? CdnConfigurationDashboardGraph.TotalTraffic : CdnConfigurationDashboardGraph.TrafficLog,
        updatedValue: [
          { graphTypeKey: CdnDashboardFilter.Domain, filterValue: values[CdnDashboardFilter.Domain] },
          { graphTypeKey: CdnDashboardFilter.Time, filterValue: values[CdnDashboardFilter.Time] },
        ],
      }),
    );
    setIsModalOpen(false);
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
      {({ setFieldValue, values, resetForm }) => {
        return (
          <Form className="flex gap-3">
            <Responsive showBelow="sm">
              <Button
                withoutPadding
                color="zinc"
                className="rounded-md p-2.5 max-h-9"
                onClick={() => setIsModalOpen(true)}
              >
                <FunnelIcon className="w-4 h-4.5" />
              </Button>
              <FormModal
                title={
                  <div className="flex items-center justify-between">
                    <ModalTitle className="font-medium">{tModal('filterBy')}</ModalTitle>
                    <Button
                      withoutPadding
                      type="reset"
                      customizeColor
                      className="text-xs cursor-pointer text-slate-700"
                      onClick={() => resetForm()}
                    >
                      {tModal('reset')}
                    </Button>
                  </div>
                }
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                confirmButton={{
                  children: tModal('apply'),
                  type: 'submit',
                }}
                submitError=""
              >
                <div className="flex flex-col gap-6">
                  <FormField
                    label={tForms('domain')}
                    inputType={InputType.Select}
                    inputMod={InputMod.Clean}
                    options={domainOptions}
                    fullWidth
                    smallFont
                    name={CdnDashboardFilter.Domain}
                  />
                  <FormField
                    label={tForms('time')}
                    inputType={InputType.Custom}
                    inputMod={InputMod.Clean}
                    options={timeFilterOption}
                    smallFont
                    fullWidth
                    name={CdnDashboardFilter.Time}
                  >
                    <div className="flex gap-2 flex-wrap">
                      {timeFilterOption.map((option, index) => {
                        const selectedValue = option.value === values[CdnDashboardFilter.Time];
                        return (
                          <Button
                            withoutPadding
                            customizeColor
                            smallFont
                            key={index}
                            className={`px-3 py-2 border rounded-lg ${
                              selectedValue
                                ? 'bg-blue-100 border-blue-500 text-blue-600'
                                : 'border-gray-300 text-gray-600'
                            }`}
                            onClick={(e) => setFieldValue(CdnDashboardFilter.Time, option.value)}
                          >
                            {option.label}
                          </Button>
                        );
                      })}
                    </div>
                  </FormField>
                </div>
              </FormModal>
            </Responsive>
            <Responsive showAbove="sm">
              <FormField
                name={CdnDashboardFilter.Time}
                smallFont
                withoutLabel
                placeholder={tForms('selectYourTime')}
                inputType={InputType.Select}
                required
                textInputClassName="min-w-36"
                options={getOptionListFromCatalog(Dictionaries.DashboardFilter)}
                onChange={(e) => onOptionClick(e, setFieldValue, CdnDashboardFilter.Time)}
              />
              <FormField
                name={CdnDashboardFilter.Domain}
                smallFont
                withoutLabel
                placeholder={tForms('selectYourDomain')}
                inputType={InputType.Select}
                required
                options={domainOptions}
                onChange={(e) => onOptionClick(e, setFieldValue, CdnDashboardFilter.Domain)}
              />
            </Responsive>
          </Form>
        );
      }}
    </Formik>
  );
};
