import { CdnConfigurationTab } from '@/enums';
import { useConfigurationList } from '@store/slices';
import { useCallback, useEffect, useState } from 'react';

// Define the structure of the origin option
interface OriginOption {
  label: string;
  value: string;
}

interface ConfigurationItem {
  name: string;
  id: string;
}

export const useGetOriginOptions = () => {
  const tableKey = CdnConfigurationTab.Origin;
  const originList: ConfigurationItem[] = useConfigurationList(tableKey);

  const [originOptions, setOriginOptions] = useState<OriginOption[]>([]);

  const getOriginOptionList = useCallback(async () => {
    try {
      const options = originList.map((option) => ({
        label: option.name,
        value: option.id,
      }));
      setOriginOptions(options);
    } catch (error) {
      console.error('Failed to get origin options:', error);
    }
  }, [originList]);

  useEffect(() => {
    getOriginOptionList();
  }, [getOriginOptionList]);

  const getOriginByValue = (value: string): string | undefined => {
    const option = originOptions.find((option) => option.value === value);
    return option ? option.label : value;
  };

  return { originOptions, getOriginByValue };
};
