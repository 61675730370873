import i18n from 'i18next';

import {
  ACTION_EN,
  API_SPAM_LIMIT_OPTION_EN,
  BOOLEAN_VARIABLE_TYPE_EN,
  BROWSER_CACHE_EN,
  CACHE_OVERWRITE_HEADER_EN,
  CACHE_TTL_OPTION_EN,
  CACHING_LEVEL_EN,
  CLIENT_COUNTRY_TYPE_EN,
  CLIENT_VARIATBLE_TYPE_EN,
  DASHBOARD_BLOCK_ACTION_EN,
  DASHBOARD_FILTER_EN,
  DEFAULT_OPTION_EN,
  DISPLAY_MODE_EN,
  LANGUAGE_EN,
  LIMIT_REQUEST_RATE_EN,
  LIMIT_REQUEST_RATE_REJECTION_ACTION_EN,
  LIMIT_RESPONSE_DATA_RATE_UNIT_EN,
  OPERATOR_EN,
  ORIGIN_TYPE_EN,
  PAGERULES_REDIRECT_OPTION_EN,
  REDIRECT_CURRENT_EN,
  REDIRECT_HOST_EN,
  REDIRECT_STATUS_CODE_EN,
  REGIONS_EN,
  REQUEST_METHOD_VARIABLE_TYPE_EN,
  REQUEST_RATE_UNIT_EN,
  RESPONSE_COOKIE_VALUE_EN,
  RESPONSE_HEADER_VARIABLE_EN,
  ROLES_EN,
  SSL_TYPE_EN,
  TIME_EN,
  UNDER_ATTACK_MODE_EN,
  VARIABLE_EN,
  WAF_ACTION_EN,
  WAF_LANGUAGE_EN,
  WAF_RULES_EN,
  WAF_SENSITIVITY_EN,
  WAF_TYPE_EN,
} from '../../i18n/en';

export enum Dictionaries {
  Roles = 'ROLES',
  Languages = 'LANGUAGES',
  Operator = 'OPERATOR',
  Variable = 'VARIABLE',
  OriginType = 'ORIGIN_TYPE',
  WAFAction = 'WAF_ACTION',
  WAFSensitivity = 'WAF_SENSITIVITY',
  WAFType = 'WAF_Type',
  WAFLanguage = 'WAF_LANGUAGE',
  SSLType = 'SSL_TYPE',
  Time = 'time',
  WAFRules = 'WAFRULES',
  Regions = 'REGIONS',
  Action = 'ACTION',
  ClientVariableType = 'CLIENT_VARIABLE_TYPE',
  RequestMethodVariableType = 'REQUEST_METHOD_VARIABLE_TYPE',
  SchemeVariableType = 'SCHEME_VARIABLE_TYPE',
  BooleanVariableType = 'BOOLEAN_VARIABLE_TYPE',
  ClientCountryType = 'CLIENT_COUNTRY_VARIABLE_TYPE',
  BrowserCacheOption = 'BROWSER_CACHE_OPTION',
  DefaultOption = 'DEFAULT_OPTION',
  CachingLevelOption = 'CACHING_LEVEL_OPTION',
  LimitRequestRate = 'LIMIT_REQUEST_RATE',
  LimitRequestRateRejectionAction = 'LIMIT_REQUEST_RATE_REJECTION_ACTION',
  LimitResponseDataRateUnit = 'LIMIT_RESPONSE_DATA_RATE_UNIT',
  RedirectCurrent = 'REDIRECT_CURRENT',
  RedirectHost = 'REDIRECT_HOST',
  RedirectStatusCode = 'REDIRECT_STATUS_CODE',
  ResponseCookieValue = 'RESPONSE_COOKIE_VALUE',
  ResponseHeaderVariable = 'RESPONSE_HEADER_VARIABLE',
  ApiSpamLimitOption = 'API_SPAM_LIMIT_OPTION',
  CacheTTLOption = 'CACHE_TTL_OPTION',
  PageRulesRedirectOption = 'PAGERULES_REDIRECT_OPTION',
  DisplayMode = 'DISPLAY_MODE',
  UnderAttackMode = 'UNDER_ATTACK_MODE',
  DashboardFilter = 'DASHBOARD_FILTER',
  RequestRateUnit = 'REQUEST_RATE_UNIT',
  DashboardBlockAction = 'DASHBOARD_BLOCK_ACTION',
  CacheOverwriteHedaer = 'CACHE_OVERWRITE_HEADER',
}

const dictionaryMappings: Record<Dictionaries, Record<string, string>> = {
  [Dictionaries.Roles]: ROLES_EN,
  [Dictionaries.Languages]: LANGUAGE_EN,
  [Dictionaries.Operator]: OPERATOR_EN,
  [Dictionaries.Variable]: VARIABLE_EN,
  [Dictionaries.OriginType]: ORIGIN_TYPE_EN,
  [Dictionaries.WAFAction]: WAF_ACTION_EN,
  [Dictionaries.WAFSensitivity]: WAF_SENSITIVITY_EN,
  [Dictionaries.WAFLanguage]: WAF_LANGUAGE_EN,
  [Dictionaries.WAFType]: WAF_TYPE_EN,
  [Dictionaries.SSLType]: SSL_TYPE_EN,
  [Dictionaries.Time]: TIME_EN,
  [Dictionaries.WAFRules]: WAF_RULES_EN,
  [Dictionaries.Action]: ACTION_EN,
  [Dictionaries.ClientVariableType]: CLIENT_VARIATBLE_TYPE_EN,
  [Dictionaries.BooleanVariableType]: BOOLEAN_VARIABLE_TYPE_EN,
  [Dictionaries.RequestMethodVariableType]: REQUEST_METHOD_VARIABLE_TYPE_EN,
  [Dictionaries.ClientCountryType]: CLIENT_COUNTRY_TYPE_EN,
  [Dictionaries.SchemeVariableType]: ORIGIN_TYPE_EN,
  [Dictionaries.BrowserCacheOption]: BROWSER_CACHE_EN,
  [Dictionaries.DefaultOption]: DEFAULT_OPTION_EN,
  [Dictionaries.CachingLevelOption]: CACHING_LEVEL_EN,
  [Dictionaries.LimitRequestRate]: LIMIT_REQUEST_RATE_EN,
  [Dictionaries.LimitRequestRateRejectionAction]: LIMIT_REQUEST_RATE_REJECTION_ACTION_EN,
  [Dictionaries.RequestRateUnit]: REQUEST_RATE_UNIT_EN,
  [Dictionaries.LimitResponseDataRateUnit]: LIMIT_RESPONSE_DATA_RATE_UNIT_EN,
  [Dictionaries.RedirectCurrent]: REDIRECT_CURRENT_EN,
  [Dictionaries.RedirectHost]: REDIRECT_HOST_EN,
  [Dictionaries.RedirectStatusCode]: REDIRECT_STATUS_CODE_EN,
  [Dictionaries.ResponseCookieValue]: RESPONSE_COOKIE_VALUE_EN,
  [Dictionaries.ResponseHeaderVariable]: RESPONSE_HEADER_VARIABLE_EN,
  [Dictionaries.ApiSpamLimitOption]: API_SPAM_LIMIT_OPTION_EN,
  [Dictionaries.CacheTTLOption]: CACHE_TTL_OPTION_EN,
  [Dictionaries.PageRulesRedirectOption]: PAGERULES_REDIRECT_OPTION_EN,
  [Dictionaries.DisplayMode]: DISPLAY_MODE_EN,
  [Dictionaries.UnderAttackMode]: UNDER_ATTACK_MODE_EN,
  [Dictionaries.DashboardFilter]: DASHBOARD_FILTER_EN,
  [Dictionaries.DashboardBlockAction]: DASHBOARD_BLOCK_ACTION_EN,
  [Dictionaries.Regions]: REGIONS_EN,
  [Dictionaries.CacheOverwriteHedaer]: CACHE_OVERWRITE_HEADER_EN,
};

export const getOptionListFromCatalog = (name?: Dictionaries, disabledOptions?: number[]) => {
  if (!name || !dictionaryMappings[name]) return [];

  return Object.keys(dictionaryMappings[name]).map((code, index) => ({
    label: i18n.t(`${name}:${code}`),
    value: code,
    disabled: disabledOptions?.includes(index) ?? false,
  }));
};

export const getCodeValueFromCatalog = (key: Dictionaries, value: string) => {
  return i18n.t(`${key}:${value}`);
};

export const getfilteredOptionList = ({ dictionaries, availableVariables }) => {
  return getOptionListFromCatalog(dictionaries)?.filter((option) => availableVariables.includes(option.value));
};
