import { validateValueEqual } from './validateValueEqual';

export const getGraphTypeValue = (list: Array<{ label: string; value: any }>, graphTypeKey: string): any => {
  if (!Array.isArray(list) || validateValueEqual(list.length, 0)) {
    return [];
  }

  const entry = list.find((item) => validateValueEqual(item.label, graphTypeKey));

  return entry ? entry.value : [];
};
