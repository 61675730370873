import { CdnConfigurationTab } from '@/enums';
import {
  getConfigurationList,
  useConfigurationList,
  useConfigurationLoading,
  useConfigurationLoadSuccess,
} from '@store/slices';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

interface Option {
  label: string;
  value: string;
}

interface ConfigurationItem {
  name: string;
  id: string;
}

export const useGetConfigurationOptions = ({
  tableKey,
  dataKey,
  conditional,
  defaultData,
}: {
  tableKey: CdnConfigurationTab;
  dataKey: string;
  conditional?: any;
  defaultData?: any;
}) => {
  const { id: application_id } = useParams();
  const dispatch = useDispatch();

  const list: ConfigurationItem[] = useConfigurationList(tableKey);
  const loadSuccess = useConfigurationLoadSuccess(tableKey);
  const isLoading = useConfigurationLoading(tableKey);
  const [options, setOptions] = useState<Option[]>([]);

  const getOptionList = useCallback(() => {
    try {
      let optionsList = list.map((option) => ({
        label: option.name,
        value: option[dataKey],
      }));

      // Check if defaultData is provided and has the necessary properties
      if (defaultData && defaultData.value !== undefined && defaultData.label !== undefined) {
        // Prepend the defaultData to the options array
        optionsList = [defaultData, ...optionsList];
      }

      setOptions(optionsList);
    } catch (error) {
      console.error('Failed to get options:', error);
    }
  }, [dataKey, list, setOptions]);
  useEffect(() => {
    if (Array.isArray(list) && list.length < 1 && !loadSuccess) {
      if ((!conditional || conditional.every((obj) => Object.keys(obj).length > 0)) && !isLoading) {
        dispatch(getConfigurationList({ tableKey, application_id }));
      }
    }
  }, [application_id, list, tableKey, dispatch, loadSuccess, isLoading, conditional]);

  useEffect(() => {
    getOptionList();
  }, [getOptionList]);

  const getOptionByValue = (value: string): string | undefined => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : value;
  };
  return { options, getOptionByValue };
};
