interface UrlAndStatus {
  url: string;
  submitToastCode: string;
}

interface ApiUrlParams {
  isSorting: boolean;
  sortingPath?: string;
  contentId?: string;
  baseApiPath: string;
}

export const generateApiUrlAndStatus = ({
  isSorting,
  sortingPath,
  contentId,
  baseApiPath,
}: ApiUrlParams): UrlAndStatus => {
  if (isSorting && sortingPath) {
    return { url: sortingPath, submitToastCode: 'Sorted' };
  }

  if (contentId) {
    return { url: `${baseApiPath}/${contentId}`, submitToastCode: 'Edited' };
  }

  return { url: baseApiPath, submitToastCode: 'Created' };
};
