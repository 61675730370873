import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ConfigurationTab, ConfirmationModal, Responsive, SettingsButtons } from '@components';
import { CdnConfigurationTab, StatusType, TableActionType } from '@enums';
import { useAppDispatch, useResetLoadSuccess } from '@hooks';
import { addSnack, getConfigurationList, getMultipleRequest, submitClearCache } from '@store';
import { useActionHandlers, validateValueEqual } from '@utils';

import { FormikProps } from 'formik';
import { CacheClearingModal, CachePurge, ConfigurationModal, CustomCacheForm, PredefinedCacheView } from './';

export const Cache: FC = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const predefinedCacheTableKey = CdnConfigurationTab.PredefinedCache;
  const customCacheTableKey = CdnConfigurationTab.CustomCache;

  const { t: tCommon } = useTranslation('common');
  const { t: tCacheModal } = useTranslation('configuration', { keyPrefix: 'modals.cache' });
  const { t: tCacheToast } = useTranslation('configuration', { keyPrefix: 'toasts.cache' });
  const { t: tModal } = useTranslation('configuration', { keyPrefix: 'modals' });
  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.cache' });

  const tabs = [
    { name: tTabs('cacheRules'), key: 'cacheRules' },
    // { name: 'Custom configuration', key: 'customConfiguration' },
  ];

  const formikRef = useRef<FormikProps<any> | null>(null);
  const [openCacheClearingModal, setOpenCacheClearingModal] = useState<boolean>(false);
  const [openCacheRulesConfigurationModal, setOpenCacheRulesConfigurationModal] = useState<boolean>(false);
  const [editApplication, setEditApplication] = useState<boolean>(false);
  const [openDeleteCustomCacheDialog, setOpenDeleteCustomCacheDialog] = useState<boolean>(false);
  const [openPurgeCacheDialog, setOpenPurgeCacheDialog] = useState<boolean>(false);
  const [openResetSettingsDialog, setOpenResetSettingsDialog] = useState<boolean>(false);
  const [currentConfigurationData, setCurrentConfigurationData] = useState<any>();
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  useResetLoadSuccess([predefinedCacheTableKey, customCacheTableKey]);

  useEffect(() => {
    if (application_id) {
      dispatch(
        getMultipleRequest({
          tab: predefinedCacheTableKey,
          requests: [
            { tableKey: predefinedCacheTableKey, application_id },
            // { tableKey: customCacheTableKey, application_id },
          ],
        }),
      );
    }
  }, [application_id]);

  const customCacheActionHandlers = useActionHandlers<any>({
    setData: setCurrentConfigurationData,
    setEditOpen: setOpenCacheRulesConfigurationModal,
    setDeleteOpen: setOpenDeleteCustomCacheDialog,
    setEdit: setEditApplication,
    tableKey: predefinedCacheTableKey,
    activeHandlers: [TableActionType.Create, TableActionType.Edit, TableActionType.Delete],
  });

  const handleCurrentTab = useCallback((currentTab: string) => {
    setActiveTab(currentTab);
  }, []);

  const handleResetSettingsModal = useCallback(() => {
    setOpenResetSettingsDialog((prev) => !prev);
  }, []);

  // const handleCustomPurgeCacheModal = useCallback(() => {
  //   setOpenCacheRulesConfigurationModal((prev) => !prev);
  // }, []);

  const handlePurgeCacheDialog = useCallback(() => {
    setOpenPurgeCacheDialog((prev) => !prev);
  }, []);

  const handlePurgeCacheDeleteClose = () => {
    dispatch(submitClearCache({ tableKey: predefinedCacheTableKey, application_id }));
    setOpenPurgeCacheDialog(false);
  };

  const handleCustomCacheDeleteClose = () => {
    dispatch(addSnack({ type: StatusType.Success, message: tCacheToast('deleteSuccessful') }));
    setOpenDeleteCustomCacheDialog(false);
    application_id && dispatch(getConfigurationList({ application_id, tableKey: customCacheTableKey }));
  };

  const handleFormikInstance = (formik) => {
    formikRef.current = formik;
  };

  const handleSaveSettings = useCallback(() => {
    formikRef?.current?.handleSubmit();
  }, []);

  return (
    <div className="flex flex-col flex-1 space-y-8">
      <div className="flex justify-between">
        <ConfigurationTab arrayTab={tabs} currentTab={activeTab} setCurrentTab={handleCurrentTab} />
        <Responsive showAbove="sm">
          {validateValueEqual(activeTab, 'cacheRules') && (
            <SettingsButtons
              activeTab={activeTab}
              tabName="predefinedCache"
              resetTitle={tCacheModal('resetSettings')}
              resetContent={tCacheModal('areYouSureReset')}
              saveTitle={tCacheModal('saveSettings')}
              saveContent={tCacheModal('areYouSureSave')}
              openResetDialog={openResetSettingsDialog}
              handleResetModal={handleResetSettingsModal}
              handleSaveModal={handleSaveSettings}
              tModal={tModal}
              formikRef={formikRef}
            />
          )}
        </Responsive>
      </div>
      <div className={`flex flex-col gap-8`}>
        {validateValueEqual(activeTab, 'cacheRules') ? (
          <>
            <CachePurge openPurgeDialog={handlePurgeCacheDialog} />
            <PredefinedCacheView
              formikRef={formikRef}
              onSaveClose={handleSaveSettings}
              onResetClose={handleResetSettingsModal}
              onFormikInstance={handleFormikInstance}
            />
          </>
        ) : (
          <CustomCacheForm actionHandlers={customCacheActionHandlers} />
        )}
      </div>
      <Responsive showBelow="sm">
        {validateValueEqual(activeTab, 'cacheRules') && (
          <SettingsButtons
            activeTab={activeTab}
            tabName="predefinedCache"
            resetTitle={tCacheModal('resetSettings')}
            resetContent={tCacheModal('areYouSureReset')}
            saveTitle={tCacheModal('saveSettings')}
            saveContent={tCacheModal('areYouSureSave')}
            openResetDialog={openResetSettingsDialog}
            handleResetModal={handleResetSettingsModal}
            handleSaveModal={handleSaveSettings}
            tModal={tModal}
            formikRef={formikRef}
          />
        )}
      </Responsive>
      {openCacheRulesConfigurationModal && (
        <ConfigurationModal
          data={currentConfigurationData}
          isEdit={editApplication}
          open={openCacheRulesConfigurationModal}
          onClose={() => setOpenCacheRulesConfigurationModal(false)}
        />
      )}

      {openCacheClearingModal && (
        <CacheClearingModal
          data={[]}
          isEdit={editApplication}
          open={openCacheClearingModal}
          onClose={() => setOpenCacheClearingModal(false)}
        />
      )}
      {openDeleteCustomCacheDialog && (
        <ConfirmationModal
          title={tCacheModal('cacheDelete')}
          content={tModal('areYouSureDelete', { domain: currentConfigurationData?.name })}
          confirmButton={{ children: tCommon('button.confirm'), onClick: handleCustomCacheDeleteClose }}
          open={openDeleteCustomCacheDialog}
          type={StatusType.Delete}
          onClose={() => setOpenDeleteCustomCacheDialog(false)}
        />
      )}
      {openPurgeCacheDialog && (
        <ConfirmationModal
          title={tCacheModal('purgeEverything')}
          content={tCacheModal('purgeDescription')}
          confirmButton={{ children: tCommon('button.confirm'), onClick: handlePurgeCacheDeleteClose }}
          open={openPurgeCacheDialog}
          type={StatusType.Warning}
          onClose={handlePurgeCacheDialog}
        />
      )}
    </div>
  );
};
