import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState, useAppSelector } from '@hooks';
import type { WorkspaceSliceState } from '@types';

const workspaceInitialState: WorkspaceSliceState = {
  isLoading: false,
  workSpaceId: null,
  workSpaceList: [],
  error: null,
};

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState: workspaceInitialState,
  reducers: {
    getWorkspaceList: (state) => {
      state.isLoading = true;
    },
    getWorkSpaceListSuccess: (state, { payload }: PayloadAction<any>) => {
      const { workspace_list } = payload;
      state.workSpaceList = workspace_list;
      state.isLoading = false;
    },
    getWorkSpaceListError: (state, { payload }: PayloadAction<any>) => {
      state.workSpaceList = [];
      state.isLoading = false;
    },
    updateWorkSpaceId: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = true;
    },
    updateWorkSpaceIdSuccess: (state, { payload }: PayloadAction<any>) => {
      const { workspace_list, default_workspace_id } = payload;
      state.workSpaceList = workspace_list;
      state.workSpaceId = default_workspace_id;
      state.isLoading = false;
    },
    clearWorkSpaceId: (state) => {
      state.workSpaceId = null;
    },
    updateWorkSpaceIdError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const {
  getWorkspaceList,
  getWorkSpaceListSuccess,
  getWorkSpaceListError,
  updateWorkSpaceId,
  updateWorkSpaceIdSuccess,
  updateWorkSpaceIdError,
  clearWorkSpaceId,
} = workspaceSlice.actions;

export const useWorkspaceId = () => useAppSelector((state: RootState) => state.workspace.workSpaceId ?? 0);
export const useWorkspaceList = () => useAppSelector((state: RootState) => state.workspace.workSpaceList ?? []);
export const useWorkspaceLoading = () => useAppSelector((state: RootState) => state.workspace.isLoading);
export const useWorkspaceError = () => useAppSelector((state: RootState) => state.workspace.error);

const userReducer = workspaceSlice.reducer;
export default userReducer;
