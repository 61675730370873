import { useTranslation } from 'react-i18next';

import { validateValueEqual } from '@/utils';
import { Badges, InformationModal, Text } from '@components';
import { CdnConfigurationTab, StatusType, certificateStatusType, verificationType } from '@enums';
import { useState } from 'react';

interface RichTableCellDefaultProps<T> {
  data?: T;
  rawData: T;
  tableKey: string;
}

export const BadgeTableCell = <T,>({ data, rawData, tableKey }: RichTableCellDefaultProps<T>) => {
  const { t: tVerification } = useTranslation('common', { keyPrefix: 'verificationStatus' });
  const { t: tcVerification } = useTranslation('common', { keyPrefix: 'certificateStatus' });
  const { t: tDescription } = useTranslation('description', { keyPrefix: 'configuration' });

  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
  const isError =
    data === tVerification(verificationType.UNVERIFIED) || data === tcVerification(certificateStatusType.Expired);

  const isWarning =
    data === tcVerification(certificateStatusType.ExpiredSoon) ||
    data === tcVerification(certificateStatusType.PendingIssue);

  function getStatusType(isError: boolean, isWarning: boolean): StatusType {
    if (isError) {
      return StatusType.Error;
    } else if (isWarning) {
      return StatusType.Warning;
    } else {
      return StatusType.Success;
    }
  }

  const handleInformationModal = () => {
    setOpenInfoModal(!openInfoModal);
  };

  return (
    <div className="h-full gap-x-2 text-black items-center hover:cursor-pointer">
      <Badges onClick={handleInformationModal} name={data} type={getStatusType(isError, isWarning)} />
      <InformationModal
        title={
          validateValueEqual(tableKey, CdnConfigurationTab.Domain)
            ? tDescription('domain.cName.verification_title')
            : tDescription('ssl.status.status_title')
        }
        size="xl"
        topTitle
        hideIcon
        content={
          <>
            {!validateValueEqual(tableKey, CdnConfigurationTab.Domain) && (
              <Text $level={5} className="text-sm font-medium leading-5 text-justify text-theme-primary-main pt-1">
                {typeof data === 'string' ? tDescription(`ssl.status.${data?.toLowerCase()}_title`) : ''}
              </Text>
            )}

            <Text $level={5} className="text-xs leading-5 text-justify pt-1">
              {validateValueEqual(tableKey, CdnConfigurationTab.Domain)
                ? tDescription(rawData ? 'domain.cName.cNameSuccessVerified' : 'domain.cName.cNameFailVerified')
                : typeof data === 'string'
                  ? tDescription(`ssl.status.${data?.toLowerCase()}_description`)
                  : ''}
            </Text>
          </>
        }
        onClose={handleInformationModal}
        open={openInfoModal}
      />
    </div>
  );
};
