import NoRecordFound from '@components/Charts/NoRecordFound';
import { breakpoints, useMediaQuery } from '@components/Responsive/ResponsiveVisible';
import { useChartDimensions } from '@hooks';
import { formatBarChartYAxisUnit, formatNumberWithSeparator } from '@utils';
import { ApexOptions } from 'apexcharts';
import React, { memo, useCallback, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

interface BarChartProps {
  label: {
    filterValue: string;
    dataSets: Array<{ x: string; y: number }>;
  };
}

const HorizontalBarChart: React.FC<BarChartProps> = memo(({ label }) => {
  const isBelowSm = useMediaQuery(`(max-width: ${breakpoints.sm}px)`);

  const dimensionData = useMemo(
    () => ({
      mobile: { height: 350, yAxisWidth: 80 },
      tablet: { height: 400, yAxisWidth: 100 },
      desktop: { height: 480, yAxisWidth: 130 },
      large: { height: 500, yAxisWidth: 150 },
    }),
    [],
  );

  const { chartHeight, yAxisWidth } = useChartDimensions({ dimensionsData: dimensionData });

  const { seriesData, maxValue } = useMemo(() => {
    const dashboardGraphData = label?.dataSets ?? [];
    const value: number[] = [];
    const key: string[] = [];
    let max = 0;

    dashboardGraphData.forEach(({ x = '', y = 0 }) => {
      value.push(y);
      key.push(x);
      if (y > max) max = y;
    });

    return { seriesData: { value, key }, maxValue: max };
  }, [label?.dataSets]);

  const paddedMaxValue = maxValue * 1.15;

  const formatYAxis = useCallback((value: string | number) => {
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;
    return formatBarChartYAxisUnit(numericValue);
  }, []);

  const formatTooltip = useCallback((value: number) => formatNumberWithSeparator(value), []);

  const options = useMemo<ApexOptions>(
    () => ({
      chart: {
        type: 'bar',
        height: chartHeight,
        width: '100%',
        toolbar: { show: false },
        animations: { enabled: false }, // Disable animations for performance
      },
      dataLabels: { enabled: false },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '70%',
          dataLabels: { position: 'outside' },
        },
      },
      background: { enabled: true },
      grid: {
        show: true,
        borderColor: '#e7e7e7',
        strokeDashArray: 4,
        position: 'back',
        xaxis: { lines: { show: true } },
        yaxis: { lines: { show: false } },
      },
      labels: seriesData.key,
      xaxis: {
        labels: { formatter: formatYAxis },
        tickAmount: isBelowSm ? 3 : undefined,
        max: paddedMaxValue,
        min: 0,
      },
      yaxis: {
        labels: {
          minWidth: yAxisWidth,
          maxWidth: yAxisWidth,
          style: { colors: ['#000'] },
        },
      },
      tooltip: {
        y: { formatter: formatTooltip },
      },
    }),
    [seriesData.key, isBelowSm, paddedMaxValue, yAxisWidth, chartHeight, formatYAxis, formatTooltip],
  );

  const series = useMemo(() => [{ name: 'Request Count', data: seriesData.value }], [seriesData.value]);

  if (seriesData.value.length === 0 || label?.dataSets?.length === 0) {
    return <NoRecordFound />;
  }

  return (
    <div className="justify-center mt-8">
      <ReactApexChart options={options} series={series} type="bar" height={chartHeight} />
    </div>
  );
});

export default HorizontalBarChart;
