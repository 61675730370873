import { DialogPanel } from '@headlessui/react';
import { InformationCircleIcon, MinusCircleIcon, PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Field, FieldArray, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button, DialogModal, TextInput } from '@components';
import { StatusType, VariantType } from '@enums';
import { useAppDispatch } from '@hooks';
import { addSnack } from '@store';

interface SSLModalProps {
  data?: any;
  open: boolean;
  isEdit: boolean;
  onClose: () => void;
}

export const CacheClearingModal = ({ data, open, isEdit, onClose }: SSLModalProps) => {
  const { t: tToast } = useTranslation('form', { keyPrefix: 'toast' });

  const dispatch = useAppDispatch();
  const handleSubmit = (values: any) => {
    dispatch(
      addSnack({
        type: StatusType.Success,
        message: isEdit ? tToast('editSSLSuccessful') : tToast('addSSLSuccessful'),
      }),
    );
    onClose();
  };

  const initialValues: any = {
    rules: data ?? [
      {
        name: '',
        documentType: '',
      },
    ],
    ipList: [{ hostName: '' }],
  };

  return (
    <DialogModal open={open} onClose={onClose}>
      <DialogPanel className="min-w-[40rem] border-gray-900/10 relative transform transition-all text-left bg-theme-surface-default  rounded-lg shadow-xl">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values }) => {
            return (
              <Form className="bg-white rounded-lg p-8 space-y-8">
                <div className="flex items-center justify-between">
                  <article className="text-lg font-medium leading-6 text-gray-700">{'Custom cache purge jobs'}</article>
                  <Button
                    variant={VariantType.Icon}
                    hoverEffect
                    withoutPadding
                    rounded="rounded-full"
                    iconWithoutM
                    onClick={onClose}
                    icon={<XMarkIcon className="w-5 h-5" />}
                  />
                </div>
                <article className="text-xs flex bg-blue-50 fill-blue-400 rounded-md p-3 text-blue-500">
                  <InformationCircleIcon className="w-4 h-4 mr-3" />
                  {'Any resources in the cache that exactly match the URL will be purged from the cache'}
                </article>
                <div className="flex">
                  <label
                    htmlFor={`ipList`}
                    className="w-2/5 block text-sm font-normal leading-6 text-gray-700 text-left"
                  >
                    {'URL Path'}
                  </label>
                  <fieldset className="w-3/5">
                    <div className="space-y-4">
                      <FieldArray name="ipList">
                        {(arrayHelpers) => (
                          <div className="grid grid-cols-1 gap-y-4 flex-col w-full">
                            {values.ipList.map((_ip, index) => (
                              <div key={index} className={` flex w-full space-x-3 items-center`}>
                                <div className="flex-grow">
                                  <Field
                                    smallFont
                                    name={`ipList.${index}.hostName`}
                                    placeholder={'URL Path'}
                                    as={TextInput}
                                  />
                                </div>
                                <div className={`flex space-x-3 items-start pt-1.5 h-full`}>
                                  <Button
                                    className="bg-white"
                                    variant={VariantType.Clean}
                                    withoutPadding
                                    onClick={() => arrayHelpers.push({ hostName: '' })}
                                  >
                                    <PlusCircleIcon className="w-6 h-6 text-blue-500" />
                                  </Button>
                                  <Button
                                    className="bg-white"
                                    variant={VariantType.Clean}
                                    withoutPadding
                                    disabled={index === 0}
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <MinusCircleIcon
                                      className={`w-6 h-6 ${index === 0 ? 'text-gray-300' : 'text-red-500'}`}
                                    />
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  </fieldset>
                </div>
                <article className="text-us text-gray-400">
                  <article>
                    1. Use carriage return to separate, enter one per line, non-repeatable, add up to 40;
                  </article>
                  <article>
                    2. If you want to delete a specific file, enter the full path of the file (e.g.,
                    https://www.example.com/cat.jpg).
                  </article>
                  <article>
                    3. If you want to delete all files in a directory, enter a wildcard character (e.g.,
                    https://www.example.com/*).
                  </article>
                </article>
                <div className="sm:grid sm:grid-cols-2 gap-8">
                  <Button
                    variant={VariantType.Outlined}
                    className="rounded-md ring-theme-primary-main ring-opacity-30"
                    onClick={onClose}
                  >
                    <article className="ellipsis-text">{'Cancel'}</article>
                  </Button>
                  <Button type="submit">{'Confirm'}</Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </DialogPanel>
    </DialogModal>
  );
};
