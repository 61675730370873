import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { FormActionType, FormType } from '@enums';
import { RootState, useAppSelector } from '@hooks';
import { FormSliceState, FormState } from '@types';

const defaultFormState: FormState = {
  loading: false,
  submitting: false,
  saveSuccess: false,
  sortSuccess: false,
  formList: [],
  formContent: {},
  sortingList: [],
  filter: {
    searchTerm: '',
    filterCriteria: [{ label: 'status', value: 'all' }],
  },
  selectedIds: [],
  pagination: {
    first_page: 1,
    last_page: 1,
    next_page: 1,
    page: 1,
    total: 0,
    total_pages: 0,
    page_size: 10,
  },
  procedure: FormActionType.Idle,
  error: null,
};

const initialState: FormSliceState = Object.values(FormType).reduce((acc, tab) => {
  acc[tab as keyof FormSliceState] = {
    ...defaultFormState,
  };
  return acc;
}, {} as FormSliceState);

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    fetchFormList: (
      state,
      {
        payload,
      }: PayloadAction<{
        formType: FormType;
        params?: {
          contentId?: string | number;
          application_id?: string | number;
          workspace_id?: string | number;
          [key: string]: string | number | undefined;
        };
        globalOptions?: {
          withoutParams?: boolean;
        };
      }>,
    ) => {
      state[payload.formType].loading = true;
    },
    fetchFormListSuccess: (
      state,
      { payload }: PayloadAction<{ formType: FormType; list: any[]; paginationInfo: any }>,
    ) => {
      const { formType, list, paginationInfo } = payload;
      state[formType].loading = false;
      state[formType].formList = list;
      state[formType].pagination = {
        ...state[formType].pagination,
        ...paginationInfo,
      };
    },
    fetchFormListError: (state, { payload }: PayloadAction<{ formType: FormType; responseResult: any }>) => {
      const { formType, responseResult } = payload;
      state[formType].loading = false;
      state[formType].error = responseResult;
    },
    fetchSortingListSuccess(state, { payload }: PayloadAction<{ formType: FormType; responseResult: any }>) {
      const { formType, responseResult } = payload;
      if (state[formType]) {
        state[formType].loading = false;
        state[formType].sortSuccess = true;
        state[formType].sortingList = responseResult;
      }
    },
    submitFormRequest: (
      state,
      {
        payload,
      }: PayloadAction<{
        formType: FormType;
        formData: any;
        params?: {
          contentId?: string | number;
          applicationId?: string | number;
          workspace_id?: string | number;
          [key: string]: string | number | undefined;
        };
        globalOptions?: {
          pagination?: boolean;
          response?: boolean;
          sorting?: boolean;
          returnResult?: boolean;
          toast?: boolean;
        };
      }>,
    ) => {
      state[payload.formType].submitting = true;
    },
    submitFormSuccess: (state, { payload }: PayloadAction<{ formType: FormType; content: any }>) => {
      const { formType, content } = payload;
      state[formType].submitting = false;
      state[formType].formContent = content;
      state[formType].saveSuccess = true;
    },
    submitFormError: (state, { payload }: PayloadAction<{ formType: FormType; error: string }>) => {
      const { formType, error } = payload;
      state[formType].submitting = false;
      state[formType].error = error;
    },
    updateFormFilter: (
      state,
      { payload }: PayloadAction<{ formType: FormType; filterType: 'search' | 'content'; value: any }>,
    ) => {
      const { formType, filterType, value } = payload;
      state[formType].filter[filterType] = value;
    },
    updateFormProcedure: (state, { payload }: PayloadAction<{ formType: FormType; procedure: FormActionType }>) => {
      const { formType, procedure } = payload;
      state[formType].procedure = procedure;
    },
    updateSelectedIds: (state, { payload }: PayloadAction<{ formType: FormType; id: string | string[] }>) => {
      const { formType, id } = payload;
      const selected = state[formType].selectedIds;

      if (Array.isArray(id)) {
        state[formType].selectedIds = JSON.stringify(id) === JSON.stringify(selected) ? [] : id;
      } else {
        const index = selected.indexOf(id);
        if (index === -1) {
          selected.push(id);
        } else {
          selected.splice(index, 1);
        }
      }
    },
    deleteFormContent: (state, { payload }: PayloadAction<{ formType: FormType }>) => {
      const { formType } = payload;
      if (state[formType]) {
        state[formType].loading = true;
      }
    },
    deleteFormContentError: (state, { payload }: PayloadAction<{ formType: FormType; error: any }>) => {
      const { formType, error } = payload;
      if (state[formType]) {
        state[formType].loading = false;
        state[formType].error = error;
      }
    },
    resetCurrentForm: (state, { payload }: PayloadAction<FormType>) => {
      state[payload] = { ...defaultFormState };
    },
    resetAllForms: () => initialState,
  },
});

export const {
  fetchFormList,
  fetchFormListSuccess,
  fetchFormListError,
  fetchSortingListSuccess,
  submitFormRequest,
  submitFormSuccess,
  submitFormError,
  updateFormFilter,
  updateFormProcedure,
  updateSelectedIds,
  deleteFormContent,
  deleteFormContentError,
  resetCurrentForm,
  resetAllForms,
} = formSlice.actions;

export default formSlice.reducer;

export const useFormState = (formType: FormType) => useAppSelector((state: RootState) => state.form[formType]);
export const useFormLoadingStatus = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].loading);
export const useFormSubmissionStatus = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].submitting);
export const useFormSaveStatus = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].saveSuccess);
export const useFormSortStatus = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].sortSuccess);
export const useFormList = (formType: FormType) => useAppSelector((state: RootState) => state.form[formType].formList);
export const useFormContent = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].formContent);
export const useFormSortedList = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].sortingList);
export const useFormFilter = (formType: FormType) => useAppSelector((state: RootState) => state.form[formType].filter);
export const useFormSelectedList = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].selectedIds);
export const useFormPagination = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].pagination);
export const useFormProcedure = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].procedure);
export const useFormError = (formType: FormType) => useAppSelector((state: RootState) => state.form[formType].error);
