import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getWAFConditionsColumns } from '@/constants';
import { Button, DialogModal, FormField } from '@components';
import {
  ConditionOperatorType,
  ConditionVariableType,
  InputType,
  StatusType,
  VariantType,
  columnDirection,
} from '@enums';
import { useAppDispatch } from '@hooks';
import { addSnack } from '@store';
import { WhitelistWAFForm } from '@types';

interface WAFWhitelistFormProps {
  data?: WhitelistWAFForm;
  open: boolean;
  isEdit: boolean;
  onClose: () => void;
}

export const WAFWhiteListModal = ({ data, open, isEdit, onClose }: WAFWhitelistFormProps) => {
  const dispatch = useAppDispatch();
  const { t: tForm } = useTranslation('configuration', { keyPrefix: 'forms.route' });
  const { t: tCommonForm } = useTranslation('configuration', { keyPrefix: 'forms' });
  const { t: tToast } = useTranslation('configuration', { keyPrefix: 'toasts.route' });
  const { t: tButton } = useTranslation('common', { keyPrefix: 'button' });

  const WAFConditionTableColumns = useMemo(getWAFConditionsColumns, []);

  const handleSubmit = (values: WhitelistWAFForm) => {
    dispatch(
      addSnack({
        type: StatusType.Success,
        message: isEdit ? tToast('editRouteSuccessful') : tToast('addRouteSuccessful'),
      }),
    );
    onClose();
  };

  const initialValues: WhitelistWAFForm = {
    name: data?.name ?? '',
    skippedWafRules: data?.skippedWafRules ?? [],
    rules: data?.rules ?? [],
  };

  return (
    <DialogModal open={open} onClose={onClose}>
      <Dialog.Panel className="min-w-[38rem] border-gray-900/10 relative transform transition-all text-left bg-theme-surface-default  rounded-lg shadow-xl">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values }) => {
            return (
              <Form className="bg-white rounded-lg p-8 space-y-8">
                <div className="flex items-center justify-between">
                  <article className="text-lg font-medium leading-6 text-gray-700">{'New WAF Whitelist Rule'}</article>
                  <Button
                    variant={VariantType.Icon}
                    hoverEffect
                    withoutPadding
                    rounded="rounded-full"
                    iconWithoutM
                    onClick={onClose}
                    icon={<XMarkIcon className="w-5 h-5" />}
                  />
                </div>
                <div className="grid grid-cols-1 gap-y-6">
                  <FormField
                    label={'WAF Whitelist Name'}
                    name="name"
                    direction={columnDirection.Row}
                    smallFont
                    required
                    placeholder={'Enter your whitelist name'}
                  />
                  <FormField
                    inputType={InputType.Table}
                    label={tForm('conditions')}
                    smallFont
                    columns={WAFConditionTableColumns}
                    format={tCommonForm}
                    withoutLabel
                    direction={columnDirection.Column}
                    name="rules"
                    placeholder={''}
                    emptyData={{
                      main: {
                        field: ConditionVariableType.URI,
                        argument: '',
                        operator: ConditionOperatorType.stringEqual,
                        values: [{ value: '' }],
                      },
                    }}
                  />
                  <FormField
                    inputType={InputType.Radio}
                    label={'Skip WAF rules'}
                    columns={WAFConditionTableColumns}
                    direction={columnDirection.Row}
                    name="skippedWafRules"
                    smallFont
                  />
                </div>

                <div className="sm:grid sm:grid-cols-2 gap-8">
                  <Button variant={VariantType.Outlined} className="ring-theme-primary-main" onClick={onClose}>
                    <article className="ellipsis-text">{tButton('cancel')}</article>
                  </Button>
                  <Button type="submit">{tButton(isEdit ? 'save' : 'create')}</Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Dialog.Panel>
    </DialogModal>
  );
};
