import { VariantType } from '@enums';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '..';

interface PaginationProps {
  first_page: number;
  last_page: number;
  next_page: number;
  page: number;
  total: number;
  total_pages: number;
  page_size: number;
}

interface PaginationComponentProps {
  pagination: PaginationProps;
  onChangePage: (page: number) => void;
}

const Pagination: React.FC<PaginationComponentProps> = ({ pagination, onChangePage }) => {
  const { t } = useTranslation('common', { keyPrefix: 'tables' });
  const { page: currentPage, first_page: firstPage, total_pages: totalPages, page_size: pageSize, total } = pagination;

  const visiblePages = useMemo(() => {
    if (totalPages <= 3) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (currentPage <= 2) return [1, 2, 3];
    if (currentPage >= totalPages - 1) return [totalPages - 2, totalPages - 1, totalPages];

    return [currentPage - 1, currentPage, currentPage + 1];
  }, [currentPage, totalPages]);

  const isFirstPage = currentPage === firstPage;
  const isLastPage = currentPage === totalPages;

  const handlePageClick = (page: number) => {
    if (page !== currentPage) {
      onChangePage(page);
    }
  };

  return (
    <div className="flex items-center justify-center sm:justify-between bg-transparent">
      <div className="flex sm:flex-1 sm:items-center sm:justify-between">
        <Text $level={6} color="text-gray-500" className="hidden sm:flex ">
          {t('displaying')} <span className="font-medium mx-2 text-black">{(currentPage - 1) * pageSize + 1}</span>
          {t('to')}
          <span className="font-medium mx-2 text-black">{Math.min(currentPage * pageSize, total)}</span>
          {t('of')}
          <span className="font-medium mx-2 text-black">{total}</span> {t('totalResults')}
        </Text>
        <div>
          <nav className="isolate inline-flex space-x-2 items-center" aria-label="Pagination">
            <PaginationButton
              onClick={() => handlePageClick(currentPage - 1)}
              disabled={isFirstPage}
              ariaLabel="Previous page"
            >
              <ChevronLeftIcon
                className={`h-5 w-5 ${isFirstPage ? 'text-gray-300' : 'text-theme-primary-main'}`}
                aria-hidden="true"
              />
            </PaginationButton>

            {currentPage > 2 && totalPages > 3 && (
              <PaginationButton onClick={() => handlePageClick(1)} disabled={false}>
                1
              </PaginationButton>
            )}

            {currentPage > 3 && totalPages > 4 && (
              <PaginationButton onClick={() => handlePageClick(currentPage - 2)} disabled={false}>
                ...
              </PaginationButton>
            )}

            {visiblePages.map((page) => (
              <PaginationButton
                key={page}
                onClick={() => handlePageClick(page)}
                disabled={page === currentPage}
                active={page === currentPage}
              >
                {page}
              </PaginationButton>
            ))}

            {currentPage < totalPages - 2 && totalPages > 4 && (
              <PaginationButton onClick={() => handlePageClick(currentPage + 2)} disabled={false}>
                ...
              </PaginationButton>
            )}

            {currentPage < totalPages - 1 && totalPages > 3 && (
              <PaginationButton onClick={() => handlePageClick(totalPages)} disabled={false}>
                {totalPages}
              </PaginationButton>
            )}

            <PaginationButton
              onClick={() => handlePageClick(currentPage + 1)}
              disabled={isLastPage}
              ariaLabel="Next page"
            >
              <ChevronRightIcon
                className={`h-5 w-5 ${isLastPage ? 'text-gray-300' : 'text-theme-primary-main'}`}
                aria-hidden="true"
              />
            </PaginationButton>
          </nav>
        </div>
      </div>
    </div>
  );
};

interface PaginationButtonProps {
  onClick: () => void;
  disabled: boolean;
  active?: boolean;
  children: React.ReactNode;
  ariaLabel?: string;
}

const PaginationButton: React.FC<PaginationButtonProps> = ({ onClick, disabled, active, children, ariaLabel }) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    variant={VariantType.Transparent}
    rounded="rounded-full"
    withoutPadding
    customizeColor
    className={`
      ${active ? 'bg-theme-primary-main text-white text-opacity-100 opacity-100' : ' text-theme-neutral-text'}
      ${disabled ? '' : 'hover:bg-blue-50'}
      min-w-7 min-h-7 flex items-center justify-center
    `}
    aria-label={ariaLabel}
  >
    {children}
  </Button>
);

export default Pagination;
