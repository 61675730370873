import classNames from 'classnames';
import { memo } from 'react';

import { CellType } from '@enums';
import { validateValueEqual } from '@utils';

import {
  ActionHandlers,
  ActionTableCell,
  BadgeTableCell,
  CheckBoxTableCell,
  Column,
  ColumnLayout,
  DefaultTableCell,
  DraggableItem,
} from '@components';

interface DraggableCellProps<T> {
  column: Column;
  item: T;
  rowId: number;
  layout: ColumnLayout;
  actionHandlers?: ActionHandlers<DraggableItem>;
  tableKey: string;
}

export const DraggableCell = memo(
  <T extends DraggableItem>({ column, item, rowId, layout, actionHandlers, tableKey }: DraggableCellProps<T>) => {
    const cellValue = Array.isArray(column.value)
      ? column.value.map((value) => item[value]).join(', ')
      : item[column.value];

    const renderContent = () => {
      const formattedData = column.format?.(cellValue, item) ?? cellValue;

      switch (column.cellType) {
        case CellType.Badge:
          return <BadgeTableCell tableKey={tableKey} data={formattedData} rawData={cellValue} />;
        case CellType.Action:
          if (!actionHandlers) return null;
          return (
            <ActionTableCell
              tableKey={tableKey}
              multiLines={column.multiLines}
              actionHandlers={actionHandlers}
              data={item}
            />
          );
        case CellType.Checkbox:
          if (!actionHandlers) return null;
          return <CheckBoxTableCell data={item} tableKey={tableKey} actionHandlers={actionHandlers} />;
        default:
          return (
            <DefaultTableCell
              rowId={rowId}
              tableKey={tableKey}
              data={formattedData}
              column={column}
              multiLines={column.multiLines}
            />
          );
      }
    };

    return (
      <div
        className={classNames('flex px-3  text-sm font-medium text-gray-900 flex-col', {
          truncate: column.dotsTextOverflow,
          'break-words': !column.dotsTextOverflow,
          'justify-start h-auto pt-4 pb-3.5': column.multiLines,
          'justify-center py-1': !column.multiLines,
        })}
        style={{
          width: `${layout.percentage}%`,
          minWidth: layout.minWidth,
          flex: layout.flex,
        }}
      >
        <div
          className={classNames({
            'w-full': true,
            'text-left': !column.center,
            'text-center': column.center,
            'py-1.5': !column.multiLines && validateValueEqual(column.cellType, CellType.Action),
            'py-3': !column.multiLines && !validateValueEqual(column.cellType, CellType.Action),
            'pt-0.5': column.multiLines,
          })}
        >
          {renderContent()}
        </div>
      </div>
    );
  },
);
