import { useField } from 'formik';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  resetConfigurationForm,
  useConfigurationError,
  useConfigurationSaveSuccess,
  useConfigurationSubmitting,
} from '@/store';
import { FormModal } from '@components';
import { CdnConfigurationTab } from '@enums';
import { useAppDispatch } from '@hooks';
import { Dictionaries, getOptionListFromCatalog } from '@utils';

import { RegionList } from './RegionList';

interface BlockRegionModalProps {
  label: any;
  open: boolean;
  onClose: () => void;
  formik: any;
  name: string;
  onSubmit: (event, formik, name) => void;
}

export const BlockRegionModal = ({ name, formik, onSubmit, label, open, onClose }: BlockRegionModalProps) => {
  const dispatch = useAppDispatch();
  const { t: tModals } = useTranslation('configuration', { keyPrefix: 'modals.security' });

  const tableKey = CdnConfigurationTab.Security;

  const submitError = useConfigurationError(tableKey);
  const isSubmitting = useConfigurationSubmitting(tableKey);
  const saveSuccess = useConfigurationSaveSuccess(tableKey);

  const [field, , helpers] = useField<string[]>(name);

  const regions = useMemo(() => getOptionListFromCatalog(Dictionaries.Regions) ?? [], []);

  const availableRegions = useMemo(
    () => regions.filter((region) => !field.value.includes(region.value)),
    [regions, field.value],
  );

  const blockedRegions = useMemo(
    () => regions.filter((region) => field.value.includes(region.value)),
    [regions, field.value],
  );

  const handleToggleRegion = (regionCode: string, addToBlocked: boolean) => {
    if (addToBlocked) {
      helpers.setValue([...field.value, regionCode]);
    } else {
      helpers.setValue(field.value.filter((c) => c !== regionCode));
    }
  };

  const handleMoveAllToBlocked = () => {
    helpers.setValue([...field.value, ...availableRegions.map((c) => c.value)]);
  };

  const handleMoveAllToAvailable = () => {
    helpers.setValue([]);
  };

  useEffect(() => {
    if (!submitError && !isSubmitting && saveSuccess) {
      onClose();
      dispatch(resetConfigurationForm(tableKey));
    }
  }, [isSubmitting, saveSuccess, submitError]);

  return (
    <FormModal
      title={label?.title}
      open={open}
      onClose={onClose}
      description={label?.description}
      isSubmitting={isSubmitting}
      submitError={submitError}
      size="2xl"
      confirmButton={{
        children: label?.confirmButton,
        onClick: () => {
          onSubmit(field.value, formik, name);
        },
      }}
    >
      <div className="flex gap-6 flex-col sm:flex-row">
        <RegionList
          title={tModals('availableRegions')}
          regions={availableRegions}
          onToggleRegion={(regionCode) => handleToggleRegion(regionCode, true)}
          placeholder={label?.placeholder}
          onMoveAll={handleMoveAllToBlocked}
          name="availableRegions"
        />
        <RegionList
          title={tModals('blockedRegions')}
          regions={blockedRegions}
          onToggleRegion={(regionCode) => handleToggleRegion(regionCode, false)}
          placeholder={label?.placeholder}
          onMoveAll={handleMoveAllToAvailable}
          name="blockedRegions"
        />
      </div>
    </FormModal>
  );
};
